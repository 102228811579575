import { markRaw } from 'vue'
import {
  DEFAULT_AVATAR,
  DEFAULT_COLOR_GRAY,
  DEFAULT_COLOR_WHITE,
  DEFAULT_JOB,
  DEFAULT_NAME,
  DEFAULT_LONG_MARKDOWN_TEXT,
} from '../constants'
import ReviewsView from './ReviewsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'
import type { Review } from './types'

const DEFAULT_REVIEW: Review = {
  name: DEFAULT_NAME,
  company: DEFAULT_JOB,
  image: DEFAULT_AVATAR,
  text: DEFAULT_LONG_MARKDOWN_TEXT,
}

export default {
  viewComponent: markRaw(ReviewsView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'Reviews',
    bgColor: DEFAULT_COLOR_WHITE,
    cardBgColor: '#f0f6ff',
    iframeCardBgColor: '#f0f6ff',
    userInfoColor: DEFAULT_COLOR_GRAY,
    iframeUserInfoColor: DEFAULT_COLOR_GRAY,
    textColor: DEFAULT_COLOR_GRAY,
    iframeTextColor: DEFAULT_COLOR_GRAY,
    reviews: [DEFAULT_REVIEW, DEFAULT_REVIEW, DEFAULT_REVIEW, DEFAULT_REVIEW],
  },
}
