import { markRaw } from 'vue'
import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_IMAGE_HORIZONTAL,
  DEFAULT_LINK,
} from '../constants'
import CardsView from './CardsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

const DEFAULT_CARD = {
  cardType: 'image',
  imageUrl: DEFAULT_IMAGE_HORIZONTAL,
  link: DEFAULT_LINK,
}

export default {
  viewComponent: markRaw(CardsView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    colsNumberOnDesktop: 4,
    colsNumberOnMobile: 2,
    isUseSliderOnMobile: true,
    rowsNumberInSliderOnMobile: 1,
    isShadowed: true,
    shadowColor: DEFAULT_COLOR_GRAY,
    isBordered: false,
    borderColor: DEFAULT_COLOR_GRAY,
    cards: [DEFAULT_CARD, DEFAULT_CARD, DEFAULT_CARD, DEFAULT_CARD],
  },
}
