<template>
  <img
    :src="isLazy && isFirstRender && !isInIframe ? undefined : resizeSrc"
    :data-src="isLazy && isFirstRender && !isInIframe ? resizeSrc : undefined"
    :loading="isLazy && !isFirstRender && !isInIframe ? 'lazy' : undefined"
    :width="width"
    :height="height"
    v-bind="{ ...$attrs }"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    default: undefined,
  },
  height: {
    type: [Number, String],
    default: undefined,
  },
  loading: {
    type: String as PropType<'lazy' | 'eager'>,
    default: undefined,
  },
})

const isInIframe = computed(() => {
  return useRoute().path.includes('/iframe/')
})
const nuxtApp = useNuxtApp()
const isLazy = computed(() => props.loading === 'lazy')
// custom lazy load optimization only for the first render to improve GPSI LCP
const isFirstRender = computed(() => process.server || nuxtApp.isHydrating)

const resizeSrc = computed(() => {
  return useProcessImage({
    url: props.src,
    width: parseInt(String(props.width)),
    height: parseInt(String(props.height)),
  })
})
</script>
