import { defineStore } from 'pinia'

export const useTokenRefresh = defineStore('token-refresh', () => {
  const isTokenRefreshing = ref<null | Promise<boolean>>(null)
  const resolveRefreshPromise = ref<(_value: boolean) => any>(
    (_value: boolean) => {}
  )
  const rejectRefreshPromise = ref<(_value: any) => any>(
    (_value: boolean) => {}
  )

  return {
    // avoid error on function serialization
    getRefs: () => ({
      isTokenRefreshing,
      resolveRefreshPromise,
      rejectRefreshPromise,
    }),
  }
})
