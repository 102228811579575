<template>
  <div class="flex gap-2">
    <input
      v-model="value"
      type="color"
      class="color-input"
      :class="!modelValue && 'opacity-10'"
    />
    <BaseInput
      v-if="showText"
      v-model="value"
      name="color-name"
      class="w-24"
      placeholder="#000000"
      :error="isValidColor ? '' : 'Invalid'"
    />
  </div>
</template>

<script setup lang="ts">
// TODO: refact if we want more standard input (with error, label, descr., etc)
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  showText: {
    type: Boolean,
    default: true,
  },
  allowNoColor: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue'])
const isValidColor = ref(true)

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value: string) => {
    // validation of HEX color
    if (value.startsWith('#') && (value.length === 7 || value.length === 4)) {
      isValidColor.value = true
      emit('update:modelValue', value)
    }
    // validation of color from color picker
    else if (!value.startsWith('#') && value.length === 7) {
      isValidColor.value = true

      emit('update:modelValue', value)
    } else if (!value && props.allowNoColor) {
      isValidColor.value = true

      emit('update:modelValue', '')
    } else {
      isValidColor.value = false
    }
  },
})
</script>

<style lang="css" scoped>
.color-input {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.75rem;
  border: solid 1px #c3c8cc;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
}

.color-input::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-input::-webkit-color-swatch {
  border-radius: 0.75rem;
  border: none;
}

.color-input::-moz-color-swatch {
  border-radius: 0.75rem;
  border: none;
}
</style>
