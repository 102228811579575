export default {
  images: {
    items: {
      image: {
        'ui:widget': 'EditorUploadWidget',
      },
    },
  },
  text: {
    'ui:widget': 'EditorMarkdownWidget',
  },
  textColor: {
    'ui:widget': 'BaseColorWidget',
  },
  buttons: {
    items: {
      color: {
        'ui:widget': 'BaseColorWidget',
      },
      textColor: {
        'ui:widget': 'BaseColorWidget',
      },
    },
  },
}
