<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <div
      class="marked"
      :style="{
        color: formData.textColor,
        '--text-color': formData.textColor,
      }"
      v-html="getMarkedTextWithBlankLinks(formData.text, formData.linkColor)"
    />
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()
interface PropsInterface {
  uuid: string
  formData: any
}

defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()
</script>
