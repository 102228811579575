import { NavigationSortType } from '~/models/navigation'
import type { InfinityListParams } from '~/composables/use-infinity-list'

export enum FilterParams {
  Popular = 'filter[popular]',
  Query = 'filter[query]',
  Event = 'filter[event_id]',
  EventArray = 'filter[event_id][]',
  Language = 'filter[language]',
  Category = 'filter[category]',
  CategoryArray = 'filter[category][]',
  Categories = 'filter[categories][]',
  Tranding = 'filter[tranding]',
  Premium = 'filter[premium]',
  EventType = 'filter[type][]',
  EventSubtype = 'filter[event_type]',
  EventRegions = 'filter[regions][]',
  CourseSubjects = 'filter[course_subject][]',
  CourseLevels = 'filter[course_level][]',
  Company = 'filter[company_id]',
}

export const SortFilters = {
  [NavigationSortType.Popular]: FilterParams.Popular,
  [NavigationSortType.Premium]: FilterParams.Premium,
  // NavigationSortType.New empty filter
} as const

export const isSortTheSame = (
  params: InfinityListParams,
  newValue: NavigationSortType
) => {
  return Object.entries(SortFilters).every(([key, value]) => {
    return (key === newValue) === !!params[value]
  })
}

export const setSort = (
  params: InfinityListParams,
  newValue: NavigationSortType
) => {
  Object.entries(SortFilters).forEach(([sortType, filter]) => {
    if (sortType === newValue) {
      params[filter] = 1
    } else {
      delete params[filter]
    }
  })
}

export const isCategoryTheSame = (
  params: InfinityListParams,
  newValue?: number
) => {
  const oldValue = params[FilterParams.Categories] as number[] | undefined

  if (!newValue && !oldValue) {
    return true
  }

  if (newValue && oldValue) {
    return newValue === oldValue[0]
  }

  return false
}

export const isQueryTheSame = (params: InfinityListParams, newValue = '') => {
  const oldValue = params[FilterParams.Query] as string | undefined

  return newValue === oldValue || (!newValue && !oldValue)
}

export const isEventsIdsTheSame = (
  params: InfinityListParams,
  newValue: number[]
) => {
  const oldValue = params[FilterParams.EventArray] as number[] | undefined

  if (!newValue.length && !oldValue) {
    return true
  }

  if (oldValue && newValue.length === oldValue.length) {
    return newValue.every((id) => oldValue.includes(id))
  }

  return false
}
