<template>
  <div
    :id="uuid"
    ref="ticketsBlock"
    class="p-6 md:p-8 bg-white rounded-xl gap-4"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <div
      v-if="
        (formData.title || formData.subtitle) &&
        !(formData.hideOnEmbed && isInIframe)
      "
      class="mb-6"
      :style="{ textAlign: formData.titleAlign, color: formData.textColor }"
    >
      <h2 v-if="formData.title" class="text-2xl md:text-3xl font-bold mb-1">
        {{ formData.title }}
      </h2>
      <div v-if="formData.subtitle" class="text-xl">
        {{ formData.subtitle }}
      </div>
    </div>
    <div v-if="isTicketsLoading" class="p-24 flex justify-center">
      <BaseSpinner />
    </div>
    <div
      v-else-if="formData.categories"
      class="grid grid-cols-1 gap-4"
      :class="
        isInIframe
          ? 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
          : 'md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'
      "
    >
      <template
        v-for="(category, index) in formData.categories"
        :key="category.title + index"
      >
        <TicketsCategoryCard
          :category="category"
          :tickets-info="ticketsStore.ticketsDetails"
          @update="onUpdate"
        />
      </template>
      <BaseObserve empty @change="onVisibleChange" />
    </div>
    <BaseLink
      v-if="ticketsCounter"
      :to="isInIframe ? undefined : cartRoute"
      :href="`https://wnhub.io/cart/${eventSlug}?${ticketsQuery}`"
      target="_blank"
      class="!fixed z-10 right-4 rounded-xl flex items-center justify-center bg-white !p-4 shadow-md group transform duration-150"
      :class="[isCartExpanded && 'scale-125']"
      style="top: calc(5rem + var(--sat))"
      size="none"
      theme="none"
      @click="onClickOnCart"
    >
      <BaseIcon name="outline_ticket_thin" size="xl" class="text-primary-800" />
      <div
        class="absolute -top-2 -right-2 flex items-center justify-center bg-red-500 rounded-full h-5 w-5 text-white text-xs"
      >
        {{ ticketsCounter }}
      </div>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import type { Category } from './TicketsCategoryCard.vue'
import TicketsCategoryCard from './TicketsCategoryCard.vue'
import { useEvents } from '~/stores/events'
import { useTickets } from '~/stores/tickets'
import { usePageEditor } from '~/stores/page-editor'
import type { TicketDetails } from '~/models/tickets'
import { YandexECommerceEventType } from '~/plugins/yandex-metrika/ecommerce-models'

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const pageEditorStore = usePageEditor()

const emit = defineEmits(['loaded'])
const eventsStore = useEvents()
const ticketsStore = useTickets()
const {
  getSessionStorageValue,
  setSessionStorageValue,
  removeSessionStorageValue,
} = useSessionStorage()
const {
  $yandexMetrika: { sendECommerceEvent, getECommerceProductFromTicket },
  $linkedin: { lintrk },
  $facebook: { fbq },
  $gtm: { gTagPush },
} = useNuxtApp()

const selectedTickets = ref<Record<number, number>>({})
const isTicketsLoading = ref(false)
const isCartExpanded = ref(false)
const ticketsBlock = ref<HTMLElement | null>(null)

const ticketsCounter = computed(() => {
  return Object.values(selectedTickets.value).reduce(
    (acc, count) => acc + count,
    0
  )
})

const currency = computed(() => {
  return eventsStore.currency
})

const eventSlug = useRoute().params.event as string
onServerPrefetch(async () => {
  isTicketsLoading.value = true
  await eventsStore.fetchEvent(eventSlug, false)
  await ticketsStore.fetchTicketListDetails(eventsStore.event?.id)
  isTicketsLoading.value = false
})

onMounted(async () => {
  if (!useNuxtApp().isHydrating) {
    isTicketsLoading.value = true
    await eventsStore.fetchEvent(eventSlug, false)
    await ticketsStore.fetchTicketListDetails(eventsStore.event?.id)
    isTicketsLoading.value = false
  }

  emit('loaded', ticketsBlock, 'tickets')

  const savedTickets = getSessionStorageValue('tickets')

  if (savedTickets) {
    if (savedTickets.eventId === eventsStore.event?.id) {
      selectedTickets.value = savedTickets.list
    } else {
      removeSessionStorageValue('tickets')
    }
  }
})

const onUpdate = (ticketId: number, quantity: number) => {
  if (selectedTickets.value[ticketId]) {
    selectedTickets.value[ticketId] += quantity
  } else {
    selectedTickets.value[ticketId] = quantity
  }

  isCartExpanded.value = true
  setTimeout(() => {
    isCartExpanded.value = false
  }, 150)

  setSessionStorageValue('tickets', {
    eventId: eventsStore.event?.id,
    list: selectedTickets.value,
  })

  lintrk('track', { conversion_id: 17206282 })
  fbq('track', 'AddToCart')
  gTagPush({ event: 'AddToCart' })
  sendYandexECommerceAddEvent(ticketId, quantity)
}

const onClickOnCart = () => {
  lintrk('track', { conversion_id: 17206306 })
  fbq('track', 'ViewContent')
  gTagPush({ event: 'ViewCartContent' })
  removeSessionStorageValue('tickets')
}

const ticketsQuery = computed(() => {
  return Object.keys(cartRoute.value.query)
    .map((key) => `${key}=${cartRoute.value.query[key]}`)
    .join('&')
})

const cartRoute = computed(() => {
  const ticketsQuery = Object.entries(selectedTickets.value).reduce(
    (acc, [id, count]) => {
      acc[`tickets[${id}]`] = count.toString()
      return acc
    },
    {} as Record<string, string>
  )

  return {
    name: 'cart-event',
    params: {
      event: eventsStore.event?.slug || '',
    },
    query: ticketsQuery,
  }
})

function onVisibleChange(isVisible: boolean): void {
  if (isVisible) {
    sendYandexECommerceImpressionsEvent()
  }
}

let isYandexECommerceImpressionsEventSent = false

function sendYandexECommerceImpressionsEvent() {
  if (isYandexECommerceImpressionsEventSent) {
    return
  }

  isYandexECommerceImpressionsEventSent = true

  const tickets: TicketDetails[] = []

  props.formData.categories.forEach((category: Category) => {
    category.tickets?.forEach((ticketParams) => {
      const ticketId = ticketParams.value

      const ticket = ticketsStore.ticketsDetailsMap[ticketId]
      ticket && tickets.push(ticket)
    })
  })

  sendECommerceEvent({
    currencyCode: currency.value,
    [YandexECommerceEventType.Impressions]: tickets.map((ticket, index) =>
      getECommerceProductFromTicket(
        ticket,
        currency.value,
        eventsStore.event,
        1,
        '',
        0,
        index
      )
    ),
  })
}

function sendYandexECommerceAddEvent(ticketId: number, quantity: number): void {
  const ticket = ticketsStore.ticketsDetailsMap[ticketId]

  if (!ticket) {
    return
  }

  sendECommerceEvent({
    currencyCode: currency.value,
    [YandexECommerceEventType.Add]: {
      products: [
        getECommerceProductFromTicket(
          ticket,
          currency.value,
          eventsStore.event,
          quantity
        ),
      ],
    },
  })
}
</script>
