import { markRaw } from 'vue'
import { DEFAULT_IMAGE_SQUARE } from '../constants'
import ImagesView from './ImagesView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(ImagesView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    colsNumberOnDesktop: 4,
    colsNumberOnMobile: 2,
    isUseSliderOnMobile: true,
    isOpenFullscreen: true,
    rowsNumberInSliderOnMobile: 1,
    images: [
      DEFAULT_IMAGE_SQUARE,
      DEFAULT_IMAGE_SQUARE,
      DEFAULT_IMAGE_SQUARE,
      DEFAULT_IMAGE_SQUARE,
    ],
  },
}
