<template>
  <div>
    <div v-if="label">{{ label }}</div>
    <n-config-provider preflight-style-disabled>
      <n-switch v-model:value="value">
        <template #checked>{{ checkedText }} </template>
        <template #unchecked> {{ uncheckedText }} </template>
      </n-switch>
    </n-config-provider>
  </div>
</template>

<script setup>
/*
you can pass props to this component this way:
'ui:fieldProps': { label: 'label', checkedText: 'Your text', uncheckedText: 'Your text' },
*/

const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  checkedText: {
    type: String,
    default: 'Hide settings',
  },
  uncheckedText: {
    type: String,
    default: 'Show settings',
  },
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => {
    return !!props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value ? 'true' : undefined)
  },
})
</script>
