import { Dayjs } from 'dayjs'
import { useI18n } from 'vue-i18n'
import { useAuth } from '~/stores/auth'

export const useDate = () => {
  const authStore = useAuth()
  const { $dayjs } = useNuxtApp()
  const i18n = useI18n()

  function parseDate(date: string | Dayjs, timezone?: string) {
    if (!date) {
      return ''
    }

    if (typeof date === 'string') {
      return $dayjs
        .utc(date)
        .tz(timezone || authStore.profile?.timezone_id || undefined)
    } else if ('tz' in date) {
      return timezone ? date.tz(timezone) : date
    }

    return ''
  }

  const getHumanizedDate = (
    date: string | Dayjs,
    timezone?: string,
    locale?: string
  ) => {
    const currentLocale = locale || i18n.locale.value
    const isRu = currentLocale === 'ru'
    let parsedDate = parseDate(date, timezone)

    if (typeof parsedDate === 'string') {
      return parsedDate
    }

    parsedDate = parsedDate.locale(currentLocale)

    return isRu
      ? parsedDate.format('D MMMM, YYYY')
      : parsedDate.format('MMMM D, YYYY')
  }

  const getFormattedDate = (
    date: string | Dayjs,
    full = true,
    timezone?: string
    // locale?: string
  ) => {
    // const currentLocale = locale || i18n.locale.value
    // const isRu = currentLocale === 'ru'
    const parsedDate = parseDate(date, timezone)

    // TODO uncomment if we need american date format for en locale
    // return isRu
    //   ? parsedDate.format('DD.MM.YYYY')
    //   : parsedDate.format('MM.DD.YYYY')

    if (!parsedDate) {
      return ''
    }

    return full ? parsedDate.format('DD.MM.YYYY') : parsedDate.format('DD.MM')
  }

  const getHumanizedDatePeriod = (
    dateFrom: string | Dayjs,
    dateTo: string | Dayjs,
    timezone?: string,
    locale?: string
  ) => {
    const currentLocale = locale || i18n.locale.value
    const isRu = currentLocale === 'ru'
    let parsedDateFrom = parseDate(dateFrom, timezone)
    let parsedDateTo = parseDate(dateTo, timezone)

    if (
      typeof parsedDateFrom !== 'object' ||
      typeof parsedDateTo !== 'object'
    ) {
      return ''
    }

    parsedDateFrom = parsedDateFrom.locale(currentLocale)
    parsedDateTo = parsedDateTo.locale(currentLocale)

    if (parsedDateFrom.year() === parsedDateTo.year()) {
      if (parsedDateFrom.month() === parsedDateTo.month()) {
        if (isRu) {
          return `
            ${
              parsedDateFrom.date() === parsedDateTo.date()
                ? parsedDateFrom.format('D MMMM')
                : parsedDateFrom.format('D-')
            }${
            parsedDateFrom.date() === parsedDateTo.date()
              ? ''
              : parsedDateTo.format('D MMMM')
          }, 
            ${parsedDateFrom.format('YYYY')}
          `
        } else {
          return `
            ${parsedDateFrom.format('MMMM D')}${
            parsedDateFrom.date() === parsedDateTo.date()
              ? ''
              : parsedDateTo.format('-D')
          }, 
            ${parsedDateFrom.format('YYYY')}
          `
        }
      } else if (isRu) {
        return `
              ${parsedDateFrom.format('D MMMM')}
              ${parsedDateTo.format(' - D MMMM')}, 
              ${parsedDateFrom.format('YYYY')}
            `
      } else {
        return `
              ${parsedDateFrom.format('MMMM D')}
              ${parsedDateTo.format(' - MMMM D')}, 
              ${parsedDateFrom.format('YYYY')}
            `
      }
    } else if (isRu) {
      return `
            ${parsedDateFrom.format('D MMMM, YYYY')}
            ${parsedDateTo.format(' - D MMMM, YYYY')}
          `
    } else {
      return `
            ${parsedDateFrom.format('MMMM D, YYYY')}
            ${parsedDateTo.format(' - MMMM D, YYYY')}
          `
    }
  }

  return {
    parseDate,
    getHumanizedDate,
    getHumanizedDatePeriod,
    getFormattedDate,
  }
}
