import { defineStore } from 'pinia'
import type {
  PageEditorFormRequest,
  PageEditorFormProductRequest,
} from '~/models/page-editor-forms'

export const usePageEditorForms = defineStore('page-editor-forms', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  const sendRequest = async (request: PageEditorFormRequest) => {
    const { data } = await useAuthFetch<{ data: any }>('/form-requests', {
      baseURL,
      method: 'POST',
      body: request,
    })

    return data
  }

  const sendProductRequest = async (request: PageEditorFormProductRequest) => {
    const { data } = await useAuthFetch<{ data: any }>(
      '/catalog/product-request',
      {
        baseURL,
        method: 'POST',
        body: request,
      }
    )

    return data
  }

  async function uploadFile(file: File) {
    const formData = new FormData()

    formData.append('media', file)

    const data = await useAuthFetch<{ filename: string }>(
      '/form-requests/media',
      {
        baseURL,
        method: 'POST',
        body: formData,
      }
    )

    return data.filename
  }

  return {
    sendProductRequest,
    sendRequest,
    uploadFile,
  }
})
