export type Transforms<T> = Record<string, (entity: T) => any>

export function execTransforms<T>(transforms: Transforms<T>, entity: T) {
  const transformedEntity = useCloneDeep(entity)
  Object.keys(transforms).forEach((key) => {
    transformedEntity[key as keyof T] = transforms[key](entity)
  })

  return transformedEntity
}
