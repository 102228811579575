export function readFile(file: File) {
  return new Promise((resolve) => {
    if (!file) {
      resolve('')
    }
    const reader = new FileReader()

    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export function getImageExtension(type: string) {
  switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
      return '.jpg'
    case 'image/png':
      return '.png'
    case 'image/gif':
      return '.gif'
    case 'image/svg+xml':
      return '.svg'
    default:
      return '.jpg'
  }
}
