import { Capacitor } from '@capacitor/core'

// TODO: improve SSR detect by request domain
const isInIframe = process.client ? window.self !== window.top : false

let isApp = false
try {
  isApp = Capacitor.isNativePlatform() && !isInIframe
} catch (error: any) {
  console.error(error)
}

const isProd = process.env.NODE_ENV === 'production'

export function useDetect() {
  return {
    isApp,
    isProd,
    isInIframe,
  }
}
