
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "2c4f3417-c59e-47fb-abfe-7f1d0877e212"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/sites/wnhub-general-client/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
