import { FetchError } from 'ofetch'
import type { NitroFetchOptions } from 'nitropack'
import { useCurrentLang } from '~/stores/current-lang'
import { useSkipKey } from '~/composables/use-create-screen-error'

const LANG_HEADER = 'Accept-Language'

interface KrakenError {
  error_backend_alias: {
    http_status_code?: number
    http_body?: string
  }
}

function throwKrakenError(response: any, method = 'GET', request: string) {
  if (
    typeof response === 'object' &&
    response !== null &&
    'error_backend_alias' in response
  ) {
    const krakenError = response as KrakenError
    const code = krakenError.error_backend_alias?.http_status_code || 500
    const error = new FetchError(
      `${code} Kraken Wrapped Error (${method}, ${request}, ${
        krakenError.error_backend_alias?.http_body || ''
      })`
    )
    error.status = code
    error.statusCode = code

    try {
      if (krakenError.error_backend_alias.http_body) {
        error.data = JSON.parse(krakenError.error_backend_alias.http_body)
      }
    } catch (error: any) {
      console.warn('Error on parse Kraken error data', error)
    }

    throw error
  }
}

export const useKrakenFetch = async <T>(
  request: string,
  opts?: NitroFetchOptions<any> | undefined
) => {
  let fetch = $fetch

  if (process.client) {
    const { $sentryAddBreadcrumb } = useNuxtApp()
    fetch = $fetch.create({
      onResponse({ request, response, options }) {
        $sentryAddBreadcrumb?.({
          category: 'fetch',
          message: `${options.method || 'GET'}: ${request} [${
            response.status
          }]`,
          ...(options.body ? { body: options.body } : {}),
          level: 'info',
        })
      },
    })
  }

  const { lang } = useCurrentLang()
  const targetHeader = LANG_HEADER.toLowerCase()

  const hasLangHeader = Object.keys(opts?.headers || []).some(
    (header) => header.toLowerCase() === targetHeader
  )

  if (!hasLangHeader && lang) {
    opts ??= {}
    opts.headers ??= {}
    opts.headers = {
      ...opts.headers,
      ...{ [LANG_HEADER]: lang },
    }
  }

  try {
    process.client && console.log('Kraken Fetch', request, JSON.stringify(opts))

    const response = await fetch<T>(request, opts)
    throwKrakenError(response, opts?.method, request)

    return response
  } catch (error: any) {
    throwKrakenError(error.data, opts?.method, request)

    if (process.client && error.message.includes('<no response>')) {
      // we want to skip network or browser related errors from capture
      // '<no response> Failed to fetch' error usually caused by browser when page closed by user or by code on fast navigation
      // examples: google iframe auth, app deep linking througt general to meeting system, user fast close the unwanted page
      error.message = error.message + '; ' + useSkipKey()
    }

    throw error
  }
}
