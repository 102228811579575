<template>
  <div>
    <BaseSelect
      v-model="value"
      :options="blocksOptions"
      name="ticket"
      class="w-64 sm:w-80"
    />
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

interface PropsInterface {
  modelValue?: string | number
}

const props = withDefaults(defineProps<PropsInterface>(), {
  modelValue: undefined,
})

const emit = defineEmits(['update:modelValue'])
const pageEditorStore = usePageEditor()

const blocksOptions = computed(() => {
  return pageEditorStore.editorBlocks.map((block) => {
    const value = pageEditorStore.getBlockId(block)

    return {
      label: value,
      value,
    }
  })
})

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})
</script>
