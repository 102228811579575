import { basePropertiesSchema } from '../base-schema-properties'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Program',
  description: 'Event program',
  type: 'object',
  required: ['title', 'eventId', 'programId'],
  properties: {
    ...basePropertiesSchema,
    hideOnEmbed: {
      title: 'Hide title when embedding via Iframe',
      type: 'boolean',
      default: false,
    },
    eventId: {
      title: 'Event id',
      type: 'number',
      'ui:hidden': '{{ true }}',
    },
    programId: {
      title: 'Program id',
      type: 'number',
    },
    isUsDateFormat: {
      title: 'Is US date format',
      type: 'boolean',
      default: false,
    },
    pdfLang: {
      title: 'PDF language',
      type: 'string',
      enum: ['en', 'ru'],
      enumNames: ['En', 'Ru'],
      default: 'en',
    },
    isDescriptionOpen: {
      title: 'Description is open by default',
      type: 'boolean',
      default: false,
    },
    isDescriptionOpenApp: {
      title: 'Description is open by default in App',
      type: 'boolean',
      default: false,
    },
  },
}
