declare let window: {
  _tmr: any
  opera?: string
}

function loadScript(id: string) {
  return new Promise((resolve, reject) => {
    try {
      const _tmr = window._tmr || (window._tmr = [])
      _tmr.push({
        id,
        type: 'pageView',
        start: new Date().getTime(),
      })
      ;(function (d, w, id) {
        if (d.getElementById(id)) return
        const ts = d.createElement('script')
        ts.type = 'text/javascript'
        ts.async = true
        ts.id = id
        ts.src = 'https://top-fwz1.mail.ru/js/code.js'
        const f = function () {
          const s = d.getElementsByTagName('script')[0]
          s.parentNode?.insertBefore(ts, s)
        }
        if (w.opera === '[object Opera]') {
          d.addEventListener('DOMContentLoaded', f, false)
        } else {
          f()
        }
      })(document, window, 'tmr-code')

      resolve({ success: true })
    } catch (error: any) {
      reject(error)
    }
  })
}

function debug(...args: any[]) {
  console.log('[VK Pixel]', ...args)
}

export async function createPixel(id: string, isDebug = false) {
  if (!isDebug) {
    await loadScript(id)

    console.log('analytics: VK Pixel successfully inited')

    return (...args: any[]) => window._tmr.push(...args)
  } else {
    return (...args: any[]) => debug(...args)
  }
}
