<template>
  <BaseSelectAsync
    v-model="value"
    label="Email Template"
    :fetch="fetchAutocomplete"
    :initial="initialEmail"
    name="email"
    class="w-full"
  />
</template>

<script setup lang="ts">
import { useEmails } from '~/stores/emails'
import type { OptionInterface } from '~/components/base/controls/use-simple-input'
import { EmailTypes } from '~/models/emails'

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const eventsStore = useEvents()
const emailsStore = useEmails()
const initialEmail = ref<undefined | OptionInterface>(undefined)

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})

function fetchAutocomplete(query: string) {
  if (!eventsStore.event) {
    return []
  }
  return emailsStore.getEmailsAutocomplete(eventsStore.event.id, query, [
    EmailTypes.Custom,
  ])
}

onMounted(async () => {
  if (props.modelValue && eventsStore.event) {
    const email = await emailsStore.getEmailByEvent(
      eventsStore.event.id,
      props.modelValue
    )
    initialEmail.value = {
      value: email.id,
      label: email.name,
    }
  }
})
</script>
