export default {
  speakers: {
    items: {
      subtitle: {
        'ui:widget': 'EditorMarkdownWidget',
      },
      image: {
        'ui:widget': 'EditorUploadWidget',
      },
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
    },
  },
}
