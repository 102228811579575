import type { Media } from './media'

export interface Category {
  id: number
  slug: string
  title: string | Record<string, string>
  description: string | Record<string, string>
  preview: Media[]
  background: Media[]
  active: boolean
  match_keywords: string[]
  match_tags: string[]
  meta_description: string
  meta_keywords: string
  meta_title: string
}

export enum NavigationSortType {
  Popular = 'popular',
  New = 'new',
  Premium = 'premium',
  All = 'all',
}

export interface NavigationSortItem {
  slug: NavigationSortType
  local: string
  icon: string
  subpage?: string
}

export const OTHER_CATEGORY_ID = 16
