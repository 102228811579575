import { createPixel, debug } from './helpers'

export default defineNuxtPlugin(() => {
  const id = useRuntimeConfig().public.cityAdsPixelId

  function initPixel() {
    try {
      return createPixel(
        id,
        !id || useDetect().isInIframe || !!useRoute().query.disableTracking
      )
    } catch (error: any) {
      debug('error on create', error)
      return {
        trackClickIdRegister: debug,
        trackClickIdOrder: debug,
        trackProduct: debug,
        trackProducts: debug,
        trackOrder: debug,
      } as ReturnType<typeof createPixel>
    }
  }

  const {
    trackClickIdRegister,
    trackClickIdOrder,
    trackProduct,
    trackProducts,
    trackOrder,
  } = initPixel()

  return {
    provide: {
      cityads: {
        trackClickIdRegister,
        trackClickIdOrder,
        trackProduct,
        trackProducts,
        trackOrder,
      },
    },
  }
})
