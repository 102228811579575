import { markRaw } from 'vue'
import ProgramView from './ProgramView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(ProgramView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    name: 'program',
  },
}
