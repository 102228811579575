<template>
  <div>{{ eventsStore.event?.id }}</div>
</template>

<script setup lang="ts">
import { useEvents } from '~/stores/events'

defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const eventsStore = useEvents()

onMounted(() => {
  emit('update:modelValue', eventsStore.event?.id)
})

// we have this widget just to save eventId in formData
</script>
