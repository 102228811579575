<template>
  <div
    class="text-center h-full w-full flex flex-col items-center justify-center gap-10"
  >
    <ErrorNetworkImage
      v-if="isNetworkIssue"
      class="h-full max-h-56 md:max-h-72 max-w-none object-contain"
    />
    <img
      v-else
      loading="lazy"
      :src="`/error-pages/${unifiedCode}.svg`"
      class="h-full max-h-56 md:max-h-72 max-w-none object-contain"
    />
    <div class="flex flex-col gap-4">
      <h1 class="text-2xl md:text-3xl font-black">
        <template v-if="!isNetworkIssue">
          {{ $t(`errors.${unifiedCode}.title`) }}
        </template>
        <template v-else>
          {{ $t(`errors.network.title`) }}
        </template>
      </h1>
      <p class="text-gray-500 whitespace-pre-line">
        <template v-if="!isNetworkIssue">
          {{ $t(`errors.${unifiedCode}.text`) }}
        </template>
        <template v-else>
          {{ $t(`errors.network.text`) }}
        </template>
      </p>
    </div>
    <template v-if="isNetworkIssue">
      <BaseButton @click="onReload">
        {{ $t('errors.network.button') }}
      </BaseButton>
    </template>
    <div v-else class="flex max-xs:flex-col justify-center gap-2">
      <BaseLocaleLink to="/">
        <BaseButton class="w-full max-xs:min-w-52">
          {{ $t('errors.button') }}
        </BaseButton>
      </BaseLocaleLink>
      <BaseButton
        v-if="unifiedCode === 401 || unifiedCode === 403"
        look="border"
        class="w-full max-xs:min-w-52"
        @click="authStore.openLoginModal()"
      >
        {{ $t(`errors.${unifiedCode}.button`) }}
      </BaseButton>
      <template v-else-if="unifiedCode === 500">
        <BaseLocaleLink to="/event-info">
          <BaseButton look="border" class="w-full max-xs:min-w-52">
            {{ $t('errors.500.button_events') }}
          </BaseButton>
        </BaseLocaleLink>
        <BaseLocaleLink to="/academy">
          <BaseButton look="border" class="w-full max-xs:min-w-52">
            {{ $t('errors.500.button_courses') }}
          </BaseButton>
        </BaseLocaleLink>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Network } from '@capacitor/network'
import { useAuth } from '~/stores/auth'

const supportedCodes = [401, 403, 404]
const defaultCode = 500

interface PropsInterface {
  statusCode?: number
}

const props = withDefaults(defineProps<PropsInterface>(), {
  statusCode: 500,
})

const router = useRouter()
const authStore = useAuth()

const isConnected = ref(true)

const emit = defineEmits(['clear'])

const unifiedCode = computed(() => {
  const errorCode = Number(props?.statusCode)
  return !Number.isNaN(errorCode) && supportedCodes.includes(errorCode)
    ? errorCode
    : defaultCode
})

const isNetworkIssue = computed(
  () => !isConnected.value && unifiedCode.value === defaultCode
)

function onReload() {
  window.location.reload()
}

onBeforeMount(async () => {
  try {
    const { connected } = await Network.getStatus()
    isConnected.value = connected
  } catch (error: any) {
    useLogError(error)
  }

  if (!authStore.auth && unifiedCode.value === 401 && process.client) {
    authStore.openLoginModal()
  }
})

watch(
  () => router.currentRoute.value.fullPath,
  () => {
    emit('clear')
  }
)

watch(
  () => authStore.auth,
  (isAuth) => {
    if (isAuth && (unifiedCode.value === 401 || unifiedCode.value === 403)) {
      emit('clear')
    }
  }
)
</script>
