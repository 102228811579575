import {
  type YandexEcommerceEvent,
  YandexECommerceEventBrands,
  YandexECommerceEventIdPrefix,
  type YandexECommerceEventWrapper,
  YandexECommerceOrderIdPrefix,
  YandexECommerceOrderType,
  type YandexECommerceProduct,
} from './ecommerce-models'
import { debug, isActive } from './helpers'
import type {
  Currency,
  TicketDetails,
  TicketsOrderItemDetails,
} from '~/models/tickets'
import { type EventLite, EventTypeNamesMap } from '~/models/events'

export const ECOMMERCE_DATA_LAYER = 'dataLayer'

declare let window: {
  [ECOMMERCE_DATA_LAYER]: YandexECommerceEventWrapper[]
}

export function sendECommerceEvent(event: YandexEcommerceEvent) {
  if (!isActive()) {
    debug('Send ECommerce event', event)
    return
  }

  window[ECOMMERCE_DATA_LAYER] ??= []

  window[ECOMMERCE_DATA_LAYER].push({
    ecommerce: event,
  })
}

export function getECommerceProductFromTicket(
  ticket: TicketDetails | TicketsOrderItemDetails,
  currency: Currency,
  event?: EventLite | null,
  quantity: number = 1,
  coupon = '',
  discount = 0,
  position?: number
): YandexECommerceProduct {
  const brand = YandexECommerceEventBrands.HubTicket
  const category = event
    ? prepareECommerceEventCategory([EventTypeNamesMap[event.type], event.name])
    : ''

  const price =
    'prices' in ticket && ticket.prices
      ? ticket.prices[currency]
      : (ticket as TicketDetails)[
          currency.toLowerCase() as Lowercase<Currency>
        ] * 100

  return {
    id: prepareECommerceEventId(ticket.id || 0, brand),
    name: ticket.name,
    price: prepareECommercePrice(price),
    coupon,
    discount: prepareECommercePrice(discount),
    quantity,
    category,
    brand,
    position,
  }
}

export function getECommerceGoalId(): number | undefined {
  const goalId = useRuntimeConfig().public.metrikaEcommerceGoalId
  return goalId ? Number(goalId) : undefined
}

export function prepareECommerceEventId(
  id: string | number,
  brand: YandexECommerceEventBrands
): string {
  return YandexECommerceEventIdPrefix[brand] + id
}

export function prepareECommerceOrderId(
  orderId: number,
  type = YandexECommerceOrderType.GeneralOrder
): string {
  return YandexECommerceOrderIdPrefix[type] + orderId
}

export function prepareECommercePrice(price?: number): number {
  return (price || 0) / 100
}

export function prepareECommerceEventCategory(categories: string[]): string {
  return categories
    .map((category) => category.replace('/', ''))
    .filter((category) => category)
    .join('/')
}
