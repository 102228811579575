import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { useI18n } from 'vue-i18n'
import type {
  PageResponse,
  PageData,
  EditorBlock,
  PageBlock,
  BlockTemplate,
} from '~/models/page-editor'
import PageEditorTitleBlock from '~/components/page-editor/title'
import PageEditorImagesBlock from '~/components/page-editor/images'
import PageEditorMarkdownBlock from '~/components/page-editor/markdown'
import PageEditorContentBlock from '~/components/page-editor/content'
import PageEditorTicketsBlock from '~/components/page-editor/tickets'
import PageEditorSpeakersBlock from '~/components/page-editor/speakers'
import PageEditorColumnsBlock from '~/components/page-editor/columns'
import PageEditorCardsBlock from '~/components/page-editor/cards'
import PageEditorProgramBlock from '~/components/page-editor/program'
import PageEditorVideoBlock from '~/components/page-editor/video'
import PageEditorIFrameBlock from '~/components/page-editor/iframe'
import PageEditorFormBlock from '~/components/page-editor/form'
import PageEditorExpoBlock from '~/components/page-editor/expo'
import PageEditorNavigationBlock from '~/components/page-editor/navigation'
import PageEditorNavigationCustomBlock from '~/components/page-editor/navigation-custom'
import PageEditorTabsBlock from '~/components/page-editor/tabs'
import PageEditorCoverBlock from '~/components/page-editor/cover'
import PageEditorShowcaseCountersBlock from '~/components/page-editor/showcase-counters'
import PageEditorTilesBlock from '~/components/page-editor/tiles'
import PageEditorEventsBlock from '~/components/page-editor/events'
import PageEditorNewsBlock from '~/components/page-editor/news'
import PageEditorContentLinkableBlock from '~/components/page-editor/content-linkable'
import PageEditorBannersBlock from '~/components/page-editor/banners'
import PageEditorAccordionBlock from '~/components/page-editor/accordion'
import PageEditorQRCodeBlock from '~/components/page-editor/qr-code'
import EventHeaderBlock from '~/components/page-editor/event-header'
import ReviewsBlock from '~/components/page-editor/reviews'
import StatisticsBlock from '~/components/page-editor/statistics'
import IconsTextsBlock from '~/components/page-editor/icons-texts'

interface StateInterface {
  page: PageData | null
  isProcessing: boolean
  isEditMode: boolean
  isLoaded: boolean
  block: PageBlock | null
  isLoadedByAdmin: boolean
  templates: BlockTemplate[]
}

export enum BlockType {
  Title = 1,
  Images,
  Markdown,
  Content,
  Tickets,
  Speakers,
  Cards,
  Columns,
  Program,
  Video,
  IFrame,
  Expo,
  ShowcaseCounters,
  Navigation,
  Tabs,
  Cover,
  Form,
  Tiles,
  Events,
  News,
  ContentLinkable,
  Banners,
  Accordion,
  QRCode,
  EventHeader,
  Reviews,
  Statistics,
  IconsTexts,
  NavigationCustom,
}

export const usePageEditor = defineStore('page-editor', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi
  const adminURL = useRuntimeConfig().public.gatewayAdminApi
  const { locale } = useI18n()
  const nuxtApp = useNuxtApp()

  const state = reactive<StateInterface>({
    page: null,
    isProcessing: false,
    isEditMode: false,
    isLoaded: false,
    block: null,
    isLoadedByAdmin: false,
    templates: [],
  })

  const blocksList: Partial<EditorBlock>[] = [
    {
      type: BlockType.Accordion,
      ...PageEditorAccordionBlock,
    },
    {
      type: BlockType.Banners,
      ...PageEditorBannersBlock,
    },
    {
      type: BlockType.Columns,
      ...PageEditorColumnsBlock,
    },
    {
      type: BlockType.Cards,
      ...PageEditorCardsBlock,
    },
    {
      type: BlockType.Content,
      ...PageEditorContentBlock,
    },
    {
      type: BlockType.ContentLinkable,
      ...PageEditorContentLinkableBlock,
    },
    {
      type: BlockType.Cover,
      ...PageEditorCoverBlock,
    },
    {
      type: BlockType.EventHeader,
      ...EventHeaderBlock,
    },
    {
      type: BlockType.Events,
      ...PageEditorEventsBlock,
    },
    {
      type: BlockType.Expo,
      ...PageEditorExpoBlock,
    },
    {
      type: BlockType.Form,
      ...PageEditorFormBlock,
    },
    {
      type: BlockType.IconsTexts,
      ...IconsTextsBlock,
    },
    {
      type: BlockType.IFrame,
      ...PageEditorIFrameBlock,
    },
    {
      type: BlockType.Images,
      ...PageEditorImagesBlock,
    },
    {
      type: BlockType.Markdown,
      ...PageEditorMarkdownBlock,
    },
    {
      type: BlockType.Navigation,
      ...PageEditorNavigationBlock,
    },
    {
      type: BlockType.NavigationCustom,
      ...PageEditorNavigationCustomBlock,
    },
    {
      type: BlockType.News,
      ...PageEditorNewsBlock,
    },
    {
      type: BlockType.Program,
      ...PageEditorProgramBlock,
    },
    {
      type: BlockType.QRCode,
      ...PageEditorQRCodeBlock,
    },
    {
      type: BlockType.Reviews,
      ...ReviewsBlock,
    },
    {
      type: BlockType.ShowcaseCounters,
      ...PageEditorShowcaseCountersBlock,
    },
    {
      type: BlockType.Speakers,
      ...PageEditorSpeakersBlock,
    },
    {
      type: BlockType.Statistics,
      ...StatisticsBlock,
    },
    {
      type: BlockType.Tabs,
      ...PageEditorTabsBlock,
    },
    {
      type: BlockType.Tickets,
      ...PageEditorTicketsBlock,
    },
    {
      type: BlockType.Tiles,
      ...PageEditorTilesBlock,
    },
    {
      type: BlockType.Title,
      ...PageEditorTitleBlock,
    },
    {
      type: BlockType.Video,
      ...PageEditorVideoBlock,
    },
  ]

  const blocksMap = blocksList.reduce((map, item) => {
    map[item.type as number] = item
    return map
  }, {} as { [key: number | string]: any })

  const pageBlockToEditorBlock = (block: PageBlock) => {
    return {
      ...block,
      ...blocksMap[block.type],
      ...{ editorData: JSON.parse(block.content) },
      uuid: block.uuid ?? uuid(),
      key: Symbol('key'),
    }
  }

  const editorDataToContent = (editorData: any) => {
    if (editorData.name) {
      editorData.name = editorData.name.toLowerCase()
    }

    return JSON.stringify(editorData)
  }

  const editorBlock: ComputedRef<EditorBlock> = computed(() => {
    if (state.block) {
      return pageBlockToEditorBlock(state.block)
    }

    return null
  })

  const editorBlocks: ComputedRef<EditorBlock[]> = computed(() => {
    if (state.page?.blocks) {
      return state.page?.blocks.map(pageBlockToEditorBlock)
    }

    return []
  })

  const scrollMarginTop = computed(() => {
    // we suppose that at least one menu will be on page start
    const isStickyMenuOnPage = editorBlocks.value.some(
      (block) =>
        (block.type === BlockType.Navigation && block.editorData.sticky) ||
        (block.type === BlockType.NavigationCustom && block.editorData.sticky)
    )

    return `calc(
      ${isStickyMenuOnPage ? '8rem' : '4rem'} + var(--sat)
    )`
  })

  const isUrlIncludesLocale = (url: string) => {
    return url.includes(`/${locale.value}/`) || url.endsWith(`/${locale.value}`)
  }

  const removeLocaleFromUrl = (url: string) => {
    return url.replace(
      `/${locale.value}`,
      url.endsWith(`/${locale.value}`) ? '/' : ''
    )
  }

  // the whole page functions
  const fetchPage = async (
    url: string,
    isAdmin: boolean,
    checkPrev = true,
    showError = false
  ) => {
    const oldUrl = state.page?.url?.toLowerCase() || ''
    const newUrl = url.toLowerCase()

    if (checkPrev) {
      if (nuxtApp.isHydrating) {
        if (removeLocaleFromUrl(oldUrl) === removeLocaleFromUrl(newUrl)) {
          return
        }
      } else if (oldUrl === newUrl) {
        return
      }
    }

    state.isProcessing = true
    state.page = null

    try {
      const data = await useAuthFetch<PageResponse>('/page', {
        baseURL: isAdmin ? adminURL : baseURL,
        params: {
          url,
        },
      })

      state.page = data
    } catch (error: any) {
      if (error.status === 404 && showError) {
        throw useCreateScreenError({ message: 'page is not found' })
      } else if (error.status === 404 && isUrlIncludesLocale(url) && !isAdmin) {
        await fetchPage(removeLocaleFromUrl(url), isAdmin)
        return
      } else if (error.status !== 404) {
        throw error
      }

      state.page = { url }
    } finally {
      state.isLoaded = true
      state.isProcessing = false
      state.isLoadedByAdmin = isAdmin
    }
  }

  const createPage = async (url: string) => {
    state.isProcessing = true

    try {
      const data = await useAuthFetch<PageResponse>('/page', {
        baseURL: adminURL,
        method: 'POST',
        body: {
          url,
          // we create page with empty data, then we just put blocks inside "blocks" field
          // "data" field is for supporting old interface
          data: '[]',
        },
      })

      state.page = data
    } catch (error) {
      useLogError(error, true)
    } finally {
      state.isProcessing = false
    }
  }

  const updatePage = async (pageId: number, pageData: Partial<PageData>) => {
    const data = await useAuthFetch<PageResponse>(`/page/${pageId}`, {
      baseURL: adminURL,
      method: 'PUT',
      body: pageData,
    })

    state.page = data
  }

  const deletePage = async (pageId: number) => {
    try {
      state.isProcessing = true
      await useAuthFetch<PageResponse>(`/page/${pageId}`, {
        baseURL: adminURL,
        method: 'DELETE',
      })

      state.page = null
    } catch (error: any) {
      useLogError(error, true)
    } finally {
      state.isProcessing = false
    }
  }

  // blocks functions
  const fetchPageBlock = async (id: string) => {
    if (state.block?.uuid === id) {
      return
    }

    const { data } = await useAuthFetch<{ data: PageBlock }>(
      `/pages/blocks/${id}`,
      {
        baseURL,
      }
    )

    state.block = data
  }

  const getPageBlock = async (uuid: string, isAdmin = false) => {
    const { data } = await useAuthFetch<{ data: PageBlock }>(
      `/pages/blocks/${uuid}`,
      {
        baseURL: isAdmin ? adminURL : baseURL,
      }
    )

    return data
  }

  const createPageBlock = async (block: EditorBlock, pageId: number) => {
    const { data } = await useAuthFetch<{ data: PageBlock }>('/pages/blocks', {
      baseURL: adminURL,
      method: 'POST',
      body: {
        ...block,
        content: editorDataToContent(block.editorData),
        page_id: pageId,
        is_visible: true,
      },
    })

    return data
  }

  const updatePageBlock = async (block: Partial<EditorBlock>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { priority, ...blockData } = block
    const { data } = await useAuthFetch<{ data: PageBlock }>(
      `/pages/blocks/${block.uuid}`,
      {
        baseURL: adminURL,
        method: 'PUT',
        body: {
          ...blockData,
          content: editorDataToContent(block.editorData),
        },
      }
    )

    return data
  }

  const deletePageBlock = async (uuid: string) => {
    await useAuthFetch(`/pages/blocks/${uuid}`, {
      baseURL: adminURL,
      method: 'DELETE',
    })
  }

  async function updateSort(blocks: PageBlock[]) {
    if (!blocks.every((block) => block.id)) {
      console.warn('there are blocks without id')
      return
    }

    const items = blocks.map((block, index) => ({
      id: block.id,
      priority: index + 1,
    }))

    await useAuthFetch('/pages/blocks/sort', {
      baseURL: adminURL,
      method: 'PUT',
      body: { items },
    })
  }

  // templates functions
  const fetchBlockTemplateList = async () => {
    if (state.templates.length) {
      return
    }

    const { data } = await useAuthFetch<{ data: BlockTemplate[] }>(
      '/block-templates',
      {
        baseURL: adminURL,
        params: {
          per_page: 100,
        },
      }
    )

    state.templates = data
  }

  const createBlockTemplate = async (template: Partial<BlockTemplate>) => {
    const data = await useAuthFetch<BlockTemplate>('/block-templates', {
      baseURL: adminURL,
      method: 'POST',
      body: template,
    })

    state.templates.push(data)
  }

  const deleteBlockTemplate = async (templateId: number) => {
    await useAuthFetch(`/block-templates/${templateId}`, {
      baseURL: adminURL,
      method: 'DELETE',
    })

    state.templates = state.templates.filter(
      (template) => template.id !== templateId
    )
  }

  const turnOnEditMode = () => {
    state.isEditMode = true
  }

  const turnOffEditMode = () => {
    state.isEditMode = false
  }

  const toggleEditMode = () => {
    state.isEditMode = !state.isEditMode
  }

  const resetPage = () => {
    state.isLoaded = false
    state.page = null
  }

  function getBlockId(block: EditorBlock) {
    return block.editorData.name || 'block-' + block.uuid
  }

  return {
    page: toRef(state, 'page'),
    isEditMode: toRef(state, 'isEditMode'),
    isProcessing: toRef(state, 'isProcessing'),
    isLoaded: toRef(state, 'isLoaded'),
    block: toRef(state, 'block'),
    isLoadedByAdmin: toRef(state, 'isLoadedByAdmin'),
    templates: toRef(state, 'templates'),
    editorBlocks,
    editorBlock,
    blocksList,
    blocksMap,
    scrollMarginTop,
    getBlockId,
    fetchPage,
    createPage,
    updatePage,
    deletePage,
    resetPage,
    turnOnEditMode,
    turnOffEditMode,
    toggleEditMode,
    fetchPageBlock,
    getPageBlock,
    createPageBlock,
    updatePageBlock,
    deletePageBlock,
    updateSort,
    pageBlockToEditorBlock,
    fetchBlockTemplateList,
    createBlockTemplate,
    deleteBlockTemplate,
  }
})
