<template>
  <div
    :id="uuid"
    class="px-6 md:px-8 pt-3"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      textAlign: formData.textAlign,
    }"
  >
    <div
      class="marked"
      :style="{ color: formData.textColor, '--text-color': formData.textColor }"
      v-html="getMarkedTextWithBlankLinks(formData.text)"
    />
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()
interface PropsInterface {
  uuid: string
  formData: any
}

defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()
</script>
