import { markRaw } from 'vue'
import ShowcaseCountersView from './ShowcaseCountersView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(ShowcaseCountersView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {},
}
