import { DEFAULT_COLOR_GRAY, DEFAULT_COLOR_PRIMARY } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  linkColorSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
} from '../common'
import { enumToOptions, optionsToMap } from '~/utils/options-utils'

export enum FormFieldType {
  Name = 'name',
  Email = 'email',
  Text = 'text',
  Checkbox = 'checkbox',
  Date = 'date',
  Datetime = 'datetime-local',
  Textarea = 'textarea',
  Number = 'number',
  Radio = 'radio',
  Select = 'select',
  MultiSelect = 'multi-select',
  Time = 'time',
  File = 'file',
}

export const FormFieldTypeOptions = enumToOptions(FormFieldType, true)
export const FormFieldTypeMap = optionsToMap(FormFieldTypeOptions)

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Form',
  description: 'Form with a list of field',
  type: 'object',
  required: ['buttonTitle', 'fields'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'textColor',
      'bgColor',
      'linkColor',
      'blockShadow',
      'buttonText',
      'buttonColor',
      'buttonStyle',
      'border',
      'borderColor',
      'maxWidth',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    action: {
      title: 'Form Type',
      type: 'string',
      enum: ['default', 'marketplace-product-request'],
      enumNames: ['Default', 'Marketplace Product Request'],
      default: 'default',
    },
    eventId: {
      title: 'Event id',
      type: 'number',
      'ui:hidden': '{{ true }}',
    },
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...linkColorSchema,
    ...bgColorSchema,
    buttonText: {
      title: 'Button text',
      type: 'string',
      default: 'Submit',
    },
    buttonStyle: {
      title: 'Button style',
      enum: ['solid', 'border'],
      enumNames: ['Solid', 'Border'],
      default: 'solid',
      'ui:widget': 'RadioWidget',
    },
    buttonColor: {
      title: 'Button color',
      type: 'string',
      default: DEFAULT_COLOR_PRIMARY,
    },
    border: {
      title: 'Use border',
      type: 'boolean',
      default: false,
    },
    borderColor: {
      title: 'Border color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
      'ui:hidden': '{{ parentFormData.border === false }}',
    },
    maxWidth: {
      title: 'Max width of the form (in px)',
      description: "If it's not set, the form width will be 576px",
      type: 'number',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    emailTemplate: {
      title: 'Email Template',
      description:
        'Select an email template if you want to send an email after form submission',
      type: 'number',
    },
    successMessage: {
      title: 'Success Message',
      description: 'Displayed after submitting the form',
      type: 'string',
    },
    textUnderForm: {
      title: 'Text under form',
      type: 'string',
    },
    fields: {
      type: 'array',
      title: 'Fields',
      minItems: 1,
      description:
        'Be careful, if you delete "email" and "name", you won\'t be able to identify the user',
      'ui:options': {
        showIndexNumber: true,
      },
      default: [],
      items: {
        type: 'object',
        required: ['title', 'type'],
        properties: {
          title: {
            title: 'Title',
            type: 'string',
            description: 'Attention! Must be unique for each field!',
          },
          type: {
            title: 'Type',
            enum: Object.values(FormFieldType),
            enumNames: Object.keys(FormFieldType),
            default: 'text',
          },
          placeholder: {
            title: 'Placeholder',
            type: 'string',
            default: '',
            'ui:hidden':
              '{{ parentFormData.type === "checkbox" || parentFormData.type === "radio" || parentFormData.type === "date" || parentFormData.type === "datetime-local" || parentFormData.type === "file" || parentFormData.type === "time"}}',
          },
          required: {
            title: 'Required',
            type: 'boolean',
            default: false,
            'ui:hidden': '{{ parentFormData.type === "checkbox" }}',
          },
          min: {
            title: 'Min',
            type: 'number',
            'ui:hidden': '{{ parentFormData.type !== "number" }}',
          },
          max: {
            title: 'Max',
            type: 'number',
            'ui:hidden': '{{ parentFormData.type !== "number" }}',
          },
          rowsNumber: {
            title: 'Rows number',
            type: 'number',
            'ui:hidden': '{{ parentFormData.type !== "textarea" }}',
          },
          options: {
            type: 'array',
            title: 'Options',
            'ui:options': {
              showIndexNumber: true,
            },
            'ui:hidden':
              '{{ parentFormData.type !== "radio" && parentFormData.type !== "select" && parentFormData.type !== "multi-select" }}',
            items: {
              type: 'object',
              required: ['label', 'value'],
              properties: {
                label: {
                  title: 'Label',
                  type: 'string',
                  description: 'Must be unique for each option',
                },
                value: {
                  title: 'Value',
                  type: 'string',
                  description: 'Must be unique for each option',
                },
              },
            },
          },
        },
      },
    },
  },
}
