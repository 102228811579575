<template>
  <div>
    <div v-if="!column.isImage && column.text" class="flex flex-col gap-4">
      <div
        class="marked"
        v-html="getMarkedTextWithBlankLinks(column.text, linkColor)"
      />
      <div v-if="column.buttons?.length" class="flex flex-wrap gap-4">
        <template v-for="(button, idx) in column.buttons" :key="idx">
          <EditorButton :button="button" />
        </template>
      </div>
    </div>
    <div v-else-if="column.isImage && column.image">
      <BaseLink
        v-if="column.link"
        :href="isRelativeLink(column.link) ? undefined : linkUrl"
        :to="isRelativeLink(column.link) ? linkUrl : undefined"
        :target="getLinkTarget(column.link, column.isBlank)"
        class="w-full"
      >
        <img :src="column.image" class="rounded-xl w-full" />
      </BaseLink>
      <img v-else :src="column.image" class="rounded-xl w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonInterface } from '../common'
import EditorButton from '~/components/page-editor/EditorButton.vue'

interface ColumnInterface {
  isImage?: boolean
  text?: string
  buttons: ButtonInterface[]
  image?: string
  link?: string
  isBlank?: boolean
}

interface PropsInterface {
  column: ColumnInterface
  linkColor?: string
}

const props = defineProps<PropsInterface>()

const { getLinkTarget, getLinkUrl, isRelativeLink } = useEditorLinks()
const { getMarkedTextWithBlankLinks } = useMarkedText()

const linkUrl = computed(() => getLinkUrl(props.column.link))
</script>
