import { markRaw } from 'vue'
import NavigationView from './NavigationView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(NavigationView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    menuStyle: 'bg',
    items: [],
  },
}
