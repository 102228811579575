<template>
  <div
    :id="uuid"
    ref="eventHeaderBlock"
    class="bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      color: formData.textColor,
      'background-color': formData.bgColor,
    }"
  >
    <EventsItemPageHeader
      :hide-title="formData.hideTitle"
      :hide-image="formData.hideImage"
      :hide-dates="formData.hideDates"
      :hide-google-calendar="formData.hideGoogleCalendar"
      :hide-hub-dates="formData.hideHubDates"
      :hide-place="formData.hidePlace"
      :hide-sharing="formData.hideSharing"
      :hide-bottom-section="formData.hideBottomSection"
      :overlay-text="formData.overlayText"
      :show-overlay="formData.showOverlay"
      :hide-favorite-button="formData.hideFavoriteButton"
      @loaded="onLoaded"
    />
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

interface FormDataInterface {
  hideImage: boolean
  hideTitle: boolean
  hideDates: boolean
  hideSharing?: boolean
  hideBottomSection?: boolean
  hideGoogleCalendar: boolean
  hideHubDates: boolean
  hidePlace: boolean
  blockShadow?: boolean
  textColor?: string
  bgColor?: string
  showOverlay?: boolean
  overlayText?: string
  hideFavoriteButton?: boolean
}

interface PropsInterface {
  uuid: string
  formData: FormDataInterface
}

defineProps<PropsInterface>()

const emit = defineEmits(['loaded'])

const pageEditorStore = usePageEditor()

const eventHeaderBlock = ref<HTMLElement | null>(null)

const onLoaded = () => {
  emit('loaded', eventHeaderBlock, 'event-header')
}
</script>
