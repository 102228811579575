export interface StorageEvent {
  key: string
  newValue: any
  oldValue: any
}

export const useSessionStorage = () => {
  const checkIsServer = () => {
    if (process.server) {
      throw new Error("Don't use session storage on server side")
    }
  }

  const getSessionStorageValue = (key: string) => {
    checkIsServer()

    try {
      const value = window.sessionStorage.getItem(key)

      return value ? JSON.parse(value) : null
    } catch (error: any) {
      console.error(error)
      return null
    }
  }

  const setSessionStorageValue = (key: string, value: any) => {
    checkIsServer()

    try {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error: any) {
      console.error(error)
    }
    return value
  }

  const removeSessionStorageValue = (key: string) => {
    checkIsServer()

    try {
      window.sessionStorage.removeItem(key)
    } catch (error: any) {
      console.error(error)
    }
  }

  return {
    getSessionStorageValue,
    setSessionStorageValue,
    removeSessionStorageValue,
  }
}
