export function getFieldLocale(
  value: string | Record<string, string>,
  locale = 'en',
  parse = false
): string {
  if (!value) {
    return ''
  }

  if (parse && typeof value === 'string') {
    value = parseFieldLocales(value)
  }

  return typeof value === 'object' ? value[locale] || value.en : value
}

export function parseFieldLocales(
  value?: string
): string | Record<string, string> {
  if (!value) {
    return ''
  }

  try {
    const data = JSON.parse(value, (_key, value) =>
      typeof value !== 'object' ? value.toString() : value
    )
    return data
  } catch (error) {
    return value.toString()
  }
}
