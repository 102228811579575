import { markRaw } from 'vue'
import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_SHORT_TEXT,
  DEFAULT_TEXT,
} from '../constants'
import VideoView from './VideoView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(VideoView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: DEFAULT_TEXT,
    description: DEFAULT_SHORT_TEXT,
    textColor: DEFAULT_COLOR_GRAY,
    source: 'youtube',
    videoUrl:
      'https://www.youtube.com/embed/vb8NvZkck5c?origin=https://wnhub.io',
  },
}
