import { basePropertiesSchema } from '../base-schema-properties'
import { shadowSchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Showcase counters',
  description: 'Showcase counters with chart',
  type: 'object',
  required: ['eventId', 'showcaseSlug'],
  properties: {
    ...basePropertiesSchema,
    ...shadowSchema,
    eventId: {
      title: 'Event id',
      type: 'number',
    },
    showcaseSlug: {
      title: 'Showcase slug',
      type: 'string',
    },
  },
}
