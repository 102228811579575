export interface SimpleRoute {
  name: string
  params?: Record<string, string | number>
}

export interface MenuItem {
  id: number | string
  slug?: string
  route: SimpleRoute | string
  icon?: string
  local: string
  isMain?: boolean
  type?: 'page' | 'link' | 'header'
  hidden?: boolean
  isActive?: boolean
  isCategory?: boolean
  rightIcon?: {
    isShow: boolean
    name: string
    class: string
  }
}

export interface TopbarMenuInterface {
  title: string
  items: MenuItem[]
  style: 'col' | 'row'
  width?: string
}

export const sidebarBottomListMaxLength = 5
