import type { AuthData } from '~/models/auth'
import { AppCookieKeys } from '~/composables/use-app-cookie'

export function useAuthCookie() {
  const { load, cookie } = useAppCookie<AuthData | null>(
    AppCookieKeys.GatewayAuth,
    {
      maxAge: 1 * 365 * 24 * 60 * 60,
      domain: useDomain(),
    }
  )

  return { load, cookie }
}
