import { marked } from 'marked'
import { defineNuxtPlugin } from '#app'

marked.setOptions({
  // breaks: true,
  // gfm: true,
  pedantic: true,
})

function replaceNewlinesInPTags(text: string) {
  // Replace matching \n with <br>\n, but only if not preceded by <br>
  return text.replace(/<p>([\s\S]*?)<\/p>/g, (_match, p1) => {
    const replacedContent = p1.replace(/(<br>)?\n/g, () => '<br>\n')
    return `<p>${replacedContent}</p>`
  })
}

function addLazyForImages(text: string) {
  return text.replace(/<img/g, '<img loading="lazy"')
}

function convertStrikethrough(text: string) {
  // Replace ~~text~~ with <del>text</del> as pedantic mode not support strikethrough
  return text.replace(/~~(.*?)~~/g, '<del>$1</del>')
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('marked', (text: string) => {
    const result = addLazyForImages(
      convertStrikethrough(replaceNewlinesInPTags(marked(text)))
    )
    return result
  })
})

declare module '#app' {
  interface NuxtApp {
    $marked: typeof marked
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $marked: typeof marked
  }
}
