import { markRaw } from 'vue'
import TicketsView from './TicketsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(TicketsView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'Choose your tickets',
    subtitle: 'You can add tickets to the cart',
  },
}
