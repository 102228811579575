<template>
  <div
    ref="selectElement"
    class="relative"
    :class="hasError && 'has-error'"
    style="scroll-margin-top: calc(4rem + var(--sat))"
  >
    <label
      v-if="label"
      class="block mb-1 text-sm font-medium leading-5"
      :class="labelPadding"
    >
      {{ label }}
      {{ required ? '*' : '' }}
    </label>
    <div
      v-if="description"
      class="text-xs text-gray-500 mb-1 -mt-1"
      :class="labelPadding"
    >
      {{ description }}
    </div>
    <slot name="above" />
    <div v-clickaway="close" :class="proxyRounded">
      <div
        class="flex truncate relative border focus:border-blue-300 transition duration-150 ease-in-out"
        :class="[
          errorMessage ? 'border-red-400' : 'border-gray-300',
          proxyRounded,
        ]"
      >
        <div
          class="absolute inset-0 bg-clip-content"
          :class="[
            disabled ? disabledBackgroundClass : backgroundClass,
            proxyRounded,
          ]"
        />

        <input
          type="text"
          class="absolute h-0 w-0 pointer-events-none opacity-0"
          aria-label=""
          :name="name"
          @focus="open"
          @blur="close"
        />

        <button
          ref="input"
          tabindex="-1"
          class="relative shadow-sm flex items-center w-full h-10 bg-transparent focus:outline-none focus:shadow-outline-blue sm:text-sm sm:leading-5 truncate"
          :class="[
            disabled && 'cursor-not-allowed',
            loading && 'pointer-events-none',
            proxyRounded,
            inputPaddingLeft,
            inputPaddingRight,
          ]"
          type="button"
          :disabled="disabled"
          @click="toggle"
          @keydown="onKeydown"
        >
          <span
            v-if="selectedLabels.length > 0"
            class="flex-1 w-0 text-left truncate"
          >
            {{ selectedLabels.join(', ') }}
          </span>
          <span v-else class="flex-1 w-0 text-left text-gray-400">
            {{ placeholder }}
          </span>
          <span class="flex-shrink-0 ml-3">
            <BaseSpinner v-if="loading" size="xs" />
            <BaseIcon
              v-else
              name="outline_chevron_down"
              :class="expanded && 'transform rotate-180'"
              size="xs"
            />
          </span>
        </button>

        <BaseButton
          v-if="showClearButton"
          theme="white"
          class="relative w-10 !p-0 ml-3 flex-shrink-0"
          tabindex="-1"
          @click="clear"
        >
          <BaseIcon name="outline_trash" />
        </BaseButton>
      </div>

      <transition
        enter-active-class="transition-all duration-200"
        enter-from-class="transform -translate-y-3 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition-all duration-200"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform -translate-y-3 opacity-0"
      >
        <div
          v-if="expanded"
          class="absolute z-10 left-0 w-full origin-top-left rounded-md shadow-lg"
          :class="[!canOpenBottom && 'bottom-full']"
          :style="{ marginTop: `${listMargin}px` }"
        >
          <div
            class="rounded-md whitespace-nowrap shadow-xs bg-white overflow-y-auto"
            :style="{ maxHeight: `${listMaxHeight}px` }"
          >
            <div class="px-2 pt-2">
              <input
                ref="searchInput"
                v-model="search"
                class="appearance-none block w-full px-3 h-10 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                aria-label=""
                :placeholder="searchPlaceholder"
                @keypress.enter.prevent
                @keydown="onKeydown"
              />
            </div>

            <div class="py-2" role="menu" aria-orientation="vertical">
              <div v-if="loading" class="truncate px-4 py-2 text-gray-500">
                <BaseSpinner class="mx-auto" />
              </div>
              <div
                v-else-if="proxyOptions.length === 0"
                class="truncate px-4 py-2 text-gray-500"
              >
                {{ emptyPlaceholder }}
              </div>
              <template v-else>
                <div
                  v-for="(option, idx) in proxyOptions"
                  :key="idx"
                  class="flex truncate items-center px-4 py-2 cursor-pointer focus:outline-none"
                  :class="[
                    focusedOption &&
                      focusedOption.value === option.value &&
                      'bg-gray-100 text-gray-800',
                    selectedValues.includes(option.value)
                      ? 'text-primary-500 bg-primary-100'
                      : 'hover:bg-gray-100 hover:text-gray-800',
                    showEmptyOption && idx === 0
                      ? 'text-gray-300'
                      : 'text-gray-500',
                  ]"
                  role="menuitem"
                  @click="onSelect(option)"
                >
                  <BaseButton
                    v-if="multiple"
                    class="w-6 !p-0 mr-3 flex-shrink-0"
                    size="xs"
                    tabindex="-1"
                    :look="
                      selectedValues.includes(option.value) ? 'solid' : 'border'
                    "
                    :theme="
                      selectedValues.includes(option.value)
                        ? 'primary'
                        : 'white'
                    "
                  >
                    <BaseIcon
                      v-if="selectedValues.includes(option.value)"
                      name="outline_check"
                    />
                  </BaseButton>

                  <slot :option="option" name="option">
                    <span
                      class="flex-1 truncate"
                      :title="option.label || String(option.value)"
                    >
                      {{ option.label || option.value }}
                    </span>
                  </slot>
                </div>
              </template>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="flex pt-px text-sm leading-4">
      <transition
        enter-active-class="transition-all duration-300"
        enter-from-class="transform -translate-y-3 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition-all duration-300"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform -translate-y-3 opacity-0"
      >
        <span v-if="hasError" class="text-red-600 mt-1" :class="labelPadding">
          <slot name="error">
            {{ errorMessage }}
          </slot>
        </span>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { RuleExpression } from 'vee-validate'
import { useSimpleInput } from './use-simple-input'
import type { OptionInterface } from './use-simple-input'
const listMargin = 8
const listMaxHeight = 256
const listHeight = listMargin + listMaxHeight

const roundedMap = {
  none: 'rounded-none',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full',
}

const props = defineProps({
  modelValue: {
    type: null as unknown as PropType<
      string | number | null | (string | number)[]
    >,
    default: '',
  },
  value: {
    type: null as unknown as PropType<string | number | null>,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: '',
  },
  emptyPlaceholder: {
    type: String,
    default: 'Nothing to show',
  },
  searchPlaceholder: {
    type: String,
    default: 'Search...',
  },
  label: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  rounded: {
    type: String as PropType<keyof typeof roundedMap>,
    default: 'xl',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fetchOnOpen: {
    type: Boolean,
    default: true,
  },
  resetOnOpen: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  rules: {
    type: [String, Function, Object] as PropType<
      RuleExpression<boolean | object | string | number | any[] | null>
    >,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  empty: {
    type: String,
    default: undefined,
  },
  emptyValue: {
    type: [String, Number],
    default: undefined,
  },
  minLength: {
    type: Number,
    default: 0,
  },
  initial: {
    type: [Object, Array] as PropType<OptionInterface | OptionInterface[]>,
    default: undefined,
  },
  fetch: {
    type: Function,
    default: () => [],
  },
  backgroundClass: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, string>
    >,
    default: 'bg-white',
  },
  disabledBackgroundClass: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, string>
    >,
    default: 'bg-gray-100',
  },
})

const emit = defineEmits(['change', 'update:modelValue', 'change:option'])

defineExpose({
  clear,
  clearOptions,
})

const { isApp } = useDetect()
const { isMobile } = useDevice()
const focused = ref(-1)
const options = ref<OptionInterface[]>([])
const selected = ref<OptionInterface[]>([])
const isSelectUsed = ref(false)
const loading = ref(false)
const expanded = ref(false)
const readyToWatch = ref(false)
const search = ref('')
const canOpenBottom = ref(true)
const previousInitial = ref<OptionInterface | OptionInterface[] | undefined>(
  undefined
)

const { errorMessage, value: validatorValue } = useSimpleInput(
  props,
  getCurrentInstance()
)

const slots = useSlots()
const hasError = computed(
  () => !props.disabled && (errorMessage.value || slots.error)
)
const inputValue = computed(() => {
  return props.modelValue === undefined ? props.value : props.modelValue
})
const parsedValue = computed(() => {
  let value = inputValue.value

  if (props.multiple && !Array.isArray(value)) {
    value = value ? [value] : []
  }

  return value
})
const showEmptyOption = computed(() => {
  return props.empty && selectedLabels.value
})
const proxyOptions = computed<OptionInterface[]>(() => {
  const proxyOptions = [...options.value]

  if (showEmptyOption.value) {
    proxyOptions.unshift({
      label: props.empty as string,
      value: props.emptyValue,
    })
  }

  return proxyOptions
})
const proxyRounded = computed(() => {
  return props.rounded && roundedMap[props.rounded]
})
const showClearButton = computed(() => {
  return props.multiple && selected.value.length > 0
})
const labelPadding = computed(() => {
  return props.rounded === 'full' ? 'px-6' : 'px-4'
})
const inputPaddingLeft = computed(() => {
  return props.rounded === 'full' ? 'pl-6' : 'pl-4'
})
const inputPaddingRight = computed(() => {
  return showClearButton.value
    ? 'pr-0'
    : props.rounded === 'full'
    ? 'pr-6'
    : 'pr-4'
})
const selectedValues = computed(() => {
  return selected.value.map(({ value }) => value)
})
const selectedLabels = computed(() => {
  return selected.value.map(({ label }) => label)
})
const focusedOption = computed(() => {
  return proxyOptions.value[focused.value]
})
watch(search, (search) => {
  if (
    readyToWatch.value &&
    search.length >= props.minLength &&
    !selectedLabels.value.includes(search)
  ) {
    loading.value = true
    debounceFetch(search)
  }
})
watch(
  () => props.modelValue,
  (value) => {
    handleValue(value)
    validatorValue.value = value
  }
)
watch(
  () => props.value,
  (value) => {
    handleValue(value)
  }
)
watch(
  () => props.initial,
  () => {
    initSelect()
  },
  { deep: true }
)

onBeforeMount(() => initSelect())
onServerPrefetch(() => initSelect())

function initSelect() {
  if (!isSelectUsed.value) {
    if (previousInitial.value) {
      if (Array.isArray(previousInitial.value)) {
        previousInitial.value.forEach((item) => removePreviousInitial(item))
      } else {
        removePreviousInitial(previousInitial.value)
      }
    }

    if (props.initial) {
      if (Array.isArray(props.initial)) {
        props.initial.forEach((item) => pushInitial(item))
      } else {
        pushInitial(props.initial)
      }
    }

    previousInitial.value = props.initial
  }
}
function removePreviousInitial(previousInitialOption: OptionInterface) {
  options.value = options.value.filter(
    (option) => option !== previousInitialOption
  )
  selected.value = selected.value.filter(
    (option) => option !== previousInitialOption
  )
}
function pushInitial(initialOption: OptionInterface) {
  options.value.push(initialOption)
  selected.value.push(initialOption)
}
function handleValue(value: any) {
  // TODO we need to refact this component https://app.asana.com/0/1198300825304238/1202801033185771/f
  if (!value || (Array.isArray(value) && value.length === 0)) {
    clear()
  }
}
const debounceFetch = useDebounce(async function (search: string) {
  options.value = await props.fetch(search || undefined)
  loading.value = false
}, 400)

const searchInput = ref<InstanceType<typeof HTMLInputElement> | null>(null)
function open() {
  if (props.resetOnOpen) {
    search.value = ''
    options.value = []
    initSelect()
  }

  expanded.value = true
  const isFetchOnOpen =
    props.fetchOnOpen && (props.multiple ? true : options.value.length < 2)
  const isFetchByMinLength = search.value.length > props.minLength

  if (isFetchOnOpen) {
    loading.value = true
    debounceFetch('')
  }

  if (isFetchByMinLength) {
    search.value = ''
    loading.value = true
    debounceFetch('')
  }

  nextTick(() => {
    checkPosition()
    !isApp && !isMobile && searchInput.value?.focus()
    readyToWatch.value = true
  })
}

const selectElement = ref<InstanceType<typeof HTMLDivElement> | null>(null)
function checkPosition() {
  const rectEl = selectElement.value?.getBoundingClientRect()

  if (rectEl) {
    canOpenBottom.value = window.innerHeight - rectEl.bottom > listHeight
  }
}
function close() {
  focused.value = -1
  expanded.value = false
  readyToWatch.value = false
}
function toggle() {
  if (expanded.value) {
    close()
  } else {
    open()
  }
}
function clearOptions() {
  options.value = []
}

function clear() {
  const value = parsedValue.value

  selected.value = []
  isSelectUsed.value = false

  if (Array.isArray(value) && value.length > 0) {
    emit('update:modelValue', [])
    emit('change:option', undefined)
    emit('change', undefined)
  }
}
function onKeydown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown') {
    focused.value++

    if (!options.value[focused.value]) {
      focused.value = 0
    }
  }

  if (event.key === 'ArrowUp') {
    focused.value--

    if (!options.value[focused.value]) {
      focused.value = options.value.length - 1
    }
  }

  if (event.key === 'Enter' || event.key === 'Escape') {
    const option = options.value[focused.value]

    select(option)
    close()
  }
}
function onSelect(option: any) {
  select(option)

  if (!props.multiple) {
    close()
  }
}
function select(option: OptionInterface) {
  if (!option) {
    return
  }

  const value = parsedValue.value
  let result: any = option.value

  if (props.multiple && Array.isArray(value)) {
    if (selectedValues.value.includes(option.value)) {
      result = value.filter((v) => {
        if (parseInt(v as string)) {
          return parseInt(v as string) !== option.value
        }

        return v !== option.value
      })

      selected.value = selected.value.filter((item) => {
        if (parseInt(item.value as string)) {
          return parseInt(item.value as string) !== option.value
        }

        return item.value !== option.value
      })
    } else {
      result = [...value, option.value]
      selected.value = [...selected.value, option]
    }
  } else {
    selected.value = [option]
  }

  isSelectUsed.value = true

  emit('update:modelValue', result)
  emit('change', option)
  emit('change:option', option)
}
</script>
