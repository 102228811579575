export default {
  description: {
    'ui:widget': 'EditorMarkdownWidget',
  },
  source: {
    'ui:widget': 'RadioWidget',
  },
  textColor: {
    'ui:widget': 'BaseColorWidget',
  },
}
