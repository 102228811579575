declare let window: {
  _linkedin_data_partner_ids: any
  lintrk: any
}

function loadScript(id: string) {
  // eslint-disable-next-line camelcase
  const _linkedin_partner_id = id
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
  window._linkedin_data_partner_ids.push(_linkedin_partner_id)
  ;(function (l) {
    if (!l) {
      window.lintrk = function (a: any, b: any) {
        window.lintrk.q.push([a, b])
      }
      window.lintrk.q = []
    }
    const s = document.getElementsByTagName('script')[0]
    const b = document.createElement('script')
    b.type = 'text/javascript'
    b.async = true
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
    s.parentNode?.insertBefore(b, s)
  })(window.lintrk)
}

export function debug(...args: any[]) {
  console.log('[Linkedin Tag]', ...args)
}

export function createTag(id: string, isDebug = false) {
  if (!isDebug) {
    loadScript(id)

    console.log('analytics: Linkedin Tag successfully inited')

    return (...args: any[]) => {
      debug(...args)
      window.lintrk(...args)
    }
  } else {
    return debug
  }
}
