<template>
  <div class="w-full">
    <div class="flex items-center gap-2" :class="[proxyAlign]">
      <div
        class="flex-shrink-0 relative bg-white p-1"
        :class="[proxySize, proxyRounded, wrapperClass]"
      >
        <div v-if="$slots.topBadge" class="absolute top-0 right-0 z-10">
          <slot name="topBadge" />
        </div>
        <BaseRatio
          class="overflow-hidden text-white"
          :class="[proxyRounded, avatarClass]"
          :src="optimizedImg"
          :size="bgSize"
        >
          <slot v-if="!src" name="icon">
            <div
              class="absolute inset-0 flex items-center justify-center"
              :class="[stubClass || 'bg-gray-400']"
            >
              <BaseIcon :name="icon" size="none" class="w-full h-full" />
            </div>
          </slot>
        </BaseRatio>

        <slot name="badge" />
      </div>
      <div v-if="$slots.title || $slots.text || title || text">
        <slot name="title">
          <p v-if="title" class="leading-4 text-sm font-medium">
            {{ title }}
          </p>
          <div
            v-else-if="isLoading"
            class="h-3 w-20 bg-gray-300 rounded-md animate-pulse"
          />
        </slot>
        <slot name="text">
          <p v-if="text" class="leading-4 text-xs font-medium opacity-50">
            {{ text }}
          </p>
          <div
            v-else-if="isLoading"
            class="h-2 w-10 bg-gray-300 rounded-md animate-pulse"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const sizeList = {
  sm: 'w-4',
  md: 'w-8',
  lg: 'w-10',
  xl: 'w-12',
  '2xl': 'w-16',
  '3xl': 'w-20',
  '4xl': 'w-28',
  '5xl': 'w-48',
}

const alignList = {
  left: '',
  right: 'flex-row-reverse text-right',
}

const roundedList = {
  normal: 'rounded',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  full: 'rounded-full',
}

interface Props {
  avatarClass?: string
  wrapperClass?: string
  stubClass?: string
  icon?: string
  align?: keyof typeof alignList
  size?: keyof typeof sizeList
  bgSize?: string
  rounded?: keyof typeof roundedList
  title?: string
  text?: string
  src?: string
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  avatarClass: '',
  wrapperClass: '',
  stubClass: '',
  icon: 'solid_user_circle',
  align: 'left',
  size: 'md',
  bgSize: 'cover',
  rounded: 'full',
  title: '',
  text: '',
  src: '',
  isLoading: false,
})

const proxySize = computed(() => props.size && sizeList[props.size])
const proxyAlign = computed(() => props.align && alignList[props.align])
const proxyRounded = computed(() => props.rounded && roundedList[props.rounded])

const optimizedImg = computed(() => {
  if (!props.src) return props.src
  // convert tailwind size to rem (/4) to px (*16) minus padding (-8) for x2 density (*2)
  const pxSize =
    props.size &&
    ((Number(sizeList[props.size].split('-')[1]) / 4) * 16 - 8) * 2

  return useProcessImage({
    url: props.src,
    ...(props.size && {
      width: pxSize,
      height: pxSize,
    }),
  })
})
</script>
