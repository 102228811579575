import { useI18n } from 'vue-i18n'
import { useNotifications } from '~/stores/notifications'

export const useClipboardCopy = () => {
  const { t } = useI18n()
  const { pushNotification } = useNotifications()

  const copyLink = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link)
      pushNotification({
        title: t('notifications.share.copy_link_success'),
      })
    } catch (error) {
      pushNotification({
        title: t(`notifications.share.copy_link_error`),
        theme: 'destructive',
      })
      useLogError(new Error('error on clipboard.writeText'))
    }
  }

  return {
    copyLink,
  }
}
