<template>
  <div
    class="flex items-center px-4 py-2 gap-5"
    :style="{
      color: textColor,
      '--text-color': textColor,
    }"
  >
    <img
      v-if="card.icon"
      :src="card.icon"
      class="object-contain w-28 h-auto shrink-0"
    />
    <div class="marked" v-html="markedText" />
  </div>
</template>

<script setup lang="ts">
interface CardInterface {
  icon?: string
  text?: string
}

interface PropsInterface {
  card: CardInterface
  textColor?: string
  linkColor?: string
}

const props = defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()

const markedText = computed(() =>
  getMarkedTextWithBlankLinks(props.card.text || '', props.linkColor)
)
</script>
