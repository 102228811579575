import { FetchError } from 'ofetch'
import { defineStore } from 'pinia'
import type { Showcase, ShowcaseLite } from '~/models/showcases'
import type { ShowcaseCounters } from '~/models/showcase-counters'

const API_ENDPOINT = '/showcases'

interface ShowcasesState {
  showcase: Showcase | null
  showcaseProps: {
    eventId: number | null
    slug: string | null
  }
  availableShowcases: ShowcaseLite[] | null
  showcaseCounters: ShowcaseCounters | null
  showcaseCountersProps: {
    eventId: number | null
    slug: string | null
  }
  isShowcaseCountersLoading: boolean
}

export const useShowcases = defineStore('showcases', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  const { getHumanizedDate } = useDate()

  const state = reactive<ShowcasesState>({
    showcase: null,
    showcaseProps: { eventId: null, slug: null },
    availableShowcases: null,
    showcaseCounters: null,
    showcaseCountersProps: {
      eventId: null,
      slug: null,
    },
    isShowcaseCountersLoading: false,
  })

  const mainColor = computed(() => {
    return state.showcase?.form_main_color || '#119DF4'
  })

  async function fetchShowcase(eventId: number, slug: string): Promise<void> {
    if (
      state.showcaseProps.eventId === eventId &&
      state.showcaseProps.slug === slug
    ) {
      return
    }

    state.showcaseProps = { eventId, slug }

    try {
      const { data } = await useAuthFetch<{ data: Showcase }>(
        `${eventId}${API_ENDPOINT}/${slug}`,
        {
          baseURL,
        }
      )

      if (
        state.showcaseProps.eventId === data.event_id &&
        state.showcaseProps.slug === data.slug
      ) {
        state.showcase = data
      }
    } catch (error: any) {
      if ((error as FetchError).status === 404) {
        throw useCreateScreenError({ message: 'showcase not found' })
      } else {
        throw error
      }
    }
  }

  function resetShowcase(): void {
    state.showcaseProps = { eventId: null, slug: null }
    state.showcase = null
  }

  async function fetchAvailableShowcases(): Promise<void> {
    if (state.availableShowcases) {
      return
    }

    const { data } = await useAuthFetch<{ data: ShowcaseLite[] }>(
      `${API_ENDPOINT}/available`,
      {
        baseURL,
      }
    )

    state.availableShowcases = data
  }

  function resetAvailableShowcases(): void {
    state.availableShowcases = null
  }

  function getUntilDate(showcase: ShowcaseLite): string {
    return (
      showcase.form_until_date ||
      getHumanizedDate(
        showcase.requests_end_date || '',
        showcase.event?.timezone,
        'en'
      ) ||
      ''
    )
  }

  function getShowcasesFormLink(
    eventSlug: string,
    showcaseSlug: string
  ): string {
    return `/event-info/${eventSlug}/showcase/${showcaseSlug}`
  }

  async function fetchShowcaseCounters(
    eventId: number,
    slug: string
  ): Promise<void> {
    if (
      state.showcaseCountersProps.eventId === eventId &&
      state.showcaseCountersProps.slug === slug
    ) {
      return
    }

    state.showcaseCountersProps = { eventId, slug }
    state.isShowcaseCountersLoading = true

    const { data } = await useAuthFetch<{ data: ShowcaseCounters }>(
      `${eventId}${API_ENDPOINT}/${slug}/counters`,
      {
        baseURL,
      }
    )

    state.isShowcaseCountersLoading = false
    state.showcaseCounters = data
  }

  function resetShowcaseCounters(): void {
    state.showcaseCountersProps = { eventId: null, slug: null }
    state.isShowcaseCountersLoading = false
    state.showcaseCounters = null
  }

  return {
    showcase: toRef(state, 'showcase'),
    showcaseProps: toRef(state, 'showcaseProps'), // not used externally, but return to save state server-client
    mainColor,
    fetchShowcase,
    resetShowcase,
    availableShowcases: toRef(state, 'availableShowcases'),
    fetchAvailableShowcases,
    resetAvailableShowcases,
    getUntilDate,
    getShowcasesFormLink,
    showcaseCounters: toRef(state, 'showcaseCounters'),
    showcaseCountersProps: toRef(state, 'showcaseCountersProps'),
    isShowcaseCountersLoading: toRef(state, 'isShowcaseCountersLoading'),
    fetchShowcaseCounters,
    resetShowcaseCounters,
  }
})
