export default {
  image: {
    'ui:widget': 'EditorUploadWidget',
    'ui:options': {
      maxSize: 3,
      acceptTypes: ['image/svg+xml'],
      cropperRatio: 1,
    },
  },
}
