import { markRaw } from 'vue'
import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_COLOR_GRAY_LIGHT,
  DEFAULT_IMAGE_SQUARE,
  DEFAULT_JOB,
  DEFAULT_NAME,
} from '../constants'
import SpeakersView from './SpeakersView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(SpeakersView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    colsNumberOnDesktop: 4,
    colsNumberOnMobile: 2,
    visibleNumber: 8,
    isUseSliderOnMobile: true,
    titleColor: DEFAULT_COLOR_GRAY,
    subtitleColor: DEFAULT_COLOR_GRAY_LIGHT,
    modalTitleColor: DEFAULT_COLOR_GRAY,
    modalSubtitleColor: DEFAULT_COLOR_GRAY_LIGHT,
    speakers: [
      {
        title: DEFAULT_NAME,
        subtitle: DEFAULT_JOB,
        image: DEFAULT_IMAGE_SQUARE,
      },
      {
        title: DEFAULT_NAME,
        subtitle: DEFAULT_JOB,
        image: DEFAULT_IMAGE_SQUARE,
      },
      {
        title: DEFAULT_NAME,
        subtitle: DEFAULT_JOB,
        image: DEFAULT_IMAGE_SQUARE,
      },
      {
        title: DEFAULT_NAME,
        subtitle: DEFAULT_JOB,
        image: DEFAULT_IMAGE_SQUARE,
      },
    ],
  },
}
