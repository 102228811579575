// https://yandex.ru/support/metrica/ecommerce/data.html

export enum YandexECommerceEventType {
  Impressions = 'impressions',
  Click = 'click',
  Detail = 'detail',
  Add = 'add',
  Remove = 'remove',
  Purchase = 'purchase',
  PromoView = 'promoView',
  PromoClick = 'promoClick',
}

export enum YandexECommerceOrderType {
  GeneralOrder = 'general_order',
}

export enum YandexECommerceEventBrands {
  WNConfTicket = 'wnconf_ticket',
  HubTicket = 'hub_ticket',
}

export const YandexECommerceOrderIdPrefix = {
  [YandexECommerceOrderType.GeneralOrder]: 'general-order-',
}

export const YandexECommerceEventIdPrefix = {
  [YandexECommerceEventBrands.WNConfTicket]: 'wnconf-ticket-',
  [YandexECommerceEventBrands.HubTicket]: 'hub-ticket-',
}

export interface YandexECommerceBaseEvent {
  currencyCode: string
}

export interface YandexECommerceProduct {
  id: string
  name: string
  brand?: string
  category?: string
  coupon?: string
  discount?: number
  list?: string
  position?: number
  price?: number
  quantity?: number
  variant?: string
}

export interface YandexECommercePromo {
  id: string
  name?: string
  creative?: string
  creative_slot?: string
  position?: string
}

export interface YandexEcommerceImpressionsEvent
  extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.Impressions]: YandexECommerceProduct[]
}

export interface YandexEcommerceClickEvent extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.Click]: { products: YandexECommerceProduct[] }
}

export interface YandexEcommerceDetailEvent extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.Detail]: { products: YandexECommerceProduct[] }
}

export interface YandexEcommerceAddEvent extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.Add]: { products: YandexECommerceProduct[] }
}

export interface YandexEcommerceRemoveEvent extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.Remove]: { products: YandexECommerceProduct[] }
}

export interface YandexEcommercePurchaseEvent extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.Purchase]: {
    actionField: {
      id: string
      coupon?: string
      goal_id?: number
      revenue?: number
    }
    products: YandexECommerceProduct[]
  }
}

export interface YandexEcommercePromoViewEvent
  extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.PromoView]: { promotions: YandexECommercePromo[] }
}

export interface YandexEcommercePromoClickEvent
  extends YandexECommerceBaseEvent {
  [YandexECommerceEventType.PromoClick]: { promotions: YandexECommercePromo[] }
}

export type YandexEcommerceEvent =
  | YandexEcommerceImpressionsEvent
  | YandexEcommerceClickEvent
  | YandexEcommerceDetailEvent
  | YandexEcommerceAddEvent
  | YandexEcommerceRemoveEvent
  | YandexEcommercePurchaseEvent
  | YandexEcommercePromoViewEvent
  | YandexEcommercePromoClickEvent

export interface YandexECommerceEventWrapper {
  ecommerce: YandexEcommerceEvent
}
