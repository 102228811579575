export interface NewsTag {
  id: number
  name: string
  order: number
}

export interface NewsListItem {
  id: number
  name: string
  preview: string | null
  preview_img: string
  categories: number[]
  tags: NewsTag[]
  publish_date: string
  premium: boolean
}

export interface NewsItem extends NewsListItem {
  detail: string
  premium_detail: string
  source: string | null
  creator: string | null
  language: string
  domain: string | null
  guid: string
  feed: number | null
  active: boolean
}

export const NewsEndpointMap = {
  Base: '/news',
  Auth: '/news/me',
} as const

export type NewsEndpoint =
  (typeof NewsEndpointMap)[keyof typeof NewsEndpointMap]
