<template>
  <a
    :href="link"
    target="_blank"
    class="hover:opacity-80"
    :style="{ borderColor: borderColor }"
  >
    <div class="flex gap-3">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3333 19.3333H13.3333V21.9999V28.6666H14.6666V24.6666H17.3333C18.7999 24.6666 19.9999 23.4666 19.9999 21.9999C19.9999 20.5333 18.7999 19.3333 17.3333 19.3333ZM17.3333 23.3333H14.6666V20.6667H17.3333C18.1333 20.6667 18.6666 21.2 18.6666 22C18.6666 22.8 18.1333 23.3333 17.3333 23.3333Z"
          fill="currentColor"
        />
        <path
          d="M25.3333 19.3333H21.3333V28.6666H25.3333C26.7999 28.6666 27.9999 27.4666 27.9999 25.9999V21.9999C27.9999 20.5333 26.7999 19.3333 25.3333 19.3333ZM26.6666 26C26.6666 26.8 26.1333 27.3333 25.3333 27.3333H22.6666V20.6667H25.3333C26.1333 20.6667 26.6666 21.2 26.6666 22V26Z"
          fill="currentColor"
        />
        <path
          d="M36 15.3333H25.3333V11.3333L17.3333 2H2.66667C1.19997 2 0 3.19997 0 4.66667V35.3333C0 36.8 1.19997 38 2.66667 38H22.6667C24.1333 38 25.3333 36.8 25.3333 35.3333V32.6667H36C38.2667 32.6667 40 30.9334 40 28.6667V19.3333C40 17.0666 38.2667 15.3333 36 15.3333ZM17.3333 3.99997L23.6 11.3333H18.6667C18 11.3333 17.3333 10.6667 17.3333 10V3.99997ZM24 35.3333C24 35.9999 23.4667 36.6666 22.6667 36.6666H2.66667C2.00003 36.6666 1.33333 36.1333 1.33333 35.3333V4.66667C1.33333 4.00004 1.86667 3.33333 2.66667 3.33333H16V10C16 11.4667 17.2 12.6667 18.6667 12.6667H24V15.3333H13.3333C11.0666 15.3333 9.33333 17.0666 9.33333 19.3333V28.6667C9.33333 30.9334 11.0666 32.6667 13.3333 32.6667H24V35.3333ZM38.6667 28.6667C38.6667 30.1333 37.4666 31.3333 36 31.3333H13.3333C11.8666 31.3333 10.6667 30.1333 10.6667 28.6667V19.3333C10.6667 17.8666 11.8666 16.6666 13.3333 16.6666H36C37.4666 16.6666 38.6667 17.8666 38.6667 19.3333V28.6667Z"
          fill="currentColor"
        />
        <path
          d="M29.3333 28.6667H30.6666V24.6666H34.6666V23.3333H30.6666V20.6667H35.9999V19.3333H29.3333V28.6667Z"
          fill="currentColor"
        />
      </svg>
      <div class="text-left">
        <div>Download program*</div>
        <div class="text-sm opacity-80 -mt-0.5">*may be updated</div>
      </div>
    </div>
  </a>
</template>

<script setup lang="ts">
import type { Program } from '~/models/program'

interface PropsInterface {
  program: Program
  borderColor?: string
  lang?: string
}

const props = withDefaults(defineProps<PropsInterface>(), {
  borderColor: undefined,
  lang: 'en',
})

const printWidth = ref(0)
const printHeight = ref(0)

const link = computed(
  () =>
    `https://panel-api.wnhub.io/pdf/program?id=${props.program.id}&lang=${props.lang}&dpi=150&page-height=${printHeight.value}&page-width=${printWidth.value}&margin-top=0&margin-right=0&margin-bottom=0&margin-left=0`
)

onMounted(() => {
  // размеры констант указаны в пикселях
  // генерировалась PDF, устанавливался масштаб 100%, и измерялись размеры в пикселях на экране
  // Ratio служит для перевода пикселей в единицы, которые понимает генератор PDF
  const PRINT_RATIO = 3.78
  const PRINT_COLUMN_WIDTH = 567
  const PRINT_HEADER_HEIGHT = 144
  // const PRINT_FOOTER_HEIGHT = 110
  const PRINT_SECTION_HEIGHT_MIN = 47
  const PRINT_SECTION_HEIGHT_MAX = 110
  const PRINT_SECTION_HEIGHT_AVERAGE =
    (PRINT_SECTION_HEIGHT_MAX + PRINT_SECTION_HEIGHT_MIN) / 2

  let printColumns = 0
  let maxPrintSections = 0

  props.program.days.forEach((day) => {
    day.streams.forEach((stream) => {
      printColumns++
      maxPrintSections = Math.max(maxPrintSections, stream.sections.length)
    })
  })

  const printWidthPx = printColumns * PRINT_COLUMN_WIDTH
  printWidth.value = printWidthPx / PRINT_RATIO

  let printHeightPx =
    PRINT_HEADER_HEIGHT + maxPrintSections * PRINT_SECTION_HEIGHT_AVERAGE
  printHeightPx += PRINT_SECTION_HEIGHT_MIN // добавляем дополнительный отступ снизу, чтобы точно всё поместилось
  printHeight.value = Math.ceil(printHeightPx / PRINT_RATIO)
})
</script>
