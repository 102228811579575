export const useEditorLinks = () => {
  const route = useRoute()
  const host = useHost()
  const { pushNotification } = useNotifications()
  const { isApp } = useDetect()
  const { wnhubPrefix, wnhubEventPrefix } = useRuntimeConfig().public

  const getBlockLink = (hash: string) => {
    return `${host}${route.path}#${hash}`
  }

  function scrollToHash(hash: string) {
    const element = document.querySelector(`#${hash}`)
    if (!element) {
      pushNotification({
        title: 'No block with such hash on the page',
        theme: 'destructive',
      })
      return
    }
    element.scrollIntoView({ behavior: 'smooth' })
  }

  function getLinkTarget(initialLink = '', isBlank = false) {
    const link = initialLink?.trim()

    if (isApp) {
      return link.startsWith(wnhubEventPrefix) ||
        link.startsWith(wnhubPrefix) ||
        link.startsWith('/')
        ? '_self'
        : '_blank'
    } else {
      return isBlank || useDetect().isInIframe ? '_blank' : '_self'
    }
  }

  function getLinkUrl(initialLink?: string) {
    const link = initialLink?.trim()

    if (!isApp || !link) {
      return link
    }

    if (link.startsWith(wnhubEventPrefix)) {
      return `/eventhub/index.html?redirect=${link.split(wnhubPrefix)[1]}`
    }

    if (link.startsWith(wnhubPrefix)) {
      return `${link.split(wnhubPrefix)[1]}`
    }

    return link
  }

  function isRelativeLink(initialLink?: string) {
    const link = initialLink?.trim()

    return link ? link.startsWith('/') : false
  }

  function isWnhubLink(initialLink?: string) {
    const link = initialLink?.trim()

    return link
      ? link.startsWith(wnhubEventPrefix) || link.startsWith(wnhubPrefix)
      : false
  }

  return {
    getBlockLink,
    scrollToHash,
    getLinkTarget,
    getLinkUrl,
    isRelativeLink,
    isWnhubLink,
  }
}
