<template>
  <NuxtLink v-slot="slotProps" :to="routeTo" v-bind="$attrs">
    <slot v-bind="slotProps" />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
const localeRoute = useLocaleRoute()

const props = defineProps({
  to: {
    type: [Object, String] as PropType<RouteLocationRaw>,
    required: true,
  },
  ignoreCurrentLocale: {
    type: Boolean,
    default: false,
  },
})

const routeTo = computed(() => {
  if (typeof props.to === 'string' && props.ignoreCurrentLocale) {
    return props.to
  } else {
    return localeRoute(props.to)
  }
})
</script>
