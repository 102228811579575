import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { FetchError } from 'ofetch'
import { FilterParams, isSortTheSame, setSort } from './params-helper'
import { NewsEndpointMap } from '~/models/news'
import type { NewsItem, NewsListItem, NewsEndpoint } from '~/models/news'
import { NavigationSortType, OTHER_CATEGORY_ID } from '~/models/navigation'

interface NewsState {
  newsItem: NewsItem | null
  newsItemProps: {
    id: number | null
    endpoint: string | null
    locale: string | null
  }
  trends: {
    news: NewsListItem | null
    props: {
      locale: string | null
    }
  }
  newsListProps: {
    locale: string | null
  }
}

export const useNews = defineStore('news', () => {
  const i18n = useI18n()
  const { $dayjs } = useNuxtApp()
  const host = useHost()

  // TODO: https://app.asana.com/0/1202924432057564/1203829013202629
  const baseURL = useRuntimeConfig().public.gatewayApi

  const state = reactive<NewsState>({
    newsItem: null,
    newsItemProps: {
      id: null,
      endpoint: null,
      locale: null,
    },
    trends: {
      news: null,
      props: {
        locale: null,
      },
    },
    newsListProps: {
      locale: null,
    },
  })

  const initNewsList = () => {
    const list = useInfinityList<NewsListItem>(
      NewsEndpointMap.Base,
      baseURL,
      (params) => {
        params[FilterParams.Language] = i18n.locale.value
      },
      (newsList) => {
        newsList.forEach((item) => {
          if (!item.categories.length) {
            item.categories.push(OTHER_CATEGORY_ID)
          }
        })

        return newsList
      }
    )

    return list
  }

  const newsList = initNewsList()

  function clearNewsDetail(detail: string | null): string {
    return (detail || '').replace(
      /(<iframe.*?youtu\.?be.*?<\/iframe>)/g,
      '<div class="aspect-w-16 aspect-h-9">$1</div>'
    )
  }

  function formatDateFrom(dateStr: string) {
    const date = $dayjs.utc(dateStr).tz()

    return date.fromNow()
  }

  function formatDate(dateStr: string) {
    const date = $dayjs.utc(dateStr).tz()

    if (date.isToday()) {
      return date.format('HH:mm')
    }

    return date.format('DD.MM.YYYY')
  }

  function getTextPreview(item: NewsListItem) {
    return (
      item.preview
        ?.replace(/(<([^>]+)>)/gi, '')
        .replace(/\n\n/gi, '\n')
        .trim() || ''
    )
  }

  async function fetchNewsItem(id: number, endpoint: NewsEndpoint) {
    id = Number(id)

    if (
      state.newsItemProps.id === id &&
      state.newsItemProps.endpoint === endpoint &&
      state.newsItemProps.locale === i18n.locale.value
    ) {
      return
    }

    if (isNaN(id)) {
      throw useCreateScreenError({ message: 'news item id should be a number' })
    }

    state.newsItemProps = {
      id,
      endpoint,
      locale: i18n.locale.value,
    }

    state.newsItem = null

    try {
      const { data } = await useAuthFetch<{ data: NewsItem }>(
        `${endpoint}/${id}`,
        {
          baseURL,
        }
      )

      if (!data.categories.length) {
        data.categories.push(OTHER_CATEGORY_ID)
      }

      state.newsItem = data
    } catch (error: any) {
      if ((error as FetchError).status === 404) {
        throw useCreateScreenError({ message: 'news item not found' })
      } else {
        throw error
      }
    }
  }

  async function getNewsItem(
    id: number,
    endpoint: NewsEndpoint
  ): Promise<NewsItem> {
    const { data } = await useAuthFetch<{ data: NewsItem }>(
      `${endpoint}/${id}`,
      {
        baseURL,
      }
    )

    if (!data.categories.length) {
      data.categories.push(OTHER_CATEGORY_ID)
    }

    return data
  }

  // TODO: replace with a request for trends when backend is ready
  async function fetchTrends() {
    if (state.trends.props.locale === i18n.locale.value) {
      return
    }

    state.trends.props = { locale: i18n.locale.value }

    const { data } = await useKrakenFetch<{ data: NewsListItem[] }>(`/news`, {
      baseURL,
      params: {
        [FilterParams.Language]: i18n.locale.value,
        [FilterParams.Tranding]: 1,
        per_page: 1,
      },
    })
    state.trends.news = data[0]
    if (!state.trends.news?.categories.length) {
      state.trends.news?.categories.push(OTHER_CATEGORY_ID)
    }
  }

  function getNewsPageLink(newsId: number, categorySlug: string) {
    return `${host}/news/${categorySlug}/item-${newsId}`
  }

  function resetNewsListWithSorting(
    endpoint: NewsEndpoint,
    sort: NavigationSortType,
    categoryId?: number
  ) {
    const isEndpointTheSame = endpoint === newsList.state.endpoint

    if (categoryId === OTHER_CATEGORY_ID) {
      categoryId = undefined
    }

    const isCategoryTheSame =
      categoryId === newsList.state.params[FilterParams.Category]

    if (
      isEndpointTheSame &&
      isSortTheSame(newsList.state.params, sort) &&
      isCategoryTheSame &&
      state.newsListProps.locale === i18n.locale.value
    ) {
      return
    }

    newsList.state.endpoint = endpoint
    state.newsListProps.locale = i18n.locale.value

    setSort(newsList.state.params, sort)

    if (categoryId) {
      newsList.state.params[FilterParams.Category] = categoryId
    } else {
      delete newsList.state.params[FilterParams.Category]
    }

    newsList.reset()
  }

  async function reload() {
    newsList.reset()
    await newsList.getPage(1)
  }

  const getShortName = (name: string) => {
    const words = name.split(' ')
    return words.length > 6 ? words.slice(0, 5).join(' ') + '...' : name
  }

  return {
    newsItem: toRef(state, 'newsItem'),
    newsItemProps: toRef(state, 'newsItemProps'), // not used externally, but return to save state server-client
    trends: toRef(state, 'trends'),
    list: newsList.state,
    newsListProps: toRef(state, 'newsListProps'), // not used externally, but return to save state server-client
    clearNewsDetail,
    reload,
    getTextPreview,
    formatDate,
    formatDateFrom,
    fetchNewsItem,
    getNewsItem,
    allRecordsLoaded: newsList.allRecordsLoaded,
    fetch: newsList.fetch,
    getNews: newsList.getPage,
    getPage: newsList.getPage,
    nextPage: newsList.nextPage,
    resetNewsListWithSorting,
    fetchTrends,
    getNewsPageLink,
    getShortName,
  }
})
