import { markRaw } from 'vue'
import ExpoView from './ExpoView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(ExpoView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    blockHeight: 500,
    image: '',
  },
}
