import { markRaw } from 'vue'
import View from './EventsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    viewAllText: 'View all',
    viewAllLink: '/event-info',
    title: 'Featured events',
    titleSize: 'xl2',
    events: [
      {
        eventId: 34,
      },
    ],
  },
}
