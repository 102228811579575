export default {
  items: {
    items: {
      blockHash: {
        'ui:widget': 'EditorBlockListWidget',
      },
    },
  },
  backgroundColor: {
    'ui:widget': 'BaseColorWidget',
  },
  buttons: {
    items: {
      blockHash: {
        'ui:widget': 'EditorBlockListWidget',
      },
    },
  },
}
