export default {
  textColor: {
    'ui:widget': 'BaseColorWidget',
  },
  activeTabButtonColor: {
    'ui:widget': 'BaseColorWidget',
  },
  activeTabTextColor: {
    'ui:widget': 'BaseColorWidget',
  },
  tabs: {
    items: {
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
      image: {
        'ui:widget': 'EditorUploadWidget',
      },
    },
  },
}
