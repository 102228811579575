<template>
  <div>
    <template
      v-if="
        section.type === ProgramSectionTypes.HappyHour ||
        section.type === ProgramSectionTypes.CoffeeBreak ||
        section.description
      "
    >
      <div class="w-8 flex justify-center items-start">
        <template v-if="section.type === ProgramSectionTypes.HappyHour">
          <div>
            <BaseIcon name="program_happy_hour_thin" size="lg" />
          </div>
        </template>
        <template v-else-if="section.type === ProgramSectionTypes.CoffeeBreak">
          <div>
            <BaseIcon name="program_coffee_break_thin" size="lg" />
          </div>
        </template>
        <template v-else-if="section.description">
          <button class="hover:opacity-70" @click="emit('toggle-description')">
            <BaseIcon
              name="outline_chevron_down"
              class="transform transition duration-100 ease-in-out"
              :class="isDescriptionOpen && 'rotate-180'"
              size="lg"
            />
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ProgramSectionTypes } from '~/models/program'
import type { ProgramSection } from '~/models/program'

interface PropsInterface {
  section: ProgramSection
  isDescriptionOpen: boolean
}

withDefaults(defineProps<PropsInterface>(), {
  isDescriptionOpen: false,
})

const emit = defineEmits(['toggle-description'])
</script>
