import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import type { Program, ProgramSectionExtended } from '~/models/program'
import { ProgramSectionTypes } from '~/models/program'

interface ProgramState {
  list: Program[]
  programMap: Record<number, { program: Program | null; isLoaded: boolean }>
}

export const useProgram = defineStore('program', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi
  const { t } = useI18n()

  const state = reactive<ProgramState>({
    list: [],
    programMap: {},
  })

  async function fetchProgramList(eventId: number) {
    if (state.list.length) {
      return
    }

    const { data } = await useAuthFetch<{ data: Program[] }>(
      `events/${eventId}/programs`,
      {
        baseURL,
      }
    )

    state.list = data
  }

  async function fetchProgram(eventId: number, programId: number) {
    if (state.programMap[programId]?.program) {
      return
    }

    state.programMap[programId] = {
      program: null,
      isLoaded: false,
    }

    const { data } = await useAuthFetch<{ data: Program }>(
      `events/${eventId}/programs/${programId}`,
      {
        baseURL,
      }
    )

    state.programMap[programId].program = data
    state.programMap[programId].isLoaded = true
  }

  function resetProgramList() {
    state.list = []
  }

  function resetProgram(programId: number) {
    delete state.programMap[programId]
  }

  async function getSection(sectionId: number) {
    const { data } = await useAuthFetch<{ data: ProgramSectionExtended }>(
      `programs/sections/${sectionId}`,
      {
        baseURL,
      }
    )

    return data
  }

  const ProgramSectionTypesLabelsMap = computed(() => {
    return Object.keys(ProgramSectionTypes)
      .filter((key) => !isNaN(Number(key)))
      .reduce((acc, item) => {
        const sectionKey = Number(item)
        acc[sectionKey] = t(
          `program.sections.${ProgramSectionTypes[sectionKey]}`
        )
        return acc
      }, {} as Record<number, string>)
  })

  return {
    programMap: toRef(state, 'programMap'),
    programList: toRef(state, 'list'),
    fetchProgram,
    resetProgram,
    fetchProgramList,
    resetProgramList,
    ProgramSectionTypesLabelsMap,
    getSection,
  }
})
