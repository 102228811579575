import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import type { ComputedRef } from 'vue'
import { NavigationSortType, OTHER_CATEGORY_ID } from '~/models/navigation'
import type { Category, NavigationSortItem } from '~/models/navigation'
import { sidebarBottomListMaxLength } from '~/models/menu'
import type { MenuItem } from '~/models/menu'
import type { FilterItem } from '~/models/filter'

interface NavigationState {
  categoriesList: Category[]
  isCategoriesLoading: boolean
}

export const useNavigation = defineStore('navigation', () => {
  const i18n = useI18n()
  const { t } = useI18n()

  const baseURL = useRuntimeConfig().public.gatewayApi

  const sortsList: ComputedRef<NavigationSortItem[]> = computed(() => [
    {
      local: t('common.premium'),
      slug: NavigationSortType.Premium,
      icon: 'outline_lightning_bolt',
      subpage: 'premium',
    },
    {
      local: t('common.popular'),
      slug: NavigationSortType.Popular,
      icon: 'outline_fire',
      subpage: 'popular',
    },
    {
      local: t('common.new'),
      slug: NavigationSortType.New,
      icon: 'outline_newspaper',
    },
    {
      local: t('common.all'),
      slug: NavigationSortType.All,
      icon: 'outline_newspaper',
    },
  ])

  const state = reactive<NavigationState>({
    categoriesList: [],
    isCategoriesLoading: false,
  })

  const categoriesIdMap: ComputedRef<Record<number, Category>> = computed(() =>
    state.categoriesList.reduce((acc, category) => {
      acc[category.id] = category
      return acc
    }, {} as Record<number, Category>)
  )

  const categoriesSlugMap: ComputedRef<Record<string, Category>> = computed(
    () => {
      const result = state.categoriesList.reduce((acc, category) => {
        acc[category.slug.toString()] = category
        return acc
      }, {} as Record<string, Category>)

      return result
    }
  )

  const getSortItem = (page: string, sort: NavigationSortItem): MenuItem => {
    return {
      ...sort,
      id: sort.slug,
      route: {
        name: sort.subpage ? `${page}-${sort.subpage}` : page,
      },
    }
  }

  const getCategoryItem = (page: string, category: Category): MenuItem => {
    return {
      id: category.id,
      slug: category.slug,
      local: getFieldLocale(category.title, i18n.locale.value),
      route: {
        name: `${page}-category`,
        params: {
          category: category.slug,
        },
      },
      isCategory: true,
    }
  }

  const getCategorySortItem = (
    page: string,
    sort: NavigationSortItem,
    categorySlug: string
  ) => {
    return {
      ...sort,
      id: sort.slug,
      route: {
        name: `${page}-category` + (sort.subpage ? `-${sort.subpage}` : ''),
        params: {
          category: categorySlug,
        },
      },
    }
  }

  const getSortItems = (
    page: string,
    sortTypes: NavigationSortType[] = []
  ): ComputedRef<MenuItem[]> => {
    return computed(() =>
      sortsList.value.reduce((accumulator: MenuItem[], sort) => {
        return sortTypes.includes(sort.slug)
          ? [...accumulator, getSortItem(page, sort)]
          : accumulator
      }, [])
    )
  }

  const getCategorySortItems = (
    page: string,
    categorySlug: string,
    sortTypes: NavigationSortType[] = []
  ): ComputedRef<MenuItem[]> => {
    return computed(() =>
      sortsList.value.reduce((acc: MenuItem[], sort) => {
        return sortTypes.includes(sort.slug)
          ? [...acc, getCategorySortItem(page, sort, categorySlug)]
          : acc
      }, [])
    )
  }

  const getCategoryItems = (page: string): ComputedRef<MenuItem[]> => {
    return computed(() =>
      state.categoriesList.map((category) => {
        return getCategoryItem(page, category)
      })
    )
  }

  const getCategoryFilterItem = (
    page: string,
    category: FilterItem
  ): MenuItem => {
    return {
      id: category.id,
      slug: category.slug,
      local: getFieldLocale(category.name, i18n.locale.value),
      route: {
        name: `${page}-category`,
        params: {
          category: category.slug,
        },
      },
      isCategory: true,
    }
  }

  // avoid request duplication from many components
  let fetchCategoriesPromise: null | Promise<undefined> = null
  // eslint-disable-next-line require-await
  async function fetchCategories() {
    if (state.categoriesList.length) {
      return
    }

    if (fetchCategoriesPromise) {
      return fetchCategoriesPromise
    }

    fetchCategoriesPromise = new Promise((resolve, reject) => {
      ;(async () => {
        try {
          const { data } = await useKrakenFetch<{ data: Category[] }>(
            `/categories`,
            {
              baseURL,
            }
          )

          state.categoriesList = data.map((category) => {
            category.title = parseFieldLocales(category.title as string) || ''
            category.description =
              parseFieldLocales(category.description as string) || ''
            return category
          })
          resolve(undefined)
        } catch (error: any) {
          reject(error)
        } finally {
          fetchCategoriesPromise = null
        }
      })()
    })

    return fetchCategoriesPromise
  }

  const isCategoryHidden = (slug?: string) => {
    return computed(() => {
      if (!slug) {
        return false
      }

      return !state.categoriesList
        .slice(0, sidebarBottomListMaxLength)
        .map((item) => item.slug)
        .includes(slug)
    })
  }

  const getCategoryItemsByIds = (ids: number[], page: string) => {
    const list = ids.reduce((acc, id) => {
      const category = categoriesIdMap.value[id]
      if (category) {
        acc.push(getCategoryItem(page, category))
      }
      return acc
    }, [] as MenuItem[])
    if (!list.length) {
      list.push(getCategoryItem(page, categoriesIdMap.value[OTHER_CATEGORY_ID]))
    }

    return list
  }

  const getPageLinkCategorySlug = (ids: MenuItem[]): string => {
    return ids[0]?.slug || ''
  }

  const getCategoryMeta = (category?: Category | null) => {
    if (!category) {
      return {
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
      }
    }
    return {
      metaTitle: getFieldLocale(
        parseFieldLocales(category.meta_title) || '',
        'en'
      ),
      metaKeywords: getFieldLocale(
        parseFieldLocales(category.meta_keywords) || '',
        'en'
      ),
      metaDescription: getFieldLocale(
        parseFieldLocales(category.meta_description) || '',
        'en'
      ),
    }
  }

  return {
    categoriesList: toRef(state, 'categoriesList'),
    categoriesIdMap,
    categoriesSlugMap,
    sortsList,
    getCategoryMeta,
    fetchCategories,
    getCategoryItem,
    getCategoryFilterItem,
    getCategoryItems,
    getSortItems,
    getCategorySortItems,
    getCategoryItemsByIds,
    getPageLinkCategorySlug,
    isCategoryHidden,
  }
})
