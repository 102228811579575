import { basePropertiesSchema } from '../base-schema-properties'
import { bgColorSchema, getButtonProperties } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Menu Сustom',
  description: 'Customizable menu with internal and external links',
  type: 'object',
  properties: {
    ...basePropertiesSchema,
    menuStyle: {
      title: 'Menu style',
      type: 'string',
      default: 'bg',
      enum: ['separate', 'bg'],
      enumNames: ['Separate buttons', 'All together on the background'],
    },
    bgColor: {
      ...bgColorSchema.bgColor,
      'ui:hidden': '{{ parentFormData.menuStyle === "separate" }}',
    },
    align: {
      title: 'Content align',
      type: 'string',
      enum: [
        'justify-start',
        'justify-center',
        'justify-end',
        'justify-between',
      ],
      enumNames: ['Left', 'Center', 'Right', 'Default'],
      default: 'justify-between',
      'ui:hidden': '{{!!parentFormData.image}}',
    },
    sticky: {
      title: 'Sticky to top',
      type: 'boolean',
      default: true,
    },
    stickyShadow: {
      title: 'Cast shadow when stuck',
      type: 'boolean',
      default: true,
    },
    items: {
      type: 'array',
      title: 'A list of menu items',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        type: 'object',
        properties: getButtonProperties({
          isExternalLink: false,
          color: '#ffffff',
          colorHover: '#E4EFFD', // primary-100 - default for menu
          textColor: '#414141', // gray-900,
        }),
      },
    },
    buttons: {
      type: 'array',
      title: 'A list of buttons',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        type: 'object',
        properties: getButtonProperties(),
      },
    },
  },
}
