export default {
  shadowColor: {
    'ui:widget': 'BaseColorWidget',
  },
  borderColor: {
    'ui:widget': 'BaseColorWidget',
  },
  cards: {
    items: {
      cardType: {
        'ui:widget': 'RadioWidget',
      },
      imageUrl: {
        'ui:widget': 'EditorUploadWidget',
      },
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
      textColor: {
        'ui:widget': 'BaseColorWidget',
      },
      bgColor: {
        'ui:widget': 'BaseColorWidget',
      },
    },
  },
}
