<template>
  <div ref="target" class="flex justify-center">
    <div v-if="!props.empty" class="py-5 dark:text-white">
      <BaseSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  empty?: boolean
}
const target = ref(null)
const targetIsVisible = ref(false)
const props = withDefaults(defineProps<Props>(), {
  empty: false,
})
const emit = defineEmits<{
  (e: 'change', value: boolean): void
}>()

const { stop } = useIntersectionObserver(
  target,
  ([{ isIntersecting }], _observerElement) => {
    targetIsVisible.value = isIntersecting

    emit('change', targetIsVisible.value)
  }
)

onUnmounted(() => {
  stop()
})

// export default defineComponent({
//   name: 'BaseObserve',
//   props: {
//     empty: {
//       type: Boolean,
//       required: false,
//     },
//   },
//   emits: ['change'],
//   // directives: {
//   //   ObserveVisibility,
//   // },
//   // components: {
//   //   BaseSpinner,
//   // },
//   setup() {
//     const target = ref(null)
//     const targetIsVisible = ref(false)

//     const { stop } = useIntersectionObserver(
//       target,
//       ([{ isIntersecting }], observerElement) => {
//         targetIsVisible.value = isIntersecting
//       }
//     )

//     return {
//       target,
//       targetIsVisible,
//     }
//   },
//   methods: {
//     onObserve(isVisible: boolean) {
//       this.$emit('change', isVisible)
//     },
//   },
// })
</script>
