import { markRaw } from 'vue'
import {
  DEFAULT_IMAGE_SQUARE,
  DEFAULT_MARKDOWN_TEXT,
  DEFAULT_LINK,
} from '../constants'
import View from './ContentView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    images: [{ image: DEFAULT_IMAGE_SQUARE, link: DEFAULT_LINK }],
    text: DEFAULT_MARKDOWN_TEXT,
    colsNumberOnDesktop: 1,
    colsNumberOnMobile: 1,
    isUseSliderOnMobile: false,
    isOpenFullscreen: false,
    rowsNumberInSliderOnMobile: 1,
    buttons: [],
  },
}
