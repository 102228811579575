<template>
  <main
    class="h-screen flex flex-col overflow-hidden bg-white justify-center items-center p-3 sm:p-8"
  >
    <ErrorPage :status-code="error?.statusCode" @clear="clearError" />
    <AuthLogin
      :is-open="authStore.isLoginModalOpen"
      @close="authStore.closeLoginModal"
    />
  </main>
</template>

<script setup lang="ts">
import { useAuth } from '~/stores/auth'

const authStore = useAuth()

defineProps({
  error: {
    type: Object,
    required: true,
  },
})
</script>
