import { App } from '@capacitor/app'
import type { URLOpenListenerEvent } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links'
import { Browser } from '@capacitor/browser'

const isLoaded = ref(false)

export default defineNuxtPlugin(async (nuxtApp) => {
  const { isIOS } = useDevice()
  try {
    if (!useDetect().isApp) {
      return
    }

    nuxtApp.hooks.hookOnce('page:finish', () => {
      isLoaded.value = true
    })

    await StatusBar.setStyle({ style: Style.Light })
    useDevice().platform === 'android' &&
      (await StatusBar.setBackgroundColor({ color: '#ffffff' }))

    const router = useRouter()
    App.addListener('backButton', () => {
      if (router) {
        router.go(-1)
      }
    })

    // We support two types of deep links, link could have subdomain or not:
    // Firebase Dynamic Links:
    // https://wnhub.page.link/?apn=com.wnhub.app&isi=1508335891&ibi=media.wn.hub&efr=1&link=https://dev.wnhub.io/chats/
    // https://wnhub.page.link/?apn=com.wnhub.app&isi=1508335891&ibi=media.wn.hub&efr=1&link=https://wnhub.io/events/dev/chats/
    // Native deep links with prefix /app-link?link=
    // https://wnhub.io/app-link?link=https://dev.wnhub.io/chats/
    // https://wnhub.io/app-link?link=https://wnhub.io/events/dev/chats/
    function getPath(url: string) {
      // smoke check
      if (!url.includes('wnhub.io')) {
        return false
      }
      let path = url.split('.io').pop()
      try {
        // check for subdomain
        const parsedUrl = new URL(url.split('link=')[1])
        const hostname = parsedUrl.hostname
        const subdomain =
          hostname.split('.').length === 3 ? hostname.split('.')[0] : ''

        if (subdomain) {
          path = `/events/${subdomain}` + url.split('.io').pop()
        }
      } catch (e: any) {
        console.log('app: failed to parse url', url)
      }

      return path
    }

    async function onAppUrlOpen(event: URLOpenListenerEvent) {
      const deepLinkPrefix = 'app-link'
      try {
        await Browser.close()
      } catch (error: any) {
        console.log('Error on browser close on appUrlOpen', error)
      }
      let path = getPath(event.url)
      console.log('app: on appUrlOpen', path, event.url)

      if (path && path.includes(deepLinkPrefix)) {
        path = path.replace('/' + deepLinkPrefix, '')
        console.log('app: remove deep link prefix', path)
      }

      if (path && path.startsWith('/events/')) {
        window.open(`/eventhub/index.html?redirect=${path}`, '_self')
        return
      }

      if (path) {
        if (isLoaded.value) {
          await navigateTo(path)
        } else {
          // for some reason on some devices onNuxtReady doesn't work, we also getting two page:finish events
          // TODO: research this issue in details
          nuxtApp.hooks.hookOnce('page:finish', async () => {
            await navigateTo(path)
          })
        }
      }
    }

    App.addListener('appUrlOpen', onAppUrlOpen)

    function listenToDeepLinkOpen() {
      FirebaseDynamicLinks.addListener('deepLinkOpen', async (data) => {
        // on IOS we will get double callbacks: native and firebase
        if (!isIOS) {
          console.log('on deepLinkOpen', JSON.stringify(data))
          await onAppUrlOpen(data)
        }
      })
    }

    listenToDeepLinkOpen()

    usePushService().addListeners()
    await usePushService().registerNotifications()
  } catch (e: any) {
    useLogError(e)
  }
})
