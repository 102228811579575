<template>
  <BaseSelectAsync
    v-model="value"
    label="Event"
    :fetch="eventsStore.getEventsAutocomplete"
    :initial="initialEvent"
    name="event"
    class="w-full"
  />
</template>

<script setup lang="ts">
import { useEvents } from '~/stores/events'
import type { OptionInterface } from '~/components/base/controls/use-simple-input'

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const eventsStore = useEvents()
const initialEvent = ref<undefined | OptionInterface>(undefined)

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})

onMounted(async () => {
  if (props.modelValue) {
    const event = await eventsStore.getEvent(props.modelValue)
    initialEvent.value = {
      value: event.id,
      label: `${event.name} (${event.id})`,
    }
  }
})
</script>
