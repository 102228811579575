import type { NitroFetchOptions } from 'nitropack'
import type { FetchError } from 'ofetch'
import { useAuth } from '~/stores/auth'
import { useTokenRefresh } from '~/stores/token-refresh'

export const useAuthFetch = async <T>(
  request: string,
  opts?: NitroFetchOptions<any> | undefined
) => {
  const { getRefs } = useTokenRefresh()
  const { isTokenRefreshing, resolveRefreshPromise, rejectRefreshPromise } =
    getRefs()

  const authStore = useAuth()
  const makeRequest = () => {
    return useKrakenFetch<T>(request, {
      ...(opts || {}),
      headers: {
        ...opts?.headers,
        ...(authStore.auth?.access_token && {
          Authorization: `Bearer ${authStore.auth?.access_token}`,
        }),
      },
    })
  }

  try {
    return await makeRequest()
  } catch (error: any) {
    const fetchError = error as FetchError

    if (fetchError.status === 401) {
      console.log('auth: 401 error on auth request', fetchError)
      if (isTokenRefreshing.value && 'then' in isTokenRefreshing.value) {
        await isTokenRefreshing.value
      } else {
        isTokenRefreshing.value = new Promise((resolve, reject) => {
          resolveRefreshPromise.value = resolve
          rejectRefreshPromise.value = reject
        })
        isTokenRefreshing.value.catch(() => undefined) // supress excess promise exception

        try {
          console.log('auth: refresh token')
          await authStore.refreshToken()
          resolveRefreshPromise.value(false)
        } catch (error: any) {
          rejectRefreshPromise.value(error)

          authStore.logout()
          throw error
        } finally {
          isTokenRefreshing.value = null
        }
      }
      return makeRequest()
    } else {
      throw error
    }
  }
}
