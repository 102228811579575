import { markRaw } from 'vue'
import CoverView from './CoverView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(CoverView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    text: '# Getting acquainted \n\n The client briefs us about the company, the project, and the team, describes the tasks faced and the ideal candidate.',
    textColor: '#ffffff',
    textAlign: 'center',
    paddingSize: 'md',
    isBgImage: true,
    bgColor: '#000000',
    bgImage: 'https://cdn.wnhub.io/39253/65dde526e1a6c4.93735968.jpg',
    buttons: [],
    maxWidth: 576, // max-w-xl
  },
}
