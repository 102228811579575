<template>
  <div
    class="rounded-xl overflow-hidden relative flex flex-col shadow-md min-w-[260px] bg-white"
  >
    <div
      class="absolute top-0 left-0 w-full h-full border opacity-20 rounded-xl pointer-events-none"
      :style="{ borderColor: category.color }"
    />
    <div
      :style="{ backgroundColor: category.color }"
      class="text-base px-6 py-2 text-white flex justify-between items-center gap-2 min-h-[3.5rem]"
    >
      <div class="font-bold">
        {{ category.title.toUpperCase() }}
      </div>
      <BaseButton
        v-if="category.additionalInfo"
        look="none"
        class="!p-0"
        @click="isAboutModalOpen = true"
      >
        <BaseIcon name="outline_question_mark_circle" class="opacity-50" />
      </BaseButton>
    </div>
    <div class="px-6 py-4 flex flex-col gap-4 text-sm h-full">
      <div class="flex flex-col gap-4">
        <div
          v-if="category.text"
          class="marked"
          v-html="getMarkedTextWithBlankLinks(category.text)"
        />
        <template v-if="category.tickets?.length > 1 && selectedTicketId">
          <BaseRadioGroup
            v-model="selectedTicketId"
            :options="category.tickets"
          />
        </template>
      </div>
      <div
        v-if="category.tickets?.length"
        class="flex flex-col gap-4 flex-1 justify-end pt-4"
      >
        <div class="flex flex-col min-h-[4.5rem] items-center">
          <div class="flex text-2xl font-bold space-x-1">
            <template
              v-if="
                (rawPrice && price) || !selectedTicketConfig?.freeTicketText
              "
            >
              <div>{{ price }}</div>
              <div v-if="futurePrice" class="text-gray-200 line-through">
                {{ futurePrice }}
              </div>
            </template>
            <template v-else>
              {{ selectedTicketConfig?.freeTicketText }}
            </template>
          </div>
          <div
            v-if="category.isShowVat"
            class="text-2xl font-bold text-gray-200 text-center italic"
          >
            {{ $t('tickets.vat') }}
          </div>
          <BaseButton
            v-if="category.additionalInfo"
            look="link"
            class="!font-normal"
            size="sm"
            @click="isAboutModalOpen = true"
          >
            {{ $t('tickets.about_ticket').toLowerCase() }}
          </BaseButton>
        </div>
        <div class="flex items-center gap-4 justify-center">
          <div
            v-if="maxTicketsCount > 1"
            class="border flex items-center gap-1 rounded-xl h-10 px-3 border-primary-800"
          >
            <BaseButton
              look="none"
              class="!p-1"
              :disabled="selectedTicketCount === 1"
              @click="removeTicket"
            >
              -
            </BaseButton>
            <div class="w-5 text-center">{{ selectedTicketCount }}</div>
            <BaseButton
              look="none"
              class="!p-1"
              :disabled="selectedTicketCount === maxTicketsCount"
              @click="addTicket"
            >
              +
            </BaseButton>
          </div>
          <BaseButton
            look="border"
            class="!h-10 hover:bg-primary-800 hover:text-white active:text-white transiton duration-150"
            @click="submit"
          >
            {{ $t('tickets.add_to_cart') }}
          </BaseButton>
        </div>
      </div>
    </div>
    <TicketsCategoryCardModal
      v-if="category.additionalInfo"
      :text="category.additionalInfo"
      :ticket-id="selectedTicketId"
      :is-open="isAboutModalOpen"
      @close="isAboutModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import TicketsCategoryCardModal from './TicketsCategoryCardModal.vue'
import type { Currency, TicketDetails } from '~/models/tickets'
import { useTickets } from '~/stores/tickets'
import { languageMap } from '~/models/locale'

export interface TicketSchema {
  label: string
  value: number
  maxTicketsCount?: number
  freeTicketText?: string
}

export interface Category {
  title: string
  text?: string
  color?: string
  isShowVat?: boolean
  additionalInfo?: string
  tickets: TicketSchema[]
}

interface PropsInterface {
  category: Category
  ticketsInfo: TicketDetails[]
}

const props = withDefaults(defineProps<PropsInterface>(), {
  category: () => ({
    title: '',
    text: '',
    color: '#0D65D8',
    tickets: [],
    isShowVat: true,
  }),
  ticketsInfo: () => [],
})

const emits = defineEmits(['update'])
const eventsStore = useEvents()
const { formatPrice } = useTickets()
const { getMarkedTextWithBlankLinks } = useMarkedText()
const i18n = useI18n()
const lang = i18n.locale.value

const isAboutModalOpen = ref(false)
const MAX_TICKET_COUNT = 99
const selectedTicketCount = ref<number>(1)
const selectedTicketId = ref<number | null>(null)

watchEffect(() => {
  // Update selectedTicketId when props.category.tickets change
  selectedTicketId.value = props.category.tickets[0]?.value || null
})

const selectedTicketInfo = computed(() => {
  return props.ticketsInfo.find(
    (ticket) => ticket.id === selectedTicketId.value
  )
})

const selectedTicketConfig = computed(() => {
  return props.category.tickets.find(
    (ticket) => ticket.value === selectedTicketId.value
  )
})

const maxTicketsCount = computed(() => {
  return selectedTicketConfig.value?.maxTicketsCount || MAX_TICKET_COUNT
})

const currency = computed(() => {
  return eventsStore.currency
})

const rawPrice = computed(() => {
  return selectedTicketInfo.value
    ? selectedTicketInfo.value[
        currency.value.toLowerCase() as Lowercase<Currency>
      ] || 0
    : 0
})

const price = computed(() => {
  return selectedTicketInfo.value
    ? formatPrice(
        selectedTicketInfo.value[
          currency.value.toLowerCase() as Lowercase<Currency>
        ] || 0,
        currency.value,
        languageMap[lang]
      )
    : ''
})

const futurePrice = computed(() => {
  return selectedTicketInfo.value?.future_price
    ? formatPrice(
        selectedTicketInfo.value.future_price,
        currency.value,
        languageMap[lang]
      )
    : ''
})

const addTicket = () => {
  selectedTicketCount.value += 1
}

const removeTicket = () => {
  selectedTicketCount.value -= 1
}

const submit = () => {
  emits('update', selectedTicketId.value, selectedTicketCount.value)
  selectedTicketCount.value = 1
}
</script>
