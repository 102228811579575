declare let window: {
  fbq: any
  _fbq: any
}

function loadScript() {
  ;(function (f, b, e, v, n?: any, t?: any, s?: any) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod
        ? // eslint-disable-next-line prefer-rest-params,prefer-spread
          n.callMethod.apply(n, arguments)
        : // eslint-disable-next-line prefer-rest-params
          n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
}

export function debug(...args: any[]) {
  console.log(`[FB Pixel]`, ...args)
}

export function createPixel(id: string, isDebug = false) {
  if (!isDebug) {
    loadScript()

    window.fbq('init', id)
    window.fbq('track', 'PageView')

    console.log('analytics: FB Pixel successfully inited')

    return (...args: any[]) => {
      debug(...args)
      window.fbq(...args)
    }
  } else {
    return debug
  }
}
