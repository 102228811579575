import { defineStore } from 'pinia'

export const useCurrentLang = defineStore('current-lang', () => {
  const language = ref('')

  const setLang = (newLang: string) => {
    language.value = newLang
  }

  return {
    lang: language,
    setLang,
  }
})
