<template>
  <BaseModal
    :is-open="isOpen"
    :title="$t('tickets.about_ticket')"
    size="lg"
    @close="emit('close')"
  >
    <div class="flex flex-col gap-6">
      <div class="marked" v-html="getMarkedTextWithBlankLinks(text)" />
      <div class="ml-auto">
        <BaseButton @click="emit('close')">
          {{ $t('common.ok') }}
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { YandexECommerceEventType } from '~/plugins/yandex-metrika/ecommerce-models'
import { useTickets } from '~/stores/tickets'
import { useEvents } from '~/stores/events'

interface PropsInterface {
  text: string
  ticketId: number | null
  isOpen: boolean
}

const props = defineProps<PropsInterface>()

const emit = defineEmits(['close'])

const { getMarkedTextWithBlankLinks } = useMarkedText()
const {
  $yandexMetrika: { sendECommerceEvent, getECommerceProductFromTicket },
} = useNuxtApp()
const ticketsStore = useTickets()
const eventsStore = useEvents()

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      sendYandexECommerceDetailEvent()
    }
  },
  { immediate: true }
)

function sendYandexECommerceDetailEvent(): void {
  const ticket =
    props.ticketId && ticketsStore.ticketsDetailsMap[props.ticketId]
  const currency = eventsStore.currency

  if (!ticket) {
    return
  }

  sendECommerceEvent({
    currencyCode: currency,
    [YandexECommerceEventType.Detail]: {
      products: [
        getECommerceProductFromTicket(ticket, currency, eventsStore.event),
      ],
    },
  })
}
</script>
