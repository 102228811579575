import { createTag, debug } from './helpers'

export default defineNuxtPlugin(() => {
  const tag = useRuntimeConfig().public.gtmTag

  function initTag() {
    try {
      return createTag(
        tag,
        !tag || useDetect().isInIframe || !!useRoute().query.disableTracking
      )
    } catch (error: any) {
      console.warn('error on create gtag script', error)
      return {
        gTagPush: debug,
        gTagView: debug,
        gTag: debug,
      }
    }
  }

  const { gTagPush, gTagView, gTag } = initTag()

  return {
    provide: {
      gtm: {
        gTagPush,
        gTagView,
        gTag,
      },
    },
  }
})
