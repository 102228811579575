<template>
  <div
    :id="uuid"
    ref="navElement"
    class="overflow-x-scroll max-w-full py-2 px-4 z-10 flex justify-between flex-nowrap gap-3 items-center"
    :class="[
      formData.menuStyle === 'bg' && 'rounded-xl bg-white',
      formData.sticky &&
        'sticky top-[calc(4rem+var(--sat))] data-[stuck]:rounded-t-none group',
      formData.menuStyle === 'bg' &&
        formData.sticky &&
        formData.stickyShadow &&
        'data-[stuck]:shadow-lg',
    ]"
    style="scroll-margin-top: calc(4rem + var(--sat))"
  >
    <div
      v-if="!formData.items.length"
      class="text-red-700 text-lg bg-white text-center"
    >
      {{ $t('admin.menu_block_no_items') }}
    </div>
    <div v-else class="flex items-center gap-2">
      <div v-for="(item, index) in formData.items" :key="index">
        <BaseButton
          v-if="item.isExternalLink && item.externalLink"
          look="none"
          :class="buttonBgStyle"
          rounded="xl"
          size="md"
        >
          <a :href="item.externalLink" target="_blank" :class="buttonTextStyle">
            {{ item.title }}
          </a>
        </BaseButton>
        <template v-if="!item.isExternalLink && item.blockHash">
          <BaseButton
            v-if="item.isBlank"
            look="none"
            :class="buttonBgStyle"
            rounded="xl"
            size="md"
          >
            <a
              :href="getBlockLink(item.blockHash)"
              target="_blank"
              :class="buttonTextStyle"
            >
              {{ item.title }}
            </a>
          </BaseButton>
          <BaseButton
            v-else
            look="none"
            rounded="xl"
            size="md"
            :class="buttonBgStyle"
            @click="scrollToHash(item.blockHash)"
          >
            <span :class="buttonTextStyle">
              {{ item.title }}
            </span>
          </BaseButton>
        </template>
      </div>
    </div>
    <div v-if="formData.buttons?.length" class="flex flex-nowrap gap-2">
      <template v-for="(item, index) in formData.buttons" :key="index">
        <BaseButton
          v-if="item.isExternalLink && item.externalLink"
          :class="ctaButtonStyle"
          rounded="xl"
          size="md"
        >
          <a :href="item.externalLink" target="_blank">
            {{ item.title }}
          </a>
        </BaseButton>
        <template v-if="!item.isExternalLink && item.blockHash">
          <BaseButton
            v-if="item.isBlank"
            rounded="xl"
            size="md"
            :class="ctaButtonStyle"
          >
            <a :href="getBlockLink(item.blockHash)" target="_blank">
              {{ item.title }}
            </a>
          </BaseButton>
          <BaseButton
            v-else
            rounded="xl"
            :class="ctaButtonStyle"
            size="md"
            @click="scrollToHash(item.blockHash)"
          >
            {{ item.title }}
          </BaseButton>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
interface PageEditorMenuItem {
  title: string
  isExternalLink: boolean
  isBlank: boolean
  blockHash?: string
  externalLink?: string
}

interface FormDataInterface {
  menuStyle: 'separate' | 'bg'
  backgroundColor?: string
  sticky?: boolean
  stickyShadow?: boolean
  items: PageEditorMenuItem[]
  buttons?: PageEditorMenuItem[]
}

interface PropsInterface {
  uuid: string
  formData: FormDataInterface
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const { getBlockLink, scrollToHash } = useEditorLinks()

const ctaButtonStyle = computed(() => {
  return props.formData.menuStyle === 'separate'
    ? 'group-[[data-stuck]]:shadow-md'
    : ''
})
const buttonTextStyle = 'text-gray-900 hover:text-gray-900 focus:text-gray-900'
const buttonBgStyle = computed(() => {
  return `bg-white ${
    props.formData.menuStyle === 'separate'
      ? 'hover:bg-ocean group-[[data-stuck]]:shadow-md'
      : 'hover:bg-primary-100'
  }`
})

const navElement = ref<null | HTMLElement>(null)
let stickyObserver: undefined | IntersectionObserver

onMounted(() => {
  if (!navElement.value || !props.formData.sticky) {
    return
  }

  // mobile safe offset
  const offsetTop = Math.ceil(
    Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--sat')
        .split('px')[0]
    )
  )
  stickyObserver = new IntersectionObserver(
    ([e]) => {
      e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1)
    },
    {
      threshold: [1],
      rootMargin: `-${65 + offsetTop}px 0px 0px 0px`, // only px available, 64 - height of header + 1 px
    }
  )

  stickyObserver.observe(navElement.value)
})

onUnmounted(() => {
  stickyObserver && stickyObserver.disconnect()
})
</script>
