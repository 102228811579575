import { FetchError } from 'ofetch'
import { defineStore } from 'pinia'
import { transformGame } from '~/models/games'
import type { Game, GameCreate, GameLite, GameUpdate } from '~/models/games'
import { GameMediaCategory } from '~/models/media'
import { FilterParams } from '~/stores/params-helper'

const API_ENDPOINT = '/games'

interface GamesState {
  game: Game | null
  gameProps: {
    id: number | null
  }
  profileGames: GameLite[] | null
}

export const useGames = defineStore('games', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  const state = reactive<GamesState>({
    game: null,
    gameProps: { id: null },
    profileGames: null,
  })

  async function fetchGame(id: number): Promise<void> {
    if (state.gameProps.id === id) {
      return
    }

    state.gameProps = { id }

    try {
      const { data } = await useAuthFetch<{ data: Game }>(
        `${API_ENDPOINT}/${id}`,
        {
          baseURL,
        }
      )

      const game = transformGame(data)

      if (state.gameProps.id === game.id) {
        state.game = transformGame(data)
      }
    } catch (error: any) {
      if ((error as FetchError).status === 404) {
        throw useCreateScreenError({ message: 'game not found' })
      } else {
        throw error
      }
    }
  }

  function resetGame(): void {
    state.gameProps = { id: null }
    state.game = null
  }

  async function createGame(gameData: GameCreate): Promise<void> {
    const { data } = await useAuthFetch<{ data: Game }>(API_ENDPOINT, {
      method: 'POST',
      baseURL,
      body: gameData,
    })

    state.gameProps = { id: data.id }
    state.game = transformGame(data)
  }

  async function updateGame(id: number, gameData: GameUpdate): Promise<void> {
    const { data } = await useAuthFetch<{ data: Game }>(
      `${API_ENDPOINT}/${id}`,
      {
        method: 'PUT',
        baseURL,
        body: gameData,
      }
    )

    state.gameProps = { id: data.id }
    state.game = transformGame(data)
  }

  async function deleteGame(id: number): Promise<void> {
    await useAuthFetch(`${API_ENDPOINT}/${id}`, {
      method: 'DELETE',
    })

    if (state.gameProps.id === id) {
      resetGame()
    }
  }

  async function uploadImage(
    gameId: number,
    category: GameMediaCategory,
    file: File
  ): Promise<void> {
    const formData = new FormData()

    formData.append('id', String(gameId))
    formData.append('category', category)
    formData.append('media', file)

    const { data } = await useAuthFetch<{ data: Game }>(
      `${API_ENDPOINT}/${gameId}/media`,
      {
        baseURL,
        method: 'POST',
        body: formData,
      }
    )

    state.gameProps = { id: data.id }
    state.game = transformGame(data)
  }

  async function deleteImage(gameId: number, mediaId: number): Promise<void> {
    const { data } = await useAuthFetch<{ data: Game }>(
      `${API_ENDPOINT}/${gameId}/media/${mediaId}`,
      {
        baseURL,
        method: 'DELETE',
      }
    )

    state.gameProps = { id: data.id }
    state.game = transformGame(data)
  }

  async function fetchProfileGames(): Promise<void> {
    if (state.profileGames) {
      return
    }

    const { data } = await useAuthFetch<{ data: GameLite[] }>(
      `${API_ENDPOINT}/me`,
      {
        baseURL,
      }
    )

    state.profileGames = data
  }

  const companyGamesList = initCompanyGamesList()

  function initCompanyGamesList() {
    return useInfinityList<Game>(
      API_ENDPOINT,
      baseURL,
      () => undefined,
      (data) => data.map((item) => transformGame(item))
    )
  }

  async function fetchCompanyGames(companyId: number): Promise<void> {
    if (companyGamesList.state.params[FilterParams.Company] === companyId) {
      return
    }

    companyGamesList.state.params = {
      [FilterParams.Company]: companyId,
    }

    await companyGamesList.getPage(1)
  }

  async function nextCompanyGames(): Promise<void> {
    if (companyGamesList.state.loaded) {
      await companyGamesList.nextPage()
    }
  }

  function resetCompanyGames(): void {
    companyGamesList.state.params = {}
    companyGamesList.reset()
  }

  function resetProfileGames(): void {
    state.profileGames = null
  }

  function getGamePageLink(gameId: number): string {
    return `/games/${gameId}`
  }

  function getGameCreatePageLink(): string {
    return '/users/me/games/create'
  }

  function getGameEditPageLink(gameId: number): string {
    return `/users/me/games/${gameId}/edit`
  }

  function getProfileGamesPageLink(): string {
    return '/users/me/games/'
  }

  function getProfileGamePageLink(gameId: number): string {
    return `/users/me/games/${gameId}`
  }

  return {
    game: toRef(state, 'game'),
    gameProps: toRef(state, 'gameProps'), // not used externally, but return to save state server-client
    profileGames: toRef(state, 'profileGames'),
    fetchGame,
    resetGame,
    createGame,
    updateGame,
    deleteGame,
    uploadImage,
    deleteImage,
    companyGamesList: companyGamesList.state,
    allCompanyGamesLoaded: companyGamesList.allRecordsLoaded,
    fetchCompanyGames,
    nextCompanyGames,
    resetCompanyGames,
    getGamePageLink,
    getGameCreatePageLink,
    getGameEditPageLink,
    getProfileGamesPageLink,
    getProfileGamePageLink,
    fetchProfileGames,
    resetProfileGames,
  }
})
