import { markRaw } from 'vue'
import NavigationCustomView from './NavigationCustomView.vue'
import schema from './schema'

export default {
  viewComponent: markRaw(NavigationCustomView),
  editorSchema: schema,
  editorData: {
    menuStyle: 'bg',
    items: [],
  },
}
