function getTopDomain(domain: string) {
  const domainParts = domain.split('.')

  if (domainParts.length > 2) {
    return [
      domainParts[domainParts.length - 2],
      domainParts[domainParts.length - 1],
    ].join('.')
  }
  return domainParts.join('.').split(':')[0]
}

export const useDomain = () => {
  if (process.server) {
    const headers = useRequestHeaders()
    const host = headers.origin || headers.host

    return getTopDomain(host || '')
  } else {
    return getTopDomain(window.location.hostname)
  }
}
