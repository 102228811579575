<template>
  <template v-if="to">
    <BaseLocaleLink
      :class="[proxyClass, proxyWeight, proxyTheme]"
      :to="to"
      :ignore-current-locale="ignoreCurrentLocale"
    >
      <slot />
    </BaseLocaleLink>
  </template>
  <template v-else>
    <a
      :class="[proxyClass, proxyWeight, proxyTheme]"
      :target="target"
      :href="href"
    >
      <slot />
    </a>
  </template>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { computed } from 'vue'

const themes = {
  black:
    'text-black hover:text-gray-600 active:text-gray-600 transition duration-150',
  dark: 'text-gray-900 hover:text-gray-600 active:text-gray-600 transition duration-150',
  light:
    'text-gray-500 hover:text-gray-400 active:text-gray-600 transition duration-150',
  white:
    'text-gray-100 hover:text-gray-300 active:text-gray-400 transition duration-150',
  primary:
    'text-primary hover:text-primary-800 active:text-primary-900 transition duration-150',
  success:
    'text-green-500 hover:text-green-400 active:text-green-600 transition duration-150',
  destructive:
    'text-red-500 hover:text-red-400 active:text-red-600 transition duration-150',
  info: 'text-blue-500 hover:text-blue-400 active:text-blue-600 transition duration-150',
} as { [key: string]: string }

const weights = {
  light: 'font-light',
  normal: 'font-normal',
  semibold: 'font-semibold',
  bold: 'font-bold',
  black: 'font-black',
} as { [key: string]: string }

const props = defineProps({
  to: {
    type: [Object, String] as PropType<RouteLocationRaw>,
    default: undefined,
  },
  ignoreCurrentLocale: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String,
    default: '',
  },
  theme: {
    type: String as PropType<keyof typeof themes>,
    default: 'info',
  },
  weight: {
    type: String as PropType<keyof typeof weights>,
    default: 'semibold',
  },
  target: {
    type: String,
    default: '_self',
  },
  proxyClass: {
    type: String,
    default: 'inline-flex items-center',
  },
})

const proxyTheme = computed(() => themes[props.theme] || '')
const proxyWeight = computed(() => weights[props.weight] || '')
</script>
