export const DEFAULT_COLOR_GRAY = '#414141'
export const DEFAULT_COLOR_GRAY_LIGHT = '#A3ADBB'
export const DEFAULT_COLOR_PRIMARY = '#2B81F2' // primary-800
export const DEFAULT_COLOR_PRIMARY_HOVER = '#4591F4' // primary-700
export const DEFAULT_COLOR_WHITE = '#ffffff'
export const DEFAULT_IMAGE_SQUARE =
  'https://cdn.wnhub.io/29192/64de06ee996fd5.07154662.png'
export const DEFAULT_IMAGE_SVG_SQUARE =
  'https://cdn.wnhub.io/42570/66504939c64103.26987588.svg'
export const DEFAULT_IMAGE_HORIZONTAL =
  'https://cdn.wnhub.io/29193/64de07c7a8b753.02448512.png'
export const DEFAULT_AVATAR =
  'https://cdn.wnhub.io/44440/6683ccd4bb6a06.06915199.svg'
export const DEFAULT_IMAGE_VERTICAL =
  'https://cdn.wnhub.io/44939/6687d79fb51f00.63044591.svg'
export const DEFAULT_LINK = 'https://wnhub.io/'
export const DEFAULT_MARKDOWN_PLACEHOLDER = 'Write your markdown text here'
export const DEFAULT_MARKDOWN_TEXT = '# H1 title \n\n Default **markdown** text'
export const DEFAULT_JOB = 'CEO and Creative Director'
export const DEFAULT_NAME = 'John Snow'
export const DEFAULT_TEXT = 'Default'
export const DEFAULT_SHORT_TEXT =
  'Morbi et orci sapien. Morbi vehicula elit vel diam tristique sagittis. Etiam vulputate risus risus, sed malesuada dui egestas ac. Etiam cursus hendrerit sapien, quis malesuada massa euismod bibendum.'
export const DEFAULT_LONG_TEXT =
  'Aliquam justo est, scelerisque non faucibus quis, suscipit ac arcu. Integer quis convallis risus. Proin rutrum neque a leo tincidunt, eu sagittis mi scelerisque. Maecenas id libero nec nibh accumsan tempus eu tristique erat. Nunc feugiat pulvinar consectetur. Ut a tincidunt neque. Etiam blandit venenatis nunc, tincidunt ultricies est auctor a. In suscipit nec nisl vel mollis. In at dui quam.'
export const DEFAULT_LONG_MARKDOWN_TEXT =
  '### Morbi et orci sapien. Morbi vehicula elit vel diam tristique sagittis \n\n Etiam vulputate risus risus, sed malesuada dui egestas ac. Etiam cursus hendrerit sapien, quis malesuada massa euismod bibendum. Integer quis convallis risus. Proin rutrum neque a leo tincidunt, eu sagittis mi scelerisque.'
