import { defineStore } from 'pinia'
import { useLogError } from '~/composables/use-log-error'
import { transformProfile } from '~/models/users'
import type {
  UserBasicData,
  UserProfile,
  UserProfileUpdate,
} from '~/models/users'
import type { AuthData } from '~/models/auth'

interface AuthState {
  profile: UserProfile | null
  isLoginModalOpen: boolean
}

export const useAuth = defineStore('auth', () => {
  const runtimeConfig = useRuntimeConfig() // executing useRuntimeConfig inside catch causes nuxt app instance unavailable in SSR mode
  const baseURL = runtimeConfig.public.gatewayApi
  const { cookie: authCookie } = useAuthCookie()
  const { isApp } = useDetect()
  const { getBrowserTimezone, isTimezoneExists } = useTimezones()
  const { isInIframe } = useDetect()

  let popup: null | Window = null

  function handleAuthMessage(event: MessageEvent) {
    if (
      event.data.message_type === 'auth-response' &&
      event.data.access &&
      typeof event.data.access === 'string'
    ) {
      console.log('auth: set auth data from postMessage', event.data)
      authCookie.value = {
        access_token: event.data.access,
        refresh_token: '',
        expires_in: 0,
      }
    }
  }
  if (isInIframe) {
    window.addEventListener('message', handleAuthMessage, false)
  }

  watch(
    authCookie,
    (newValue, oldValue) => {
      console.log(
        'auth: cookie value changed',
        JSON.stringify(newValue),
        JSON.stringify(oldValue)
      )
    },
    { deep: true }
  )

  async function onGoogleAuthMessage(event: MessageEvent) {
    if (event.data.event === 'google-auth' && popup) {
      !popup.closed && popup.close()
      state.isLoginModalOpen = false
      console.log(
        'auth: on google auth message',
        JSON.stringify(event.data.data)
      )
      authCookie.value = event.data.data
      await fetchProfile()
      await updateProfileTimezone()
    }
  }

  process.client && window.addEventListener('message', onGoogleAuthMessage)

  const state = reactive<AuthState>({
    profile: null,
    isLoginModalOpen: false,
  })

  const login = async (auth: AuthData) => {
    console.log('auth: login', JSON.stringify(auth))
    authCookie.value = auth
    console.log('auth: set authCookie.value', JSON.stringify(authCookie.value))
    isApp && (await usePushService().registerNotifications())
  }

  const logout = async () => {
    console.log('auth: logout')
    const { $sentrySetUser } = useNuxtApp()
    isApp && (await usePushService().removeAppToken())
    authCookie.value = null
    console.log('auth: set authCookie to null', authCookie.value)
    state.profile = null
    $sentrySetUser && process.client && $sentrySetUser(null)
  }

  const authWithPassword = async (email: string, password: string) => {
    const response = await useAuthFetch<AuthData>('/oauth/token', {
      baseURL,
      method: 'POST',
      body: {
        email,
        password,
      },
    })

    console.log('auth: auth with password')
    await login(response)
  }

  const requestGoogleCode = () => {
    const params = [
      'scope=email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      'response_type=code',
      `redirect_uri=${useHost()}/auth/google`,
      `client_id=${runtimeConfig.public.googleAuthId}`,
    ]

    const width = 480
    const height = 640
    const left = screen.width / 2 - width / 2
    const top = screen.height / 2 - height / 2

    popup = window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?${params.join('&')}`,
      'popup',
      `popup=1,width=${width},height=${height},left=${left},top=${top}`
    )
  }

  const authWithGoogleCode = async (code: string, redirect: string) => {
    const response = await useKrakenFetch<AuthData>('/oauth/token/google', {
      baseURL,
      method: 'POST',
      body: {
        code,
        redirect,
      },
    })

    console.log('auth: auth with google code')
    await login(response)
  }

  const requestMagic = async (email: string, redirect?: string) => {
    await useAuthFetch<{ data: any }>('/register/magic', {
      baseURL,
      method: 'POST',
      body: {
        email,
        ...(redirect && { redirect: encodeURIComponent(redirect) }),
        // wnhub app to enable magic code for mobile app
        // uncomment if we want magic without code for web
        // app: isApp ? 'wnhub' : 'wnhub_general',
        app: 'wnhub',
      },
    })
  }

  const authWithMagic = async (code: string) => {
    const response = await useAuthFetch<AuthData>('/oauth/token/magic', {
      baseURL,
      method: 'POST',
      body: {
        code,
      },
    })

    console.log('auth: auth with magic')
    await login(response)
  }

  const authWithMagicCode = async (body: { email: string; code: string }) => {
    const response = await useAuthFetch<AuthData>('/oauth/token/magic-code', {
      baseURL,
      method: 'POST',
      body,
    })

    console.log('auth: auth with magic code')
    await login(response)
  }

  function trackRegister() {
    const {
      $cityads: { trackClickIdRegister },
      $gtm: { gTagPush },
      $facebook: { fbq },
    } = useNuxtApp()

    // we automatically set timezone on each login to keep it updated, so on the register and first auth there is no timezone set
    // it is not solid condition to detect login but best way to detect google auth registers for now for analytics purposes
    if (!isTimezoneSet.value && process.client) {
      trackClickIdRegister(String(state.profile?.id))
      fbq('track', 'CompleteRegistration')
      gTagPush({ event: 'CompleteRegistration' })
    }
  }
  // On SSR we have to ensure that all components will have profile data without extra requests
  let fetchProfilePromise: null | Promise<undefined> = null
  const fetchProfile = () => {
    const nuxtApp = useNuxtApp()
    if (state.profile) {
      return
    }
    if (fetchProfilePromise) {
      return fetchProfilePromise
    }

    fetchProfilePromise = new Promise((resolve, reject) => {
      ;(async () => {
        try {
          const { data } = await useAuthFetch<{ data: UserProfile }>(
            `/users/me`,
            {
              baseURL,
            }
          )

          state.profile = transformProfile(data)

          nuxtApp.runWithContext(() => {
            trackRegister()
            setSentryUser()
          })
          resolve(undefined)
        } catch (error: any) {
          reject(error)
        } finally {
          fetchProfilePromise = null
        }
      })()
    })

    return fetchProfilePromise
  }

  function setSentryUser() {
    try {
      if (process.server) {
        return
      }
      const { $sentrySetUser } = useNuxtApp()
      if ($sentrySetUser && state.profile) {
        console.log('auth: set sentry profile')
        $sentrySetUser({
          id: String(state.profile.id),
          email: state.profile.email,
        })
      }
    } catch (error: any) {
      useLogError(error)
    }
  }

  const updateProfile = async (userData: UserProfileUpdate) => {
    const response = await useAuthFetch<{ data: UserProfile }>(`/users/me`, {
      baseURL,
      method: 'PUT',
      body: userData,
    })

    state.profile = transformProfile(response.data)
  }

  const refreshProfile = async () => {
    const { data } = await useAuthFetch<{ data: UserProfile }>(`/users/me`, {
      baseURL,
    })

    state.profile = transformProfile(data)
  }

  const updateProfileTimezone = async () => {
    if (!process.client || !state.profile || !authCookie.value) {
      return
    }

    const userTimezone = state.profile?.timezone?.id
    const browserTimezone = getBrowserTimezone()

    if (userTimezone !== browserTimezone) {
      const isBrowserTimezoneExists = await isTimezoneExists(browserTimezone)

      if (userTimezone && !isBrowserTimezoneExists) {
        return
      }

      try {
        const response = await useAuthFetch<{ data: UserProfile }>(
          `/users/me`,
          {
            baseURL,
            method: 'PUT',
            body: {
              timezone_id: isBrowserTimezoneExists ? browserTimezone : 'UTC',
            },
          }
        )
        state.profile = transformProfile(response.data)
      } catch (error) {
        useLogError(error)
      }
    }
  }

  const uploadMedia = async (
    file: File,
    category?: 'image' | 'logo' | 'background'
  ) => {
    const formData = new FormData()

    formData.append('category', category || 'logo')
    formData.append('media', file)

    const response = await useAuthFetch<{ data: UserProfile }>(
      `/users/me/media`,
      {
        baseURL,
        method: 'POST',
        body: formData,
      }
    )

    state.profile = response.data
  }

  const refreshToken = async () => {
    console.log('auth: refresh token with', authCookie.value?.refresh_token)
    if (!authCookie.value || !authCookie.value.refresh_token) {
      console.log('auth: no refresh token')
      throw new Error('No refresh token')
    }

    const response = await useKrakenFetch<AuthData>('/oauth/token/refresh', {
      baseURL,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authCookie.value?.refresh_token}`,
      },
    })

    authCookie.value = response
    console.log('auth: refreshed to', response)
  }

  const openLoginModal = () => {
    state.isLoginModalOpen = true
  }

  const closeLoginModal = () => {
    state.isLoginModalOpen = false
  }

  const currentUserBasicData: ComputedRef<UserBasicData | null> = computed(
    () => {
      if (!state.profile) {
        return null
      } else {
        return {
          id: state.profile?.id,
          name: getFullName(state.profile) as string,
          media: getAvatar(state.profile),
          is_subscription_active: state.profile.is_subscription_active,
        }
      }
    }
  )

  console.log(
    'auth: init auth cookie',
    JSON.stringify(authCookie.value),
    state.profile?.id
  )

  const isProfileCompleted = computed(
    () => state.profile?.first_name && state.profile?.last_name
  )

  const isTimezoneSet = computed(() => state.profile?.timezone)

  return {
    auth: readonly(authCookie),
    profile: toRef(state, 'profile'),
    isLoginModalOpen: toRef(state, 'isLoginModalOpen'),
    userId: computed(() => state.profile?.id),
    ticket: computed(() => state.profile?.ticket || 'Community'),
    isProfileCompleted,
    isAdmin: computed(() =>
      state.profile && state.profile.roles
        ? state.profile.roles.includes('admin') ||
          state.profile.roles.includes('super_admin')
        : false
    ),
    isProActive: computed(() => !!state.profile?.is_subscription_active),
    uploadMedia,
    authWithPassword,
    fetchProfile,
    refreshProfile,
    authWithMagic,
    authWithMagicCode,
    requestMagic,
    refreshToken,
    requestGoogleCode,
    authWithGoogleCode,
    logout,
    openLoginModal,
    closeLoginModal,
    updateProfile,
    currentUserBasicData,
    updateProfileTimezone,
    setSentryUser,
    trackRegister,
  }
})
