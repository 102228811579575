declare global {
  interface Window {
    xcnt_product_id?: string
    xcnt_basket_products?: string
    xcnt_basket_quantity?: string
    xcnt_order_products?: string
    xcnt_order_id?: number
    xcnt_order_total?: string
    xcnt_order_currency?: string
  }
}

type SafeFunction<F extends (...args: any[]) => any> = (
  ...args: Parameters<F>
) => ReturnType<F> | undefined

function withTryCatch<F extends (...args: any[]) => any>(
  fn: F
): SafeFunction<F> {
  return (...args: Parameters<F>): ReturnType<F> | undefined => {
    try {
      return fn(...args)
    } catch (error) {
      debug('error', error)
      return undefined // You can return a fallback value if desired
    }
  }
}

type customerType = 'new' | 'returned'
const analyticsPixelCode = `<img src="//p.cityadstrack.com/analytics.gif" width="0" height="0" style="display: none !important; width: 0px !important; height: 0px !important;" onerror="var s=document.createElement('script'); s.src=this.src+'?iserror',s.async=!0,document.body.appendChild(s);">`
const getTrackingPixelFallbackCode = (
  id: string,
  clickId: string,
  orderId: string,
  customerType: customerType,
  orderTotal?: string,
  currency?: string
) => `
<noscript><iframe src="https://cityadstrack.com/tr/xframe/${orderId}/ct/q1/c/${id}?click_id=${clickId}&customer_type=${customerType}${
  orderTotal ? '&order_total=' + orderTotal : ''
}${
  currency ? '&currency=' + currency : ''
}" width="1" height="1"></iframe></noscript>`

const createScriptElement = (
  id: string,
  clickId: string,
  orderId: string,
  customerType: customerType,
  orderTotal?: string,
  currency?: string
) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://cityadstrack.com/tr/js/${orderId}/ct/q1/c/${id}?click_id=${clickId}&customer_type=${customerType}${
    orderTotal ? '&order_total=' + orderTotal : ''
  }${currency ? '&currency=' + currency : ''}`
  return script
}

function initAnalyticsPixel() {
  useRouter().beforeEach((to, from, next) => {
    if (process.client) {
      debug('add analytics Pixel', to.path, from.path)
      try {
        document.body.insertAdjacentHTML('beforeend', analyticsPixelCode)

        if (
          !to.path.endsWith(`/event-info/${to.params.event}`) &&
          !to.path.endsWith(`/academy/${to.params.event}`) &&
          !to.path.endsWith(`/cart/${to.params.event}`) &&
          !to.path.endsWith(`/users/me/orders/${to.params.id}`)
        ) {
          resetTrackingVariables()
          document.body.appendChild(createTrackingScript())
          debug('track any page, if not product/cart/order page')
        }
      } catch (error: any) {
        debug('failed to add CityAd analytics Pixel', error)
      }
    }
    next()
  })
}

function createTrackingScript() {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = `//x.cnt.my/async/track/?r=${Math.random()}`
  // script.id = 'xcntmyAsync'
  return script
}

function resetTrackingVariables() {
  window.xcnt_product_id = undefined
  window.xcnt_basket_products = undefined
  window.xcnt_basket_quantity = undefined
  window.xcnt_order_products = undefined
  window.xcnt_order_id = undefined
  window.xcnt_order_total = undefined
  window.xcnt_order_currency = undefined
}

function trackProduct(id: string) {
  resetTrackingVariables()
  window.xcnt_product_id = id
  document.body.appendChild(createTrackingScript())
  debug('track product', id)
}

function trackProducts(ids: number[], quantity: number[]) {
  resetTrackingVariables()
  window.xcnt_basket_products = ids?.join(',')
  window.xcnt_basket_quantity = quantity?.join(',')
  document.body.appendChild(createTrackingScript())
  debug('track products', ids, quantity)
}

function trackOrder(
  id: number,
  total: string,
  currency: string,
  products: number[]
) {
  resetTrackingVariables()
  window.xcnt_order_products = products?.join(',')
  window.xcnt_order_id = id
  window.xcnt_order_total = total
  window.xcnt_order_currency = currency
  document.body.appendChild(createTrackingScript())
  debug('track order', id, total, currency, products)
}

function saveUTMParams() {
  const clickIdParam = 'click_id'
  const utmSourceParam = 'utm_source'
  const utmMediumParam = 'utm_medium'
  const utmCompaignParam = 'utm_campaign'
  const maxAge = 60 * 60 * 24 * 30 // 30 days
  if (process.client) {
    const urlParams = new URLSearchParams(window.location.search)
    const clickId = urlParams.get(clickIdParam)
    const utmSource = urlParams.get(utmSourceParam)
    const utmMedium = urlParams.get(utmMediumParam)
    const utmCompaign = urlParams.get(utmCompaignParam)
    const clickIdCookie = useCookie(clickIdParam, { maxAge })
    const utmSourceCookie = useCookie(utmSourceParam, { maxAge })
    const utmMediumCookie = useCookie(utmMediumParam, { maxAge })
    const utmCompaignCookie = useCookie(utmCompaignParam, { maxAge })

    if (clickId) {
      clickIdCookie.value = clickId
      debug('save click_id param in cookie', clickId)
    }
    if (utmSource) {
      utmSourceCookie.value = utmSource
      debug('save utm_source param in cookie', utmSource)
    }
    if (utmMedium) {
      utmMediumCookie.value = utmMedium
      debug('save utm_medium param in cookie', utmMedium)
    }
    if (utmCompaign) {
      utmCompaignCookie.value = utmCompaign
      debug('save utm_campaign param in cookie', utmCompaign)
    }
  }
}

export function debug(...args: any[]) {
  console.log(`[CityAds Pixel]`, ...args)
}

function trackWithClickId(
  id: string,
  type: customerType,
  orderId: string,
  orderTotal?: string,
  currency?: string
) {
  const maxAge = 60 * 60 * 24 * 30 // 30 days
  const clickId = useCookie('click_id', { maxAge }).value
  const utmSource = useCookie('utm_source', { maxAge }).value
  const isTriggered = useCookie(`cityads_${type}_${orderId}_triggered`, {
    maxAge,
  })

  debug(
    'track with click_id',
    isTriggered.value,
    id,
    clickId,
    utmSource,
    orderId,
    type,
    orderTotal,
    currency
  )
  if (clickId && utmSource === 'cityads' && !isTriggered.value) {
    document.body.insertAdjacentHTML(
      'beforeend',
      getTrackingPixelFallbackCode(
        id,
        clickId,
        orderId,
        type,
        orderTotal,
        currency
      )
    )
    document.body.appendChild(
      createScriptElement(id, clickId, orderId, type, orderTotal, currency)
    )
    isTriggered.value = 'true'
  }
}

export function createPixel(id: string, isDebug = false) {
  if (!isDebug) {
    initAnalyticsPixel()
    saveUTMParams()

    console.log('analytics: CityAds Pixel successfully inited with id:', id)

    return {
      trackClickIdRegister: withTryCatch((orderId: string) => {
        trackWithClickId(id, 'new', orderId)
      }),
      trackClickIdOrder: withTryCatch(
        (orderId: string, orderTotal: string, currency: string) => {
          trackWithClickId(id, 'returned', orderId, orderTotal, currency)
        }
      ),
      trackProduct: withTryCatch(trackProduct),
      trackProducts: withTryCatch(trackProducts),
      trackOrder: withTryCatch(trackOrder),
    }
  } else {
    debug('debug mode')
    return {
      trackClickIdRegister: debug,
      trackClickIdOrder: debug,
      trackProduct: debug,
      trackProducts: debug,
      trackOrder: debug,
    }
  }
}
