import type { OptionInterface } from '~/components/base/controls/use-simple-input'

export const camelToCapitalize = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

export function enumToOptions(
  enumObject: { [key: number | string]: number | string },
  isNumeric = true,
  split = false
): OptionInterface[] {
  return Object.keys(enumObject)
    .filter((key) =>
      isNumeric
        ? !isNaN(Number(enumObject[key as keyof typeof enumObject]))
        : key
    )
    .map((key) => ({
      label: split ? camelToCapitalize(key) : key,
      value: enumObject[key as keyof typeof enumObject],
    }))
}

export function translateOptions(
  options: readonly OptionInterface[],
  t: (str: string) => string,
  section: string
): OptionInterface[] {
  return options.map((option) => {
    return {
      value: option.value,
      label: t(`${section}.${option.label}`),
    }
  })
}

export function optionsToMap(options: readonly OptionInterface[]) {
  return options.reduce((acc, { value, label }) => {
    if (value) {
      acc[value] = label
    }
    return acc
  }, {} as Record<number | string, string>)
}
