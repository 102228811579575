import { markRaw } from 'vue'
import { DEFAULT_COLOR_GRAY, DEFAULT_LONG_TEXT } from '../constants'
import ColumnsView from './ColumnsView.vue'
import schema from './schema'

export default {
  viewComponent: markRaw(ColumnsView),
  editorSchema: schema,
  editorData: {
    colsNumberOnDesktop: 2,
    columns: [
      {
        text: DEFAULT_LONG_TEXT,
        textColor: DEFAULT_COLOR_GRAY,
      },
      {
        text: DEFAULT_LONG_TEXT,
        textColor: DEFAULT_COLOR_GRAY,
      },
    ],
  },
}
