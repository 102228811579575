<template>
  <div
    :id="uuid"
    ref="navElement"
    class="overflow-x-scroll max-w-full py-2 px-4 z-10 flex flex-nowrap gap-2 items-center"
    :class="[
      formData.menuStyle === 'bg' && 'rounded-xl bg-white',
      formData.sticky &&
        'sticky top-[calc(4rem+var(--sat))] data-[stuck]:rounded-t-none group',
      formData.menuStyle === 'bg' &&
        formData.sticky &&
        formData.stickyShadow &&
        'data-[stuck]:shadow-lg',
      formData.align && formData.align !== 'justify-center'
        ? formData.align
        : 'justify-between',
    ]"
    :style="{
      'scroll-margin-top': 'calc(4rem + var(--sat))',
      'background-color':
        formData.menuStyle === 'bg' ? formData.bgColor : undefined,
    }"
  >
    <div
      v-if="formData.items?.length"
      class="flex items-center gap-2"
      :class="
        formData.align === 'justify-center' && 'first:ml-auto last:mr-auto'
      "
    >
      <div v-for="(button, index) in formData.items" :key="index">
        <EditorButton
          :button="button"
          :class="[
            formData.menuStyle === 'separate'
              ? 'group-[[data-stuck]]:shadow-md'
              : '',
          ]"
          @click="trackClick(button)"
        />
      </div>
    </div>
    <div
      v-if="formData.buttons?.length"
      class="flex flex-nowrap gap-2"
      :class="
        formData.align === 'justify-center' && 'first:ml-auto last:mr-auto'
      "
    >
      <template v-for="(button, index) in formData.buttons" :key="index">
        <EditorButton
          :button="button"
          :class="
            formData.menuStyle === 'separate'
              ? 'group-[[data-stuck]]:shadow-md'
              : ''
          "
          @click="trackClick(button)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonInterface } from '../common'
import EditorButton from '~/components/page-editor/EditorButton.vue'

interface FormDataInterface {
  menuStyle: 'separate' | 'bg'
  align?: string
  bgColor?: string
  sticky?: boolean
  stickyShadow?: boolean
  items?: ButtonInterface[]
  buttons?: ButtonInterface[]
}

interface PropsInterface {
  uuid: string
  formData: FormDataInterface
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const {
  $gtm: { gTagPush },
} = useNuxtApp()

const navElement = ref<null | HTMLElement>(null)
let stickyObserver: undefined | IntersectionObserver

function trackClick(button: ButtonInterface) {
  if (button.blockHash?.toLowerCase().includes('ticket')) {
    gTagPush({ event: 'Lead' })
  }
}

onMounted(() => {
  if (!navElement.value || !props.formData.sticky) {
    return
  }

  // mobile safe offset
  const offsetTop = Math.ceil(
    Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--sat')
        .split('px')[0]
    )
  )
  stickyObserver = new IntersectionObserver(
    ([e]) => {
      e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1)
    },
    {
      threshold: [1],
      rootMargin: `-${65 + offsetTop}px 0px 0px 0px`, // only px available, 64 - height of header + 1 px
    }
  )

  stickyObserver.observe(navElement.value)
})

onUnmounted(() => {
  stickyObserver && stickyObserver.disconnect()
})
</script>
