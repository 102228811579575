import { markRaw } from 'vue'
import { DEFAULT_COLOR_PRIMARY, DEFAULT_LONG_TEXT } from '../constants'
import TabsView from './TabsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(TabsView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    activeTabButtonColor: DEFAULT_COLOR_PRIMARY,
    activeTabTextColor: '#ffffff',
    tabs: [
      {
        label: 'Suspendisse elementum purus',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Sed tincidunt tellus eu neque dapibus',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Fusce eget metus quis erat',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Integer a libero vel quam',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Suspendisse in urna blandit',
        text: DEFAULT_LONG_TEXT,
      },
    ],
  },
}
