<template>
  <BaseLink
    v-if="isExternalLink && linkUrl"
    :href="isRelativeLink(button.url) ? undefined : linkUrl"
    :to="isRelativeLink(button.url) ? linkUrl : undefined"
    :target="getLinkTarget(button.url, button.isBlank)"
    :ignore-current-locale="button.ignoreCurrentLocale"
    @click="$emit('click')"
  >
    <BaseButton
      v-if="button.type === 'button'"
      look="none"
      :class="[buttonClass, classNames]"
    >
      {{ button.text }}
    </BaseButton>
    <span v-else :class="buttonClass" class="px-5">
      {{ button.text }}
    </span>
  </BaseLink>
  <template v-if="!isExternalLink && button.blockHash">
    <BaseLink
      v-if="button.isBlank"
      :href="getBlockLink(button.blockHash)"
      target="_blank"
      @click="$emit('click')"
    >
      <BaseButton look="none" :class="buttonClass">
        {{ button.text }}
      </BaseButton>
    </BaseLink>
    <BaseButton
      v-else
      look="none"
      :class="[buttonClass, classNames]"
      @click="
        // eslint-disable-next-line prettier/prettier
        scrollToHash(button.blockHash);
        $emit('click')
      "
    >
      {{ button.text }}
    </BaseButton>
  </template>
</template>

<script setup lang="ts">
import { type ButtonInterface } from './common'

interface PropsInterface {
  button: ButtonInterface
  classNames?: string
}

const props = defineProps<PropsInterface>()
defineEmits(['click'])

const {
  getBlockLink,
  scrollToHash,
  getLinkTarget,
  getLinkUrl,
  isRelativeLink,
} = useEditorLinks()

// for already existing buttons where the isExternalLink is undefined
const isExternalLink = computed(() => {
  return props.button.isExternalLink ?? true
})

const linkUrl = computed(() => getLinkUrl(props.button.url))

const buttonClass = computed(() => {
  let classNames = 'editor-button '

  if (props.button.type === 'button') {
    if (props.button.look === 'border') {
      classNames += 'editor-button_border'
    } else {
      classNames += 'editor-button_solid'
    }
  } else {
    classNames += 'editor-button_link'
  }

  if (colorHover.value) {
    classNames += ' editor-button_hover-color'
  } else {
    classNames += ' editor-button_no-hover-color'
  }

  return classNames
})

const color = computed(() => props.button.color)
const colorHover = computed(() => props.button.colorHover)
const textColor = computed(() => props.button.textColor || '#ffffff')
</script>

<style lang="scss" scoped>
.editor-button {
  transition-duration: 150ms;

  &_border {
    color: v-bind(color);
    border: 2px solid v-bind(color);

    &.editor-button_hover-color:hover,
    &.editor-button_hover-color:focus {
      color: v-bind(colorHover);
      border: 2px solid v-bind(colorHover);
    }
  }

  &_solid {
    background-color: v-bind(color);
    color: v-bind(textColor);

    &.editor-button_hover-color:hover,
    &.editor-button_hover-color:focus {
      background-color: v-bind(colorHover);
    }
  }

  &_link {
    color: v-bind(color);

    &.editor-button_hover-color:hover,
    &.editor-button_hover-color:focus {
      color: v-bind(colorHover);
    }
  }

  &_no-hover-color:hover,
  &_no-hover-color:focus {
    filter: brightness(1.5);
  }
}
</style>
