export default {
  description: {
    'ui:widget': 'EditorMarkdownWidget',
  },
  textColor: {
    'ui:widget': 'BaseColorWidget',
  },
  buttonColor: {
    'ui:widget': 'BaseColorWidget',
  },
  bgColor: {
    'ui:widget': 'BaseColorWidget',
  },
  borderColor: {
    'ui:widget': 'BaseColorWidget',
  },
  successMessage: {
    'ui:widget': 'EditorMarkdownWidget',
  },
  textUnderForm: {
    'ui:widget': 'EditorMarkdownWidget',
  },
  emailTemplate: {
    'ui:widget': 'EditorEmailTemplateWidget',
  },
}
