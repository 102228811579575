import { markRaw } from 'vue'
import View from './BannersView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    banners: [
      {
        text: '# Getting acquainted \n\n The client briefs us about the company, the project, and the team, describes the tasks faced and the ideal candidate.',
        textColor: '#ffffff',
        textAlign: 'center',
        paddingSize: 'md',
        isBgImage: true,
        bgImage: 'https://cdn.wnhub.io/39253/65dde526e1a6c4.93735968.jpg',
        buttons: [],
      },
      {
        text: '# Getting acquainted \n\n The client briefs us about the company, the project, and the team, describes the tasks faced and the ideal candidate.',
        textColor: '#ffffff',
        textAlign: 'center',
        paddingSize: 'md',
        isBgImage: true,
        bgImage: 'https://cdn.wnhub.io/39253/65dde526e1a6c4.93735968.jpg',
        buttons: [],
      },
    ],
  },
}
