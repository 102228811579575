import type { Meta, Link } from '@unhead/vue'
import type { MaybeRefOrGetter } from '@vueuse/core'

type HeadItemContent = MaybeRefOrGetter<string>

interface HeadMeta {
  title: HeadItemContent
  description?: HeadItemContent
  image?: HeadItemContent
  keywords?: HeadItemContent
  article?: {
    sections: HeadItemContent[]
    publishedTime: HeadItemContent
    modifiedTime?: HeadItemContent
    expirationTime?: HeadItemContent
    tags?: HeadItemContent[]
    authors?: HeadItemContent[]
  }
}

interface HeadLinks {
  canonical?: HeadItemContent
}

export const useHeadMeta = (headMeta: HeadMeta, headLinks?: HeadLinks) => {
  if (useNuxtApp().isHydrating) {
    return
  }
  let meta: Meta[] = [
    { property: 'og:title', content: headMeta.title },
    { name: 'twitter:title', content: headMeta.title },
  ]

  if (headMeta.description) {
    meta = [
      ...meta,
      { name: 'description', content: headMeta.description },
      { property: 'og:description', content: headMeta.description },
      { name: 'twitter:description', content: headMeta.description },
    ]
  }

  if (headMeta.keywords) {
    meta = [...meta, { name: 'keywords', content: headMeta.keywords }]
  }

  if (headMeta.image) {
    meta = [
      ...meta,
      { property: 'og:image', content: headMeta.image },
      { name: 'twitter:image', content: headMeta.image },
    ]
  }

  if (headMeta.article) {
    meta = [
      ...meta,
      { property: 'og:type', content: 'article' },
      {
        property: 'article:published_time',
        content: headMeta.article.publishedTime,
      },
    ]

    meta = headMeta.article.sections.reduce(
      (previousValue, currentValue, currentIndex) => {
        return [
          ...previousValue,
          {
            property: 'article:section',
            content: currentValue,
            hid: `section-${currentIndex}`,
          },
        ]
      },
      meta
    )

    if (headMeta.article.modifiedTime) {
      meta = [
        ...meta,
        {
          property: 'article:modified_time',
          content: headMeta.article.modifiedTime,
        },
      ]
    }

    if (headMeta.article.expirationTime) {
      meta = [
        ...meta,
        {
          property: 'article:expiration_time',
          content: headMeta.article.expirationTime,
        },
      ]
    }

    if (headMeta.article.tags) {
      meta = headMeta.article.tags.reduce(
        (previousValue, currentValue, currentIndex) => {
          return [
            ...previousValue,
            {
              property: 'article:tag',
              content: currentValue,
              hid: `tag-${currentIndex}`,
            },
          ]
        },
        meta
      )
    }

    if (headMeta.article.authors) {
      meta = headMeta.article.authors.reduce(
        (previousValue, currentValue, currentIndex) => {
          return [
            ...previousValue,
            {
              property: 'article:author',
              content: currentValue,
              hid: `author-${currentIndex}`,
            },
          ]
        },
        meta
      )
    }
  }

  let links: Link[] = []

  if (headLinks?.canonical) {
    links = [...links, { rel: 'canonical', href: headLinks.canonical }]
  }

  useHead({
    title: headMeta.title,
    titleTemplate: (title: string | undefined) => {
      return title ? `${title} | WN Hub` : 'WN Hub'
    },
    meta,
    link: links,
  })
}
