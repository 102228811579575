export default {
  image: {
    'ui:widget': 'EditorUploadWidget',
  },
  text: {
    'ui:widget': 'EditorMarkdownWidget',
  },
  textColor: {
    'ui:widget': 'BaseColorWidget',
  },
  bgColor: {
    'ui:widget': 'BaseColorWidget',
  },
  cardTextColor: {
    'ui:widget': 'BaseColorWidget',
  },
  cardBgColor: {
    'ui:widget': 'BaseColorWidget',
  },
}
