export enum EmailTypes {
  PlatformRegistration = 1,
  PasswordRecovery = 2,
  JobApplication = 3,
  EventRegistrationNewUser = 4,
  EventRegistrationExistingUser = 5,
  ChatNewMessage = 6,
  MeetingRequestSent = 7,
  MeetingRequestAccepted = 8,
  MeetingRequestRejected = 9,
  MeetingCanceled = 10,
  MeetingParticipantLeave = 11,
  MeetingPostponed = 12,
  Certificate = 13,
  Custom = 14,
}

export interface EmailTemplate {
  id: number
  event_id: number
  name: string
  type_id: EmailTypes
  certificates: number[]
  subject: string
  body: string
  blocks_data: any
  is_attach_ics: boolean
  is_attach_pdf_ticket: boolean
}
