<template>
  <BaseSelect
    v-model="value"
    :options="programOptions"
    name="ticket"
    class="w-full"
  />
</template>

<script setup lang="ts">
import { useProgram } from '~/stores/program'
import { useEvents } from '~/stores/events'

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const eventsStore = useEvents()
const programStore = useProgram()

const programOptions = computed(() => {
  return programStore.programList.map((program) => ({
    label: program.title,
    value: program.id,
  }))
})

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})

onMounted(async () => {
  if (eventsStore.event) {
    await programStore.fetchProgramList(eventsStore.event.id)
  }
})
</script>
