export const getFacebookLink = (url: string) => {
  url = encodeURIComponent(url)
  return `https://www.facebook.com/sharer.php?u=${url}`
}
export const getTwitterLink = (url: string) => {
  url = encodeURIComponent(url)
  return `https://twitter.com/share?url=${url}`
}
export const getLinkedinLink = (url: string) => {
  url = encodeURIComponent(url)
  return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
}

export const getTelegramLink = (url: string, text: string) => {
  url = encodeURIComponent(url)
  text = encodeURIComponent(text)
  return `https://t.me/share/url?url=${url}&text=${text}`
}

export const getWhatsappLink = (url: string, title: string) => {
  const text = encodeURIComponent(`${title}\n${url}`)
  return `https://wa.me/?text=${text}`
}
