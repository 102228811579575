export default {
  banners: {
    items: {
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
      textColor: {
        'ui:widget': 'BaseColorWidget',
      },
      linkColor: {
        'ui:widget': 'BaseColorWidget',
      },
      bgImage: {
        'ui:widget': 'EditorUploadWidget',
      },
      bgColor: {
        'ui:widget': 'BaseColorWidget',
      },
      buttons: {
        items: {
          color: {
            'ui:widget': 'BaseColorWidget',
          },
          textColor: {
            'ui:widget': 'BaseColorWidget',
          },
        },
      },
    },
  },
}
