import { setLocale, localize } from '@vee-validate/i18n'
import { configure, defineRule } from 'vee-validate'
import * as allRules from '@vee-validate/rules'
import { isYouTubeUrl } from '~/utils/url'
import enLocale from '~/locales/en.json'
import ruLocale from '~/locales/ru.json'
import zhLocale from '~/locales/zh.json'

export default defineNuxtPlugin((nuxtApp) => {
  const { locale } = nuxtApp.$i18n as any

  Object.keys(allRules).forEach((rule) => {
    // TODO: report to the Nuxt team, prod bundle fix, same as for Sentry
    // @ts-expect-error we can iterate this
    // eslint-disable-next-line import/namespace
    if (typeof allRules[rule] !== 'function') {
      return
    }
    // @ts-expect-error we can iterate this
    // eslint-disable-next-line import/namespace
    defineRule(rule, allRules[rule])
  })

  const urlValidator = (value?: string) => {
    return !value ||
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
        value
      )
      ? true
      : 'This field should contain a URL'
  }
  const notLinkValidator = (value?: string) => {
    return !value || /^[^:/]*$/.test(value)
      ? true
      : 'This field should not contain a URL'
  }

  const notEqualValidator = (value: string, params: string[]) => {
    return !params.includes(value)
  }
  const youtubeValidator = (value: string) => {
    return !value || isYouTubeUrl(value)
  }

  const nameValidator = (value: string) => {
    return (
      !value ||
      !!value.match(/^([\p{sc=Han}a-zа-яё'-]+\s?)*[\p{sc=Han}a-zа-яё'-]$/iu)
    )
  }

  defineRule('not_equal', notEqualValidator)
  defineRule('youtube', youtubeValidator)
  defineRule('name', nameValidator)
  defineRule('url', urlValidator)
  defineRule('notUrl', notLinkValidator)
  defineRule('max_date', (value: any) => {
    if (!value || !value.length) {
      return true
    }
    const maxValue = '9999-12-31'

    return new Date(maxValue).getTime() >= new Date(value).getTime()
  })

  configure({
    generateMessage: localize({
      en: {
        messages: enLocale.validation,
      },
      ru: {
        messages: ruLocale.validation,
      },
      zh: {
        messages: zhLocale.validation,
      },
    }),
  })

  setLocale(locale.value || 'en')
})
