import { markRaw } from 'vue'
import { DEFAULT_COLOR_GRAY } from '../constants'
import TitleView from './TitleView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(TitleView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    text: '# H1 title',
    textColor: DEFAULT_COLOR_GRAY,
  },
}
