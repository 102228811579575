export default {
  image: {
    'ui:widget': 'EditorUploadWidget',
  },
  tabs: {
    items: {
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
    },
  },
}
