<template>
  <div
    class="bg-no-repeat bg-center relative"
    :class="[proxySize]"
    :style="{
      paddingBottom: percent + '%',
      backgroundImage: backgroundImage,
    }"
  >
    <div
      v-if="$slots.default"
      class="absolute inset-0 flex items-center justify-center"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const sizes = {
  contain: 'bg-contain',
  cover: 'bg-cover',
  none: '',
} as { [key: string]: string }

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  ratio: {
    type: Number,
    default: 1,
  },
  size: {
    type: String,
    default: 'contain',
  },
})

const percent = computed(() => props.ratio * 100)
const backgroundImage = computed(() =>
  props.src ? `url('${props.src}')` : undefined
)
const proxySize = computed(() => sizes[props.size] || '')
</script>
