import { defineStore } from 'pinia'
import type { EmailTemplate } from '~/models/emails'
import { EmailTypes } from '~/models/emails'

export const useEmails = defineStore('emails', () => {
  const baseURL = useRuntimeConfig().public.gatewayAdminApi
  async function getEmailsAutocomplete(
    eventId: number,
    query: string,
    typefilter?: EmailTypes[]
  ) {
    const { data: emailsList } = await useAuthFetch<{ data: EmailTemplate[] }>(
      `/events/${eventId}/emails`,
      {
        baseURL,
        params: {
          'filter[query]': query || undefined,
        },
      }
    )

    const emailsListFiltered = typefilter
      ? emailsList.filter((email) => typefilter.includes(email.type_id))
      : emailsList

    return emailsListFiltered.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }

  async function getEmailByEvent(
    eventId: number,
    id: number
  ): Promise<EmailTemplate> {
    const { data } = await useAuthFetch<{ data: EmailTemplate }>(
      `/events/${eventId}/emails/${id}`,
      {
        baseURL,
      }
    )

    return data
  }
  return {
    getEmailsAutocomplete,
    getEmailByEvent,
  }
})
