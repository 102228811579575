<template>
  <div
    class="relative"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <iframe
      :id="uuid"
      class="w-full bg-white rounded-xl"
      allow="fullscreen; display-capture; microphone; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; autoplay; camera; accelerometer; vr; screen-wake-lock"
      allowfullscreen
      :src="formData.url"
      :style="{ height: (messagedHeight || formData.height) + 'px' }"
      @load="loaded = true"
    />
    <div
      v-if="pageEditorStore.isEditMode"
      class="w-full h-full absolute inset-0 bg-transparent"
    ></div>
    <div
      v-if="formData.preloader && !loaded"
      class="w-full h-full absolute inset-0 bg-white flex items-center justify-center rounded-xl"
    >
      <BaseSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

const messagedHeight = ref(0)
const loaded = ref(false)

interface FormDataInterface {
  url?: string
  height?: number
  preloader?: boolean
}

interface PropsInterface {
  formData: FormDataInterface
  uuid: string
}

defineProps<PropsInterface>()

function resizeHandler(event: any) {
  if (event.data?.event === 'update-block-height') {
    console.log('got update-block-height message', event.data)
    messagedHeight.value = event.data?.height
  }
}

onMounted(() => {
  window.addEventListener('message', resizeHandler)
})

onUnmounted(() => {
  window.removeEventListener('message', resizeHandler)
})
</script>
