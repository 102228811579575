export const useHost = () => {
  if (process.server) {
    const headers = useRequestHeaders()
    const host = headers.origin || headers.host
    const isProd =
      process.env.NODE_ENV === 'production' && !host.startsWith('localhost')

    return `http${isProd ? 's' : ''}://${host}`
  } else {
    if (useDetect().isApp) {
      return 'https://wnhub.io'
    }
    return window.location.origin
  }
}
