import { defineStore } from 'pinia'
import { EntityType } from '~/models/common'
import type { Favorite } from '~/models/favorites'

export const useFavorites = defineStore('favorites', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  const initFavoritesList = () => {
    const list = useInfinityList<Favorite>('/favorites', baseURL)

    list.state.params = {
      per_page: 5,
    }

    return list
  }

  const list = initFavoritesList()

  async function addToFavorites(
    entityId: number,
    entityType: EntityType,
    eventId?: number,
    lang?: string
  ) {
    const { data } = await useAuthFetch<{ data: Favorite }>('/favorites', {
      baseURL,
      method: 'POST',
      body: {
        entity_id: entityId,
        entity_type: entityType,
        ...(lang && { lang }),
        ...(eventId && { event_id: eventId }),
      },
    })

    return data
  }

  async function removeFromFavoritesById(id: number) {
    await useAuthFetch<void>(`/favorites/${id}`, {
      baseURL,
      method: 'DELETE',
    })
  }

  async function removeFromFavorites(entityId: number, entityType: EntityType) {
    await useAuthFetch<void>(`/favorites/${entityType}/${entityId}`, {
      baseURL,
      method: 'DELETE',
    })
  }

  async function isFavorite(entityId: number, entityType: EntityType) {
    const { data } = await useAuthFetch<{ data: Favorite }>(
      `/favorites/${entityType}/${entityId}`,
      {
        baseURL,
      }
    )

    return data
  }

  return {
    isFavorite,
    addToFavorites,
    removeFromFavorites,
    removeFromFavoritesById,
    list: list.state,
    allRecordsLoaded: list.allRecordsLoaded,
    fetch: list.fetch,
    getPage: list.getPage,
    nextPage: list.nextPage,
    reset: list.reset,
  }
})
