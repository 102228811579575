import { markRaw } from 'vue'
import View from './QRCodeView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'Current ticket',
    upgradeButtonText: 'Upgrade ticket',
  },
}
