<template>
  <BaseModal
    :title="$t('common.share')"
    :is-open="isOpen"
    size="sm"
    @close="close"
  >
    <div class="flex flex-col gap-6">
      <div class="flex gap-2 justify-between">
        <template v-for="shareItem in shareOptions" :key="shareItem.icon">
          <NuxtLink class="block" :href="shareItem.link" target="_blank">
            <BaseIcon :name="shareItem.icon" class="h-12 w-12" size="none" />
          </NuxtLink>
        </template>
      </div>
      <div class="flex gap-4 max-w-full">
        <div
          class="border border-gray-400 text-xs rounded-xl flex items-center px-4 flex-shrink overflow-hidden flex-grow"
        >
          <span class="line-clamp-1 whitespace-nowrap text-gray-400">
            {{ link }}
          </span>
        </div>
        <BaseButton
          theme="primary"
          look="solid"
          class="text-base flex-shrink-0"
          @click="() => copyLink(link)"
        >
          {{ $t('common.copy_link') }}
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
interface OptionInterface {
  icon: string
  link: any
}

interface PropsInterface {
  shareOptions: OptionInterface[]
  link: string
  isOpen: boolean
}

withDefaults(defineProps<PropsInterface>(), {
  isOpen: false,
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}

const { copyLink } = useClipboardCopy()
</script>
