import type { CatalogItem } from '~/models/common'
import type { CompanyLite } from '~/models/companies'
import type { Media } from '~/models/media'
import type { Transforms } from '~/utils/transform'
import { execTransforms } from '~/utils/transform'

export interface GameLink {
  url: string
  name: string
}

export interface GameLite {
  id: number
  name: string
  dev_start_at: string | null
  release_at: string | null
  company: CompanyLite
  icon: Media | null
  genres: CatalogItem[]
  platforms: CatalogItem[]
  engines: CatalogItem[]
  looking_for: CatalogItem[]
}

export interface Game extends GameLite {
  looking_for_partner: boolean
  stage: CatalogItem | null
  monetizations: CatalogItem[]
  budget: CatalogItem | null
  description: string | null
  pitch_deck_link: string | null
  game_build_link: string | null
  video: string | null
  cover: Media | null
  photos: Media[]
  links: GameLink[]
  is_gamefinder_featured: boolean
}

export interface GameCreate {
  name: string
  dev_start_at?: string | null
  release_at?: string | null
  genres: number[]
  platforms: number[]
  engines?: number[]
  looking_for?: number[]
  stages?: number[]
  monetizations?: number[]
  budget: number
  description?: string | null
  pitch_deck_link?: string | null
  game_build_link?: string | null
  video: string
  links: GameLink[]
  is_gamefinder_featured: boolean
}

export interface GameUpdate extends Partial<GameCreate> {}

const transforms: Transforms<any> = {
  stage: (data) => data.stages?.[0] || null,
  stages: () => undefined,
}

export function transformGame(data: any): Game {
  return execTransforms<Game>(transforms, data)
}
