import { markRaw } from 'vue'
import View from './TilesView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    tiles: [
      {
        title: 'Events',
        imageUrl: 'https://cdn.wnhub.io/42034/664232f61a7541.80228913.svg',
        link: '/event-info',
      },
      {
        title: 'Academy',
        imageUrl: 'https://cdn.wnhub.io/42109/6644adde7f4d73.43245486.svg',
        link: '/academy',
      },
      {
        title: 'Game Finder',
        imageUrl: 'https://cdn.wnhub.io/42114/6644aff1430944.04515706.svg',
        link: '/event-info/gamefinder',
      },
      {
        title: 'News',
        imageUrl: 'https://cdn.wnhub.io/42037/6642331f3a6984.16686262.svg',
        link: '/news',
      },
      {
        title: 'Video',
        imageUrl: 'https://cdn.wnhub.io/42111/6644ae2e9dee51.83672618.svg',
        link: '/video',
      },
      {
        title: 'Jobs',
        imageUrl: 'https://cdn.wnhub.io/42035/6642330410b662.91855760.svg',
        link: '/talents',
      },
    ],
  },
}
