import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_COLOR_PRIMARY,
  DEFAULT_COLOR_PRIMARY_HOVER,
  DEFAULT_COLOR_WHITE,
  DEFAULT_LINK,
  DEFAULT_TEXT,
} from './constants'

export interface ButtonInterface {
  type: 'button' | 'link'
  look: 'solid' | 'border'
  color: string
  colorHover?: string
  textColor?: string
  textColorHover?: string
  url: string
  text: string
  isExternalLink?: boolean
  blockHash?: string
  isBlank?: boolean
  ignoreCurrentLocale?: boolean
}

const PaddingsList = ['none', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const

export type PaddingSize = (typeof PaddingsList)[number]

export const getPaddingClass = (size?: PaddingSize) => {
  switch (size) {
    case 'none':
      return ''
    case 'xxs':
      return 'py-2 md:py-4'
    case 'xs':
      return 'py-6 md:py-8'
    case 'sm':
      return 'py-14 md:py-20'
    case 'md':
      return 'py-20 md:py-40'
    case 'lg':
      return 'py-24 md:py-48'
    case 'xl':
      return 'py-32 md:py-56'
    default:
      return 'py-6 md:py-8'
  }
}

export const isBlankSchema = {
  isBlank: {
    title: 'Open link in new window',
    description:
      'Only works for absolute links that start with "http..." and editor blocks (hash)',
    type: 'boolean',
    default: false,
  },
}

export const getButtonProperties = (
  defaultProperties?: Partial<ButtonInterface>
) => {
  return {
    type: {
      title: 'Button style',
      enum: ['button', 'link'],
      enumNames: ['Button', 'Link'],
      default: defaultProperties?.type || 'button',
      'ui:widget': 'RadioWidget',
    },
    look: {
      title: 'Button look',
      enum: ['solid', 'border'],
      enumNames: ['Solid', 'Border'],
      default: defaultProperties?.look || 'solid',
      'ui:widget': 'RadioWidget',
      'ui:hidden': '{{parentFormData.type === "link"}}',
    },
    color: {
      title: 'Color',
      type: 'string',
      default: defaultProperties?.color || DEFAULT_COLOR_PRIMARY,
      'ui:widget': 'BaseColorWidget',
    },
    colorHover: {
      title: 'Color on hover',
      type: 'string',
      default: defaultProperties?.colorHover || DEFAULT_COLOR_PRIMARY_HOVER,
      'ui:widget': 'BaseColorWidget',
    },
    textColor: {
      title: 'Text color if button has background',
      type: 'string',
      default: defaultProperties?.textColor || '#ffffff',
      'ui:hidden':
        '{{parentFormData.type === "link" || parentFormData.look !== "solid"}}',
      'ui:widget': 'BaseColorWidget',
    },
    text: {
      title: 'Button text',
      type: 'string',
      default: defaultProperties?.text || DEFAULT_TEXT,
    },
    isExternalLink: {
      title: 'Is link',
      type: 'boolean',
      default: defaultProperties?.isExternalLink ?? true,
    },
    blockHash: {
      title: 'Editor block (hash)',
      type: 'string',
      description:
        'To make it easier to find the block here, first fill in the field "In-page block id"',
      default: defaultProperties?.blockHash || undefined,
      'ui:hidden': '{{ parentFormData.isExternalLink === true }}',
      'ui:widget': 'EditorBlockListWidget',
    },
    url: {
      title: 'Link',
      type: 'string',
      default: defaultProperties?.url || DEFAULT_LINK,
      'ui:hidden': '{{ parentFormData.isExternalLink === false }}',
    },
    ignoreCurrentLocale: {
      title: 'Allow to change language on navigation',
      description:
        'This only works for relative links that start with "/". By default, links like "/news" will navigate to language-specific paths such as "/zh/news" or "/ru/news", based on the current language.',
      type: 'boolean',
      default: false,
      'ui:hidden': '{{ parentFormData.isExternalLink === false }}',
    },
    ...isBlankSchema,
  }
}

export const buttonsPropertiesSchema = {
  buttons: {
    type: 'array',
    title: 'A list of buttons',
    'ui:options': {
      showIndexNumber: true,
    },
    items: {
      type: 'object',
      properties: getButtonProperties(),
    },
  },
}

export const textAlignSchemaField = {
  title: 'Text align',
  type: 'string',
  enum: ['start', 'center', 'end'],
  enumNames: ['Left', 'Center', 'Right'],
  default: 'start',
}

export const titlePropertiesSchema = {
  title: {
    title: 'Title',
    type: 'string',
    default: undefined,
  },
  titleAlign: {
    ...textAlignSchemaField,
    title: 'Title align',
    'ui:widget': 'RadioWidget',
  },
  hideOnEmbed: {
    title: 'Hide title when embedding via Iframe',
    type: 'boolean',
    default: false,
  },
}

export const titleSizeMap = {
  xl: 'text-xl',
  xl2: 'text-2xl',
  xl3: 'text-2xl md:text-3xl',
} as { [key: string]: string }

export const titleSizeSchema = {
  titleSize: {
    title: 'Title size',
    type: 'string',
    enum: ['xl', 'xl2', 'xl3'],
    enumNames: ['XL', '2XL', '3XL'],
    default: 'xl3',
  },
}

export const shadowSchema = {
  blockShadow: {
    title: 'Display block shadow',
    type: 'boolean',
    default: false,
  },
}

export const paddingsSchema = {
  paddingSize: {
    title: 'Block vertical paddings',
    enum: PaddingsList,
    enumNames: PaddingsList,
    default: 'xs',
    'ui:widget': 'RadioWidget',
  },
}

export const bgColorSchema = {
  bgColor: {
    title: 'Block background color',
    type: 'string',
    default: DEFAULT_COLOR_WHITE,
    'ui:widget': 'BaseColorWidget',
  },
}

export const linkColorSchema = {
  linkColor: {
    title: 'Links color',
    type: 'string',
    default: DEFAULT_COLOR_PRIMARY,
    'ui:widget': 'BaseColorWidget',
  },
}

export const textColorSchema = {
  textColor: {
    title: 'Text color',
    type: 'string',
    default: DEFAULT_COLOR_GRAY,
    'ui:widget': 'BaseColorWidget',
  },
}

export const showAllSettingsSchema = {
  tmp_showAllSettings: {
    title: 'Show all settings',
    type: 'string',
    'ui:widget': 'EditorTextCheckboxWidget',
  },
}

export const colsNumberOnDesktopSchema = {
  colsNumberOnDesktop: {
    title: 'Number of cols on desktop',
    type: 'number',
    default: 4,
    minimum: 1,
    maximum: 6,
  },
}

export const colsNumberOnMobileSchema = {
  colsNumberOnMobile: {
    title: 'Number of cols on mobile',
    type: 'number',
    default: 2,
    minimum: 1,
    maximum: 6,
  },
}

export const isUseSliderOnMobileSchema = {
  isUseSliderOnMobile: {
    title: 'Use slider on mobile',
    type: 'boolean',
    default: true,
  },
}
