import type { CookieOptions } from '#app'
import { Preferences } from '@capacitor/preferences'

const { isApp } = useDetect()
const isNativeApp = process.client && isApp

export enum AppCookieKeys {
  GatewayAuth = 'gateway-auth',
  Lang = 'lang',
  ECommerceSentOrders = 'ecommerce-sent-orders',
}

// we crucially rely on auth cookie in storages, but defineStore can't be created in async manner,
// async wrappers aroud defineStore maybe be possible but have another issues
// so we run async load method (in the global middleware) before define of auth store
// in all cases except native app we should get the same CookieRef as before
// lang cookie uses in async ready setups so we can directly use async load to get ref

export function useAppCookie<T>(
  key: AppCookieKeys,
  params?: CookieOptions<T> & { readonly?: false | undefined }
) {
  const cookie = isNativeApp
    ? useState<T>(`${key}-cookie`)
    : useCookie<T>(key, params)

  async function load() {
    if (!isNativeApp || cookie.value !== undefined) {
      return cookie
    }

    try {
      const { value } = await Preferences.get({
        key,
      })

      cookie.value = value ? JSON.parse(value) : null

      return cookie
    } catch (error: any) {
      useLogError(error)
    }
  }

  function get() {
    if (cookie.value === undefined && isNativeApp) {
      throw new Error(`cookie ${key} using before loading`)
    }
    return cookie
  }

  if (isNativeApp) {
    watch(cookie, (value: any) => {
      Preferences.set({ key, value: JSON.stringify(value) })
    })
  }

  return {
    cookie,
    load,
    get,
  }
}
