import type { RouterConfig } from '@nuxt/schema'

// !it is not very nice solution within SSR, so it should be used only on client side
let resizeObserver: ResizeObserver | null = null

function resetScrollHandler() {
  resizeObserver?.disconnect()
  resizeObserver = null
}
// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior(to, _, savedPosition) {
    resetScrollHandler()
    if (to.query.scroll !== undefined) {
      return
    }

    // Scroll to heading on click
    if (to.hash) {
      setTimeout(() => {
        try {
          const heading = document.querySelector(to.hash) as HTMLElement

          if (!heading) {
            return
          }

          return heading.scrollIntoView({ behavior: 'smooth' })
        } catch (error: any) {
          console.log('invalid hash', to.hash)
        }
      })
      return
    }

    if (savedPosition && process.client) {
      return new Promise((resolve) => {
        resizeObserver = new ResizeObserver((entries) => {
          if (
            entries[0].target.clientHeight >=
            savedPosition.top + window.innerHeight
          ) {
            resetScrollHandler()
            resolve(savedPosition)
          } else {
            // trigger for nex page loading
            window.scrollTo({
              left: 0,
              top: document.body.scrollHeight,
            })
          }
        })

        const container = document.querySelector('body')

        if (container) {
          resizeObserver.observe(container)
        } else {
          resolve(savedPosition)
        }
      })
    }

    return { top: 0 }
  },
}
