import type { ListMeta } from '~/models/list'

export type InfinityListParams = Record<
  string,
  string | number | string[] | number[] | undefined
>

export interface InfinityList<T> {
  endpoint: string
  baseURL: string
  hits: T[]
  data: T[]
  meta: null | ListMeta
  loaded: boolean
  processing: boolean
  params: InfinityListParams
}

interface ListResponse<T> {
  data: T[]
  meta: ListMeta
}

export default function <T>(
  endpoint: string,
  baseURL: string,
  prefetchCallback?: (params: InfinityList<T>['params']) => void,
  filterCallback?: (data: T[]) => T[],
  headersCallback?: () => { [key: string]: string }
) {
  const state = reactive<InfinityList<T>>({
    endpoint,
    baseURL,
    hits: [],
    data: [],
    processing: false,
    loaded: false,
    meta: null,
    params: {
      page: 1,
    },
  })

  let currentRequestId: symbol

  const allRecordsLoaded = computed(
    () => state.meta?.current_page === state.meta?.last_page
  )

  async function nextPage() {
    state.params.page = (state.meta?.current_page || 0) + 1

    if (await fetch()) {
      state.hits = [...state.hits, ...state.data]
    }
  }

  async function prevPage(reverse = true) {
    if (state.meta && state.meta.current_page > 1) {
      state.params.page = state.meta.current_page - 1
      await fetch()
      state.hits = reverse
        ? [...state.hits, ...state.data.reverse()]
        : [...state.data, ...state.hits]
    }
  }

  async function getLastPage(reverse = true) {
    if (state.loaded) {
      return
    }
    state.params.page = undefined
    await fetch()
    state.params.page = state.meta?.current_page
    state.hits = reverse ? [...state.data].reverse() : [...state.data]
  }

  async function getPage(page?: number) {
    if (state.loaded && !page) {
      return
    }
    state.params.page = page || 1

    if (await fetch()) {
      state.hits = [...state.data]
    }
  }

  async function fetch(): Promise<boolean> {
    prefetchCallback && prefetchCallback(state.params)

    const localRequestId = Symbol('requestId')
    currentRequestId = localRequestId

    state.processing = true

    const { data, meta } = await useAuthFetch<ListResponse<T>>(state.endpoint, {
      ...(headersCallback && { headers: headersCallback() }),
      baseURL: state.baseURL,
      params: state.params,
    })

    // for requests spam, use last request state and data
    // TODO: implement using AbortController
    if (localRequestId !== currentRequestId) {
      return false
    }

    // @ts-ignore-next-line it should be fine, but would be nice to fixe typing here
    state.data =
      typeof filterCallback === 'function' ? filterCallback(data) : data
    state.meta = meta
    state.processing = false
    state.loaded = true

    return true
  }

  return {
    state,
    fetch,
    reset: () => {
      state.hits = []
      state.data = []
      state.meta = null
      state.loaded = false
      state.params.page = 1
    },
    getPage,
    getLastPage,
    nextPage,
    prevPage,
    allRecordsLoaded,
  }
}
