<template>
  <div
    class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-2 gap-x-4 md:gap-x-2 md:gap-y-0 lg:gap-y-4 xl:gap-y-6"
  >
    <template v-for="sponsor in sponsors" :key="sponsor.id">
      <template v-if="sponsor?.program_company?.media?.length > 0">
        <div class="flex flex-wrap items-center">
          <div class="w-full md:w-1/2 lg:w-2/5 p-6 sm:p-8 md:p-6 lg:p-3">
            <div class="relative">
              <div
                class="absolute rounded-lg inset-0 bg-white"
                :style="{
                  transform: 'scale(1.1) rotate(-7deg)',
                  background:
                    'linear-gradient(247deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.15))',
                  filter: 'drop-shadow(0px 0 30px rgba(0, 0, 0, 0.3))',
                }"
              />
              <div
                class="absolute rounded-lg inset-0 bg-white"
                :style="{
                  transform: 'scale(1.05) rotate(-170deg)',
                  background:
                    'linear-gradient(247deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))',
                }"
              />
              <div
                class="block relative w-full overflow-hidden bg-white rounded-lg"
                :class="[
                  (sponsor.program_company.href_id ||
                    sponsor.program_company.href_link) &&
                    'cursor-pointer',
                ]"
                :style="{
                  paddingTop: '72%',
                }"
                @click="
                  emit(
                    'open-entity',
                    'company',
                    sponsor.program_company.href_type,
                    sponsor.program_company.href_id,
                    sponsor.program_company.href_link
                  )
                "
              >
                <div
                  class="absolute inset-0 p-3.5 flex items-center justify-center"
                >
                  <img
                    :src="sponsor.program_company.media[0].url"
                    alt="sponsor image"
                    class="max-h-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full md:w-1/2 lg:w-3/5 font-medium text-sm whitespace-pre-line mb-3 md:mb-0 p-2.5"
          >
            <span class="capitalize">
              {{
                sponsor.section_type_id == ProgramSectionTypes.None
                  ? streamTitle + ' '
                  : ProgramSectionTypesLabelsMap[sponsor.section_type_id] + ' '
              }}
            </span>
            <br />
            Sponsored by {{ sponsor.program_company.name }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ProgramSponsor } from '~/models/program'
import { ProgramSectionTypes } from '~/models/program'
import { useProgram } from '~/stores/program'

interface PropsInterface {
  sponsors: ProgramSponsor[]
  streamTitle: string
  isOnHub: boolean
}

withDefaults(defineProps<PropsInterface>(), {
  streamTitle: '',
})

const emit = defineEmits(['open-entity'])

const { ProgramSectionTypesLabelsMap } = useProgram()
</script>
