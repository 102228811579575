import { markRaw } from 'vue'
import IFrameView from './IFrameView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(IFrameView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    url: 'https://en.wikipedia.org/wiki/Internet',
    height: 500,
    preloader: false,
  },
}
