import { defineStore } from 'pinia'
import { EntityType } from '~/models/common'
import { ReactionType } from '~/models/counters'

import type {
  CounterCommentsResponseType,
  CounterReactionsResponseType,
  CounterRequestType,
} from '~/models/counters'

export const useCounters = defineStore('counters', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  const getParams = (entity: CounterRequestType): Record<string, any> => ({
    [`entities[0][entity_id]`]: entity.entity_id.toString(),
    [`entities[0][entity_type]`]: entity.entity_type,
  })

  const fetchCommentsCounters = async (
    entity: CounterRequestType
  ): Promise<CounterCommentsResponseType | null> => {
    const queryParams = getParams(entity)

    const { data } = await useKrakenFetch<{
      data: CounterCommentsResponseType[]
    }>(`/comments/counter`, {
      baseURL,
      params: queryParams,
    })

    return data.length ? data[0] : null
  }

  const fetchReactionsCounters = async (
    entity: CounterRequestType,
    isAuth: boolean
  ): Promise<CounterReactionsResponseType[]> => {
    const queryParams = getParams(entity)

    if (isAuth) {
      const { data } = await useAuthFetch<{
        data: CounterReactionsResponseType[]
      }>('/reactions/me', {
        baseURL,
        params: queryParams,
      })

      return data
    } else {
      const { data } = await useKrakenFetch<{
        data: CounterReactionsResponseType[]
      }>('/reactions', {
        baseURL,
        params: queryParams,
      })

      return data
    }
  }

  const sendReaction = async (
    entityId: number,
    entityType: EntityType,
    reactionType: ReactionType
  ) => {
    await useAuthFetch<{
      data: CounterReactionsResponseType[]
    }>('/reactions', {
      baseURL,
      method: 'POST',
      body: {
        entity_id: entityId,
        entity_type: entityType,
        reaction: reactionType,
      },
    })
  }

  const deleteReaction = async (entityId: number, entityType: EntityType) => {
    await useAuthFetch<{
      data: CounterReactionsResponseType[]
    }>('/reactions', {
      baseURL,
      method: 'DELETE',
      body: {
        entity_id: entityId,
        entity_type: entityType,
      },
    })
  }

  return {
    fetchCommentsCounters,
    fetchReactionsCounters,
    sendReaction,
    deleteReaction,
  }
})
