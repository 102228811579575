<template>
  <svg
    width="639"
    height="270"
    viewBox="0 0 639 270"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M408.24 177.441C407.723 177.549 398.063 177.063 397.57 177.22C397.547 177.228 397.526 177.234 397.503 177.242C396.977 177.411 396.495 177.562 396.042 177.649C395.585 177.737 395.219 177.745 394.926 177.675L394.925 177.675C393.38 177.301 391.977 176.428 390.446 175.475L390.428 175.465C389.056 174.609 387.578 173.689 385.936 173.229C382.651 164.963 381.386 156.086 382.269 147.494C382.88 141.915 384.407 136.574 386.785 131.718C389.464 126.464 392.679 121.578 396.375 117.141L396.383 117.13C397.962 115.155 399.597 113.202 401.24 111.24C407.216 104.102 413.297 96.8381 417.167 87.8925L417.167 87.8904C421.924 76.8499 423.464 64.2862 421.599 51.733L421.597 51.7209C421.087 48.5838 420.42 45.466 419.601 42.379L419.597 42.3622C418.715 39.2696 418.16 36.1091 417.944 32.9414C417.759 27.493 419.642 22.1206 424.354 20.4028C428.445 18.9109 433.313 20.2964 436.992 23.3288C441.385 26.9494 444.325 32.7284 446.223 38.2794C447.135 40.9399 447.749 43.4117 448.232 46.165L448.241 46.2149L448.254 46.263C449.5 50.5349 450.343 54.8961 450.776 59.2788C451.747 69.399 450.462 79.4239 447.031 88.5112C442.982 99.2756 436.252 108.314 429.447 117.448C428.963 118.099 428.477 118.75 427.993 119.403C420.905 128.95 413.949 138.732 410.34 150.712C407.196 161.494 405.723 166.077 408.242 177.441L408.24 177.441Z"
      fill="white"
      stroke="#392040"
      stroke-width="0.999605"
      stroke-miterlimit="22.9256"
    />
    <path
      d="M286.973 65.9723C285.829 66.7031 284.994 67.8356 284.631 69.1492C284.218 67.3406 283.288 65.6958 281.955 64.4185C281.329 63.8418 280.562 63.4428 279.732 63.262C278.902 63.0812 278.038 63.1252 277.229 63.3893C276.429 63.6738 275.696 64.1224 275.077 64.707C274.457 65.2915 273.965 65.9992 273.631 66.7847C273.43 67.2581 273.28 67.751 273.18 68.2555C272.956 67.7832 272.684 67.3356 272.369 66.9194C271.996 66.3993 271.52 65.9628 270.972 65.6373C270.423 65.3119 269.813 65.1045 269.179 65.0281C268.546 64.9517 267.904 65.008 267.292 65.1934C266.68 65.3788 266.113 65.6893 265.625 66.1055C263.65 67.7078 262.884 70.4453 262.729 72.8905C262.582 75.7401 263.239 78.5701 264.625 81.052C264.86 81.4792 265.128 81.8872 265.405 82.2974C264.418 81.6576 263.279 81.2927 262.104 81.2395C260.942 81.2402 259.814 81.6387 258.903 82.3695C257.992 83.1003 257.353 84.1202 257.091 85.2621C256.84 86.4864 256.868 87.7511 257.173 88.9606C257.478 90.17 258.053 91.2924 258.853 92.2429C260.049 93.817 261.671 95.0079 263.526 95.6725C265.38 96.3372 267.388 96.4473 269.308 95.9896L269.296 96.3534C268.819 112.736 262.685 127.923 255.521 142.452C252.868 147.833 249.914 153.072 246.797 158.204C246.745 158.3 246.732 158.413 246.761 158.518C246.79 158.623 246.859 158.713 246.953 158.767C254.226 162.064 261.649 165.017 269.22 167.626C271.384 168.378 273.561 169.092 275.75 169.77C275.837 169.792 275.929 169.785 276.012 169.75C276.094 169.715 276.164 169.654 276.209 169.576C278.971 163.925 281.419 158.124 283.54 152.201C290.094 135.146 293.231 116.965 292.769 98.7094C292.699 94.2841 292.252 89.8747 291.434 85.5295C293.882 82.3205 295.246 78.4084 295.325 74.3694C295.417 71.8625 294.656 69.4028 293.17 67.3976C291.695 65.598 289.045 64.6977 286.973 65.9723Z"
      fill="white"
    />
    <path
      d="M276.922 167.629L283.54 152.201C290.094 135.146 293.231 116.965 292.769 98.7094C292.699 94.2842 292.252 89.8748 291.434 85.5296C293.882 82.3206 295.246 78.4084 295.325 74.3694C295.417 71.8625 294.656 69.4028 293.17 67.3976C291.695 65.5981 289.045 64.6978 286.973 65.9724C285.829 66.7032 284.994 67.8357 284.631 69.1492C284.218 67.3406 283.288 65.6958 281.955 64.4186C281.329 63.8418 280.562 63.4429 279.732 63.2621C278.902 63.0813 278.038 63.1253 277.229 63.3893C276.429 63.6738 275.696 64.1225 275.077 64.707C274.457 65.2916 273.965 65.9992 273.631 66.7847C273.43 67.2582 273.28 67.7511 273.18 68.2555C272.956 67.7833 272.684 67.3356 272.369 66.9195C271.996 66.3993 271.52 65.9628 270.971 65.6374C270.423 65.3119 269.812 65.1045 269.179 65.0281C268.546 64.9517 267.904 65.008 267.292 65.1934C266.68 65.3788 266.113 65.6894 265.625 66.1056C263.65 67.7078 262.884 70.4454 262.729 72.8906C262.582 75.7402 263.239 78.5701 264.625 81.052C264.86 81.4793 265.128 81.8872 265.405 82.2975C264.418 81.6577 263.279 81.2927 262.104 81.2396C260.942 81.2402 259.814 81.6387 258.903 82.3696C257.992 83.1004 257.353 84.1203 257.091 85.2621C256.84 86.4865 256.868 87.7512 257.173 88.9606C257.478 90.17 258.053 91.2924 258.853 92.243C260.049 93.817 261.671 95.008 263.526 95.6726C265.38 96.3372 267.388 96.4474 269.308 95.9897L269.296 96.3534C268.819 112.736 262.685 127.924 255.521 142.452L249.202 153.405"
      stroke="#392040"
    />
    <path
      d="M273.013 69.0455C273.383 71.9971 273.318 74.9935 272.822 77.9355C272.662 78.8495 273.975 79.1865 274.135 78.2726C274.667 75.2094 274.745 72.0871 274.365 69.0108C274.358 68.8291 274.283 68.6578 274.156 68.5313C274.03 68.4049 273.86 68.3327 273.682 68.3291C273.592 68.3314 273.504 68.3518 273.421 68.3891C273.339 68.4265 273.265 68.4801 273.202 68.5467C273.14 68.6134 273.091 68.6917 273.059 68.7773C273.026 68.863 273.011 68.9541 273.013 69.0455Z"
      fill="#392040"
    />
    <path
      d="M284.372 70.8141C284.492 71.337 284.512 71.8841 284.432 72.4234C284.385 73.1045 284.339 73.7854 284.268 74.4688C284.145 75.7985 283.859 77.1117 283.418 78.3741C283.376 78.5358 283.396 78.706 283.472 78.8474C283.548 78.9889 283.675 79.0899 283.825 79.1283C283.974 79.1667 284.134 79.1392 284.269 79.052C284.404 78.9648 284.503 78.8251 284.545 78.6634C285.011 77.29 285.31 75.864 285.433 74.4215C285.509 73.7219 285.563 73.0083 285.609 72.301C285.693 71.6787 285.655 71.0493 285.498 70.4534C285.257 69.7072 284.115 70.0985 284.372 70.8141Z"
      fill="#392040"
    />
    <path
      d="M265.863 83.7233C267.284 85.3222 268.32 87.1501 268.9 89.0833C268.954 89.2349 269.072 89.3655 269.229 89.4462C269.386 89.5269 269.568 89.5512 269.737 89.5138C269.905 89.4764 270.044 89.3803 270.125 89.2466C270.206 89.113 270.221 88.9526 270.168 88.801C269.556 86.7832 268.481 84.8731 267.01 83.1943C266.911 83.0622 266.762 82.9649 266.593 82.9214C266.423 82.8779 266.246 82.8914 266.096 82.9597C265.948 83.0341 265.843 83.1596 265.805 83.3087C265.766 83.4577 265.798 83.6182 265.892 83.7547L265.863 83.7233Z"
      fill="#392040"
    />
    <path
      d="M274.878 84.4808C273.392 85.5631 272.822 87.545 272.571 89.2768C272.418 90.0355 272.435 90.8181 272.622 91.566C272.809 92.3139 273.161 93.0076 273.651 93.595C274.128 94.1235 274.771 94.4681 275.472 94.5712C276.173 94.6742 276.889 94.5293 277.502 94.161C278.006 93.7586 278.412 93.245 278.691 92.6595C278.97 92.0739 279.113 91.4322 279.11 90.784C279.991 91.8184 281.194 92.6301 282.554 91.9374C283.203 91.5538 283.7 90.9521 283.959 90.2374C284.219 89.5226 284.223 88.7402 283.972 88.0268C283.217 86.2664 281.821 84.8754 280.073 84.1434C279.335 83.7858 278.526 83.603 277.705 83.6086C276.885 83.6143 276.075 83.8082 275.336 84.1759C275.175 84.2635 275.022 84.3656 274.878 84.4808Z"
      fill="#1072F1"
    />
    <path
      d="M265.622 71.1989C263.575 74.2677 266.603 78.8381 269.866 76.9822C271.605 75.987 272.115 73.3962 271.476 71.5822C270.505 68.8105 267.193 68.8424 265.622 71.1989Z"
      fill="#1072F1"
    />
    <path
      d="M276.56 70.6764C275.973 71.2865 275.628 72.0756 275.583 72.9121C275.538 73.7486 275.796 74.5821 276.314 75.2742C276.577 75.5815 276.897 75.8404 277.257 76.0372C277.926 76.5183 278.761 76.7325 279.579 76.6329C280.036 76.5753 280.471 76.4172 280.848 76.171C281.226 75.9248 281.536 75.5972 281.754 75.2138C282.222 74.5233 282.422 73.6945 282.322 72.8657C282.223 72.0369 281.828 71.2582 281.205 70.6591C280.569 70.0757 279.724 69.7498 278.853 69.7531C277.982 69.7564 277.158 70.0884 276.56 70.6764Z"
      fill="#1072F1"
    />
    <path
      d="M289.789 72.5601C289.304 72.5625 288.828 72.6728 288.399 72.8824C287.97 73.0921 287.6 73.3954 287.316 73.7687C285.612 75.9121 287.2 79.4626 290.142 79.4618C294.294 79.5043 294.66 72.7226 289.789 72.5601Z"
      fill="#1072F1"
    />
    <path
      d="M260.708 85.6192C259.11 87.7786 261.738 91.1676 264.377 89.9138C265.787 89.2496 266.137 87.3843 265.563 86.0573C265.36 85.6161 265.036 85.2327 264.626 84.9486C264.215 84.6646 263.734 84.4907 263.234 84.4456C262.734 84.4005 262.235 84.4859 261.789 84.6928C261.344 84.8996 260.97 85.22 260.708 85.6192Z"
      fill="#1072F1"
    />
    <path
      d="M193.991 173.713V173.967L194.196 174.116L210.771 186.232C221.36 215.869 251.176 244.89 305.298 252.925C384.008 264.61 411.987 208.331 403.445 179.766C399.212 165.613 389.066 148.468 379.759 135.732C375.103 129.361 370.64 124.07 367.209 120.794C365.502 119.164 364.013 117.995 362.86 117.459C362.291 117.195 361.721 117.044 361.218 117.155C360.654 117.278 360.303 117.695 360.147 118.256C359.411 120.901 358.785 123.597 358.154 126.315C354.844 140.567 351.393 155.43 331.253 166.717C316.582 174.939 288.233 176.855 257.976 158.163C233.411 142.987 217.394 141.726 207.474 146.964C197.56 152.2 193.991 163.793 193.991 173.713Z"
      fill="white"
      stroke="#392040"
    />
    <path
      d="M349.268 66.4598C350.376 67.2395 351.541 67.9324 352.755 68.5324C348.958 78.9931 345.657 91.4462 345.782 102.654C345.995 118.461 353.074 134.374 359.657 148.482C365.956 162.042 373.753 174.851 382.9 186.669C391.71 197.965 401.912 209.449 416.329 213.14C423.24 214.989 430.581 214.327 437.053 211.272C443.058 208.434 447.784 203.442 450.298 197.28C451.691 193.223 452.484 188.982 452.649 184.694C452.915 180.814 452.915 176.921 452.649 173.041C456.198 175.176 461.938 174.82 465.566 172.952C467.303 172.136 468.768 170.836 469.786 169.206C470.804 167.576 471.332 165.686 471.306 163.764C471.244 160.686 469.532 157.795 466.347 157.279C465.151 157.093 463.927 157.225 462.798 157.662C462.48 157.037 462.04 156.483 461.503 156.034C460.369 155.174 459.008 154.667 457.589 154.574C456.17 154.48 454.755 154.805 453.519 155.509C450.414 149.442 439.714 149.238 434.613 152.2C431.658 153.917 429.371 156.589 428.128 159.779C425.697 166.13 428.181 172.872 427.897 179.348C427.764 182.257 426.522 186.108 423.231 186.615C416.666 187.629 410.607 179.757 406.996 175.292C401.283 168.229 397.104 160.17 392.571 152.289C381.747 133.556 369.327 110.775 372.68 88.3507C373.727 80.9589 377.338 75.4261 380.088 68.6836C383.06 61.3896 380.177 52.4945 373.177 48.7051C370.063 47.0151 366.08 46.4191 362.824 48.0291C362.629 48.124 362.434 48.2308 362.238 48.3494C362.228 48.2895 362.204 48.2328 362.169 48.1834C362.134 48.1341 362.088 48.0935 362.034 48.0647C360.894 47.4055 359.584 47.104 358.271 47.1991C356.959 47.2943 355.704 47.7817 354.671 48.5984C354.354 48.8277 354.057 49.0836 353.784 49.3634C352.879 48.9568 351.867 48.8569 350.9 49.0788C349.684 49.0581 348.501 49.4817 347.574 50.2707C346.803 50.9508 346.209 51.8086 345.844 52.7702L345.755 52.9126C345.717 52.9899 345.699 53.0755 345.702 53.1616C345.408 54.0236 345.228 54.9209 345.169 55.8301C344.968 58.4073 345.49 60.9897 346.678 63.2843C347.309 64.4431 348.131 65.4867 349.109 66.3709L349.268 66.4598Z"
      fill="white"
    />
    <path
      d="M359.657 148.482C353.074 134.374 345.995 118.461 345.782 102.654C345.657 91.4461 348.958 78.993 352.755 68.5323C351.541 67.9323 350.375 67.2394 349.268 66.4597L349.109 66.3708C348.131 65.4866 347.309 64.443 346.678 63.2842C345.49 60.9896 344.967 58.4073 345.169 55.8301C345.228 54.9209 345.408 54.0236 345.702 53.1615C345.699 53.0754 345.717 52.9899 345.755 52.9125L345.844 52.7701C346.209 51.8086 346.803 50.9507 347.574 50.2706C348.501 49.4816 349.684 49.058 350.9 49.0787C351.867 48.8568 352.879 48.9567 353.784 49.3634C354.057 49.0836 354.354 48.8276 354.671 48.5983C355.704 47.7816 356.959 47.2942 358.271 47.1991C359.584 47.1039 360.894 47.4054 362.034 48.0646C362.088 48.0935 362.134 48.134 362.169 48.1834C362.204 48.2327 362.228 48.2894 362.238 48.3493C362.434 48.2307 362.629 48.1239 362.824 48.0291C366.08 46.4191 370.063 47.015 373.177 48.7051C380.177 52.4944 383.06 61.3895 380.088 68.6835C377.338 75.426 373.727 80.9588 372.68 88.3506C370.72 101.459 374.15 114.69 379.5 127.162C380.367 129.183 385.047 138.522 386 140.5M407.5 173.041C408 176.5 409.091 177.909 410.361 179.348C413.867 183.317 418.412 187.359 423.231 186.615C426.522 186.108 427.764 182.256 427.897 179.348C428.181 172.872 425.697 166.13 428.128 159.778C429.371 156.589 431.658 153.917 434.613 152.2C439.714 149.238 450.414 149.442 453.519 155.509C454.755 154.805 456.17 154.48 457.589 154.573C459.008 154.667 460.369 155.174 461.503 156.034C462.04 156.483 462.48 157.037 462.798 157.661C463.927 157.225 465.151 157.093 466.347 157.279C469.532 157.795 471.244 160.686 471.306 163.764C471.332 165.686 470.804 167.576 469.786 169.206C468.768 170.835 467.303 172.136 465.566 172.952C461.938 174.82 456.198 175.176 452.649 173.041C452.915 176.921 452.915 180.814 452.649 184.694C452.484 188.982 451.691 193.223 450.298 197.28C447.784 203.442 443.058 208.434 437.053 211.272C430.581 214.327 423.24 214.989 416.329 213.14C411.02 211.781 406.283 209.365 401.977 206.291"
      stroke="#392040"
    />
    <path
      d="M462.658 158.123C463.091 159.273 463.245 160.505 463.107 161.723C462.968 162.94 462.542 164.109 461.86 165.138C461.208 166.086 460.349 166.881 459.346 167.469C458.344 168.056 457.221 168.421 456.057 168.537C455.171 168.624 455.171 169.921 456.057 169.835C457.42 169.708 458.739 169.294 459.921 168.62C461.104 167.946 462.122 167.03 462.906 165.934C463.721 164.748 464.238 163.392 464.417 161.975C464.595 160.559 464.43 159.12 463.934 157.778C463.65 156.999 462.365 157.336 462.658 158.123Z"
      fill="#392040"
    />
    <path
      d="M452.781 154.831C453.094 157.084 452.645 159.379 451.51 161.326C450.433 163.183 448.327 165.358 446.035 164.973C445.162 164.819 444.782 166.214 445.663 166.359C448.531 166.849 451.064 164.704 452.54 162.337C453.981 159.996 454.542 157.188 454.118 154.446C453.988 153.532 452.642 153.927 452.781 154.831Z"
      fill="#392040"
    />
    <path
      d="M352.765 49.7492C351.116 52.0677 350.475 54.9088 350.979 57.6709C351.247 58.8497 351.814 59.9476 352.629 60.8684C353.445 61.7891 354.484 62.5046 355.656 62.9521C356.494 63.2513 356.854 61.9751 356.025 61.6758C355.051 61.274 354.198 60.6454 353.543 59.8474C352.888 59.0495 352.453 58.1076 352.277 57.1076C351.925 54.7262 352.527 52.3055 353.962 50.3301C354.441 49.6084 353.244 48.9482 352.765 49.67V49.7492Z"
      fill="#392040"
    />
    <path
      d="M361.526 47.382C360.723 48.5085 360.201 49.7907 359.999 51.1329C359.797 52.4751 359.921 53.8426 360.361 55.1338C361.111 57.5972 362.915 60.1939 365.773 60.7075C366.725 60.8882 367.141 59.5091 366.178 59.3379C363.766 58.9003 362.357 56.6461 361.769 54.5822C361.424 53.497 361.342 52.3532 361.528 51.2343C361.714 50.1154 362.163 49.0495 362.844 48.1144C363.421 47.3725 362.104 46.6591 361.526 47.3915V47.382Z"
      fill="#392040"
    />
    <path
      d="M303.638 141.773C303.638 160.26 282.065 180.072 275.7 184.308L277.084 232.764L280.031 227.241C298.729 213.071 334.841 175.851 329.718 140.345L332.827 131.031C333.669 111.978 324.548 109.746 319.882 111.012C308.31 104.363 306.206 113.317 305.142 118.626C292.484 119.049 299.35 132.897 303.638 141.773Z"
      fill="white"
    />
    <path
      d="M275.7 184.309C282.064 180.072 303.273 160.26 303.273 141.773C298.986 132.897 292.119 119.049 304.777 118.626C305.841 113.318 308.31 104.363 319.882 111.012C324.548 109.746 333.669 111.978 332.827 131.031L329.718 140.346C334.841 175.852 298.729 213.071 280.031 227.242"
      stroke="#392040"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M206.394 103.06L206.394 103.06C203.243 101.099 199.799 99.1877 196.101 98.3426L196.099 98.3421C194.864 98.066 193.635 97.8065 192.497 97.6794C191.362 97.5527 190.275 97.5519 189.334 97.822C188.374 98.0976 187.571 98.6552 187.042 99.6123C186.524 100.55 186.295 101.825 186.366 103.497L186.366 103.497L186.367 103.502C186.633 108.611 187.732 113.656 189.62 118.438C190.653 121.113 191.903 123.703 193.356 126.181L193.357 126.182C193.42 126.289 193.503 126.382 193.602 126.456C193.701 126.53 193.813 126.585 193.934 126.616C194.055 126.646 194.181 126.653 194.304 126.634C194.427 126.615 194.546 126.572 194.652 126.506L194.669 126.495L194.685 126.484C201.597 121.396 208.655 116.506 215.86 111.812C215.978 111.736 216.078 111.634 216.152 111.515C216.226 111.395 216.273 111.26 216.288 111.12C216.304 110.98 216.288 110.839 216.243 110.706C216.198 110.573 216.125 110.452 216.029 110.351L216.016 110.338L216.002 110.325C213.025 107.618 209.808 105.186 206.394 103.06Z"
      fill="white"
      stroke="#392040"
    />
    <path
      d="M190.942 103.076C192.437 109.236 194.788 115.077 197.929 120.437C198.017 120.593 198.166 120.701 198.344 120.738C198.522 120.774 198.714 120.737 198.878 120.633C199.043 120.529 199.165 120.368 199.22 120.185C199.274 120.001 199.255 119.811 199.168 119.656C196.081 114.4 193.771 108.671 192.304 102.628C192.253 102.462 192.139 102.328 191.985 102.254C191.832 102.179 191.649 102.169 191.476 102.226C191.302 102.283 191.15 102.403 191.051 102.561C190.952 102.719 190.913 102.903 190.942 103.076Z"
      fill="#392040"
    />
    <path
      d="M186.386 178.266C184.047 169.995 181.952 161.672 180.1 153.297C180.072 153.161 180.016 153.032 179.933 152.92C179.848 152.806 179.739 152.713 179.614 152.647C179.489 152.58 179.35 152.543 179.208 152.538C179.065 152.532 178.922 152.56 178.792 152.619L178.776 152.626L178.76 152.635C175.34 154.483 172.075 156.599 168.997 158.964C165.083 161.921 161.066 165.512 159.17 168.814C158.222 170.466 157.75 172.138 158.206 173.655C158.668 175.191 160.028 176.402 162.354 177.243L162.354 177.243L162.359 177.244C167.157 178.927 172.182 179.821 177.234 179.889C180.044 179.949 182.85 179.783 185.624 179.392L185.624 179.392L185.63 179.391C185.876 179.352 186.097 179.217 186.241 179.014C186.386 178.81 186.44 178.558 186.397 178.315L186.393 178.29L186.386 178.266Z"
      fill="white"
      stroke="#392040"
    />
    <path
      d="M162.456 172.131C167.4 167.726 173.172 164.382 179.388 162.321C180.192 162.055 179.865 160.805 179.06 161.071C172.625 163.213 166.652 166.684 161.538 171.254C160.873 171.851 161.791 172.729 162.456 172.131Z"
      fill="#392040"
    />
    <path
      d="M210.347 107.127L210.338 107.129L210.329 107.132C206.51 108.183 202.914 109.925 199.72 112.272C198.253 113.308 196.841 114.419 195.489 115.601L195.488 115.602C191.04 119.508 187.186 124.042 184.047 129.061C176.556 140.886 172.201 155.337 175.678 169.23C175.878 170.039 176.089 170.874 176.366 171.687C178.504 178.731 182.919 184.863 188.92 189.122L188.919 189.122L188.931 189.129C190.185 189.97 191.511 190.7 192.894 191.31L192.894 191.31L192.9 191.313C198.179 193.555 203.992 194.239 209.654 193.282C220.818 191.417 228.593 183.324 234.981 174.812C238.577 170.186 241.575 165.125 243.903 159.75L243.905 159.745C245.948 154.895 247.207 149.752 247.636 144.509L247.636 144.506C248.44 133.904 245.115 122.803 237.489 115.148L237.488 115.148C235.398 113.054 233.011 111.278 230.405 109.878C224.295 106.508 217.148 105.528 210.347 107.127Z"
      fill="white"
      stroke="#392040"
    />
    <path
      d="M210.5 173.5C213.833 172.667 219.4 167.2 215 152C219.5 152.167 227.9 149.5 225.5 137.5"
      stroke="#392040"
    />
    <path
      d="M199.621 155.257C200.367 157.693 200.846 160.199 201.047 162.729C201.664 168.416 201.08 174.148 199.334 179.555C198.029 183.44 195.591 187.822 192.995 190.977L193.802 190.858C185.046 186.855 178.62 179.658 176.683 169.032L176.629 169.536C181.394 161.589 191.847 157.572 200.465 155.73C201.314 155.552 200.922 154.251 200.073 154.428C192.881 155.963 185.466 158.509 179.978 163.54C179.079 164.366 178.249 165.265 177.497 166.227C177.17 166.648 176.23 167.65 175.933 168.092C175.785 168.248 175.683 168.459 175.568 168.659C175.296 169.153 175.401 169.452 175.546 169.998C176.696 174.293 178.53 178.476 181.14 182.099C184.144 186.336 188.474 189.817 193.163 192.04C193.295 192.109 193.446 192.133 193.592 192.111C193.738 192.088 193.872 192.019 193.975 191.914C201.576 182.645 204.099 169.356 201.616 157.659C201.432 156.726 201.196 155.803 200.911 154.895C200.642 154.07 199.346 154.426 199.621 155.257Z"
      fill="#392040"
    />
    <mask
      id="mask0_8437_4460"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="176"
      y="155"
      width="26"
      height="36"
    >
      <path
        d="M199.902 155.438C199.902 155.438 206.261 175.057 193.344 190.632C191.997 190.036 190.704 189.324 189.482 188.504C183.605 184.33 179.28 178.325 177.182 171.43C176.925 170.63 176.706 169.829 176.505 169.022C179.405 164.185 185.817 158.532 199.902 155.438Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_8437_4460)">
      <path
        d="M212.267 175.148C218.547 168.578 218.312 158.163 211.743 151.884C205.174 145.606 194.759 145.842 188.48 152.411C182.2 158.981 182.435 169.396 189.004 175.675C195.573 181.953 205.988 181.717 212.267 175.148Z"
        fill="#392040"
      />
    </g>
    <path
      d="M231.355 110.624C227.568 117.45 222.728 123.644 217.014 128.974C214.009 131.788 210.606 134.144 206.918 135.966C206.478 136.173 206.033 136.361 205.582 136.543C205.455 136.579 205.33 136.625 205.209 136.679C205.274 136.621 205.401 136.565 205.209 136.679L205.967 137.169C203.39 128.79 202.025 119.956 202.975 111.164L202.607 111.716C207.232 108.973 212.421 107.083 217.89 107.245C222.743 107.435 227.446 108.91 231.519 111.518C231.665 111.611 231.843 111.643 232.014 111.605C232.185 111.567 232.334 111.463 232.429 111.316C232.524 111.168 232.557 110.99 232.521 110.82C232.485 110.649 232.382 110.501 232.235 110.408C225.735 106.329 217.698 104.726 210.206 106.904C208.089 107.523 206.038 108.355 204.084 109.386C203.649 109.612 203.22 109.857 202.796 110.096C202.373 110.335 201.861 110.502 201.699 110.927C201.536 111.627 201.464 112.343 201.486 113.06C201.261 116.501 201.34 119.952 201.723 123.373C202.253 128.147 203.246 132.853 204.687 137.428C204.727 137.59 204.827 137.732 204.968 137.823C205.109 137.913 205.279 137.947 205.445 137.918C207.457 137.211 209.37 136.247 211.137 135.049C215.962 131.9 220.279 128.03 223.935 123.578C227.132 119.742 229.976 115.628 232.431 111.285C232.875 110.595 231.777 109.883 231.355 110.624Z"
      fill="#392040"
    />
    <mask
      id="mask1_8437_4460"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="201"
      y="106"
      width="32"
      height="32"
    >
      <path
        d="M232.171 110.646C219.466 133.004 205.302 137.323 205.302 137.323C201.896 126.443 201.4 117.555 202.122 110.682C205.412 108.636 209.048 107.211 212.851 106.478C219.586 105.426 226.471 106.911 232.171 110.646Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_8437_4460)">
      <path
        d="M240.312 101.592C235.963 93.6127 225.97 90.6704 217.99 95.0196C210.011 99.3687 207.067 109.363 211.415 117.341C215.764 125.32 225.757 128.263 233.737 123.913C241.716 119.564 244.66 109.57 240.312 101.592Z"
        fill="#392040"
      />
    </g>
    <path
      d="M209.239 146.028C209.614 146.446 209.856 146.966 209.935 147.523C210.015 148.079 209.928 148.647 209.684 149.153C209.441 149.66 209.053 150.084 208.57 150.37C208.086 150.656 207.528 150.793 206.967 150.762C206.406 150.731 205.866 150.535 205.417 150.198C204.967 149.86 204.628 149.397 204.441 148.867C204.255 148.337 204.23 147.763 204.37 147.219C204.509 146.675 204.807 146.184 205.226 145.809C205.787 145.305 206.525 145.046 207.278 145.087C208.03 145.128 208.736 145.466 209.239 146.028Z"
      fill="#1072F1"
    />
    <path
      d="M0.5 132C66 125.5 19.5 82 74.5 67C104.721 58.7578 86.5 73.5 76.5 73.5C22.0347 73.5 52.0532 12.5 82.5 30.5C146.1 68.1 117.5 75.5 101.5 52C87.1667 24.1667 106 8.49998 125.5 26.5C141.906 41.644 143.5 84.5 181 67C218.5 49.4999 227 130 283 106.5C327.665 87.7568 334.5 97.9999 355 98.4999C402.26 99.6526 390.833 80.6666 375.5 78.5M350 74.5C334 74.5 331 85.9999 345 91.4999C374.226 102.982 395 123 443 114C481.4 106.8 500 94 503.5 74.5C505.5 55.5 481 55.5 473 69.5C463 87 463.077 102.5 467.5 114C475 133.5 478 142.5 503.5 152.5C541 165 584.598 214.758 519 233.5C512 235.5 472.5 245.333 482.5 227.5C487.248 222.167 498.596 218.1 518 224.5C542.255 232.5 543.468 238 560.5 238C574 238 587 224 598 208C609 192 625.2 178.3 638 195.5"
      stroke="#1072F1"
    />
  </svg>
</template>
