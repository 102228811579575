import { markRaw } from 'vue'
import View from './NewsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'Latest news',
    titleSize: 'xl2',
    viewAllText: 'View all',
    viewAllLink: '/news',
  },
}
