import { markRaw } from 'vue'
import View from './FormView.vue'
import schema, { FormFieldType } from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'My form',
    buttonText: 'Submit',
    fields: [
      {
        title: 'Email',
        type: FormFieldType.Email,
        placeholder: '',
        required: true,
      },
      {
        title: 'Name',
        type: FormFieldType.Name,
        placeholder: '',
        required: true,
      },
    ],
    maxWidth: 576, // max-w-xl
  },
}
