<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-2xl"
    :class="formData.blockShadow && 'shadow-lg'"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <div
      class="flex justify-between items-center mb-3 md:mb-4 px-1 md:px-0 relative"
    >
      <h2
        v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
        class="font-bold grow"
        :class="titleSizeMap[formData.titleSize]"
        :style="{ textAlign: formData.titleAlign }"
      >
        {{ formData.title }}
      </h2>
      <template v-if="linkUrl && formData.viewAllText">
        <BaseLink
          :href="isRelativeLink(formData.viewAllLink) ? undefined : linkUrl"
          :to="isRelativeLink(formData.viewAllLink) ? linkUrl : undefined"
          :target="getLinkTarget(formData.viewAllLink, formData.isBlank)"
          class="absolute right-0"
          weight="normal"
        >
          {{ formData.viewAllText }}
        </BaseLink>
      </template>
    </div>
    <div
      v-if="
        isLoading ||
        !navigationStore.categoriesList.length ||
        !newsStore.list.hits.length
      "
      class="p-24 flex justify-center"
    >
      <BaseSpinner />
    </div>
    <template v-else>
      <div class="gap-6 grid grid-cols-1 md:grid-cols-2">
        <div
          class="gap-3 lg:gap-6 grid grid-cols-1 content-start"
          :class="
            newsStore.list.hits[0].preview_img
              ? 'lg:grid-cols-2'
              : 'lg:grid-cols-1'
          "
        >
          <div v-if="newsStore.list.hits[0].preview_img">
            <BaseLocaleLink :to="getNewsRoute(newsStore.list.hits[0])">
              <BaseImage
                loading="lazy"
                class="rounded-lg w-full"
                width="1280"
                :src="newsStore.list.hits[0].preview_img"
                :alt="newsStore.list.hits[0].name"
              />
            </BaseLocaleLink>
          </div>
          <div>
            <div class="font-bold text-lg leading-tight">
              <BaseLocaleLink :to="getNewsRoute(newsStore.list.hits[0])">
                {{ newsStore.list.hits[0].name }}
              </BaseLocaleLink>
            </div>
            <div class="text-sm text-gray-500 mt-1">
              {{
                newsStore.formatDateFrom(newsStore.list.hits[0].publish_date)
              }}
            </div>
            <div
              class="text-gray text-sm line-clamp-3 whitespace-pre-wrap break-words mt-1"
              v-html="newsStore.getTextPreview(newsStore.list.hits[0])"
            />
          </div>
        </div>
        <div
          class="grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 lg:gap-6 content-start"
        >
          <template
            v-for="(newsItem, index) in newsStore.list.hits.slice(1, 5)"
            :key="'news-grid' + index"
          >
            <div class="flex gap-4 w-full">
              <BaseLocaleLink
                v-if="newsItem.preview_img"
                :to="getNewsRoute(newsItem)"
                class="w-1/4 shrink-0"
              >
                <BaseImage
                  loading="lazy"
                  class="rounded-lg w-full aspect-square object-cover"
                  width="640"
                  height="640"
                  :src="newsItem.preview_img"
                  :alt="newsItem.name"
                />
              </BaseLocaleLink>
              <div>
                <div class="text-sm text-gray-500">
                  {{ newsStore.formatDateFrom(newsItem.publish_date) }}
                </div>
                <div
                  class="font-normal text-md leading-tight line-clamp-4 lg:line-clamp-2 whitespace-pre-wrap break-words"
                >
                  <BaseLocaleLink :to="getNewsRoute(newsItem)">
                    {{ newsItem.name }}
                  </BaseLocaleLink>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'
import { NewsEndpointMap, type NewsListItem } from '~/models/news'
import { NavigationSortType } from '~/models/navigation'
import { titleSizeMap } from '~/components/page-editor/common'

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()

const pageEditorStore = usePageEditor()
const authStore = useAuth()
const newsStore = useNews()
const isLoading = ref(!useNuxtApp().isHydrating)
const { getLinkTarget, getLinkUrl, isRelativeLink } = useEditorLinks()
const navigationStore = useNavigation()
const { getCategoryItemsByIds, getPageLinkCategorySlug, fetchCategories } =
  useNavigation()

const linkUrl = computed(() => getLinkUrl(props.formData.viewAllLink))

function getNewsRoute(news: NewsListItem) {
  const categories = getCategoryItemsByIds(news.categories, 'news')
  const categorySlug = getPageLinkCategorySlug(categories)

  const route = {
    name: 'news-category-item-id',
    params: {
      id: news.id,
      category: categorySlug,
    },
  }

  return route
}

const newsEndpoint = computed(() =>
  authStore.auth ? NewsEndpointMap.Auth : NewsEndpointMap.Base
)

async function getNewsList(): Promise<void> {
  newsStore.resetNewsListWithSorting(newsEndpoint.value, NavigationSortType.All)
  await newsStore.getNews()
}

async function load() {
  isLoading.value = true
  await Promise.all([fetchCategories(), getNewsList()])
  isLoading.value = false
}

onServerPrefetch(async () => {
  await load()
})

onMounted(async () => {
  if (useNuxtApp().isHydrating) {
    return
  }
  await load()
})

watch(
  () => props.formData,
  () => {
    load()
  },
  { deep: true }
)
</script>
