<template>
  <BaseImageInput
    ref="fileInput"
    :loading="isLoading"
    :max-size="maxSize"
    :preview="props.modelValue"
    :cropper-ratio="cropperRatio"
    use-cropper
    :accept="acceptTypes"
    show-delete-button
    @change="onFileChange"
    @delete="emit('update:modelValue', '')"
    @error:size="onSizeError"
  ></BaseImageInput>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useNotifications } from '~/stores/notifications'
import type { Media } from '~/models/media'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  maxSize: {
    type: Number,
    default: 1,
  },
  cropperRatio: {
    type: Number,
    default: undefined,
  },
  acceptTypes: {
    type: Array<string>,
    default: [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/svg+xml',
    ],
  },
})

const emit = defineEmits(['update:modelValue'])
const { pushNotification } = useNotifications()
const { t } = useI18n()

const isLoading = ref(false)
function onSizeError() {
  pushNotification({
    title: t('notifications.editor.file_size_error', props.maxSize),
    theme: 'destructive',
  })
}
async function onFileChange(file: File) {
  try {
    isLoading.value = true

    const formData = new FormData()

    formData.append('category', 'image')
    formData.append('media', file)

    const baseURL = useRuntimeConfig().public.gatewayAdminApi
    const { data } = await useAuthFetch<{ data: Media }>('/media', {
      baseURL,
      method: 'POST',
      body: formData,
    })

    emit('update:modelValue', data.url)

    pushNotification({
      title: 'Image was successfully uploaded',
    })
  } catch (error: any) {
    pushNotification({
      title: 'Error on image upload',
      theme: 'destructive',
    })
    useLogError(error)
  } finally {
    isLoading.value = false
  }
}
</script>
