<template>
  <BaseImageInput
    v-bind="{ ...$attrs }"
    v-model="filename"
    :loading="isLoading"
    :disabled="isLoading"
    :max-size="maxSize"
    @change="onFileChange"
    @delete="emit('update:modelValue', '')"
    @error:size="onSizeError"
    @error:type="onTypeError"
  >
    <template v-if="isLoading || filename" #preview>
      <div
        v-if="isLoading"
        class="h-full flex items-center justify-center min-h-[132px]"
      >
        <BaseSpinner />
      </div>
      <div
        v-else-if="filename"
        class="h-full flex flex-col gap-2 items-center justify-center text-sm font-medium p-4 min-h-[132px]"
      >
        <BaseIcon
          name="outline_document_check"
          size="md"
          class="text-green-500"
        />
        <div class="text-center">
          {{ t('notifications.editor.file_upload_success') }}
        </div>
        <BaseButton
          look="link"
          class="w-6 h-6 !p-0 !absolute top-1 right-1 cursor-pointer"
          @click.stop="onDeleteFile"
        >
          <BaseIcon class="text-red-500" name="outline_x" />
        </BaseButton>
      </div>
    </template>
  </BaseImageInput>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { usePageEditorForms } from '~/stores/page-editor-forms'
import { useNotifications } from '~/stores/notifications'

const props = defineProps({
  modelValue: {
    type: [Object, String] as PropType<File | string>,
    default: undefined,
  },
  maxSize: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(['update:modelValue'])

const isLoading = ref(false)
const filename = ref('')

const { uploadFile } = usePageEditorForms()
const { pushNotification } = useNotifications()
const { t } = useI18n()

function onSizeError() {
  pushNotification({
    title: t('notifications.editor.file_size_error', props.maxSize),
    theme: 'destructive',
  })
}

function onTypeError() {
  pushNotification({
    title: t('common.file_is_not_supported'),
    theme: 'destructive',
  })
}

async function onFileChange(file: File) {
  try {
    isLoading.value = true
    filename.value = await uploadFile(file)
    emit('update:modelValue', filename.value)
  } catch (error: any) {
    filename.value = ''
    emit('update:modelValue', filename.value)
    pushNotification({
      title: t('notifications.editor.file_upload_error'),
      theme: 'destructive',
    })
    useLogError(error)
  } finally {
    isLoading.value = false
  }
}

function onDeleteFile() {
  filename.value = ''
  emit('update:modelValue', filename.value)
}
</script>
