<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-2xl"
    :class="formData.blockShadow && 'shadow-lg'"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <div
      class="flex justify-between items-center mb-3 md:mb-4 px-1 md:px-0 relative"
    >
      <h2
        v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
        class="font-bold grow"
        :class="titleSizeMap[formData.titleSize]"
        :style="{ textAlign: formData.titleAlign }"
      >
        {{ formData.title }}
      </h2>
      <template v-if="linkUrl && formData.viewAllText">
        <BaseLink
          :href="isRelativeLink(formData.viewAllLink) ? undefined : linkUrl"
          :to="isRelativeLink(formData.viewAllLink) ? linkUrl : undefined"
          :target="getLinkTarget(formData.viewAllLink, formData.isBlank)"
          class="absolute right-0"
          weight="normal"
        >
          {{ formData.viewAllText }}
        </BaseLink>
      </template>
    </div>
    <div v-if="isLoading" class="p-24 flex justify-center">
      <BaseSpinner />
    </div>
    <template v-else-if="filteredEvents.length === 0">
      <div class="flex justify-center py-10">
        <div>{{ $t('events.no_events') }}</div>
      </div>
    </template>
    <template v-else>
      <div class="gap-6 hidden md:grid grid-cols-2 lg:grid-cols-4">
        <template
          v-for="(event, index) in filteredEvents"
          :key="'event-grid' + index"
        >
          <EventsCard
            v-if="eventsStore.eventsMap[event.eventId]"
            :event="eventsStore.eventsMap[event.eventId]"
          />
        </template>
      </div>
      <div class="block md:hidden">
        <Swiper
          :slides-per-view="'auto'"
          :space-between="SLIDES_GAP"
          :pagination="false"
          :modules="swiperModules"
          :class="formData.isShadowed && 'swiper-shadowed'"
        >
          <template
            v-for="(event, index) in formData.events"
            :key="'event-slider' + index"
          >
            <SwiperSlide
              v-if="eventsStore.eventsMap[event.eventId]"
              :style="{
                padding: '0 4px',
                marginRight: SLIDES_GAP + 'px',
                width: getSlideWidth(1, SLIDES_GAP),
              }"
            >
              <EventsCard
                :lazy="index > 1"
                :event="eventsStore.eventsMap[event.eventId]"
              />
            </SwiperSlide>
          </template>
        </Swiper>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import EventsCard from './EventsCard.vue'
import { getSlideWidth } from '~/components/base/utils'
import { usePageEditor } from '~/stores/page-editor'
import { titleSizeMap } from '~/components/page-editor/common'
const nuxtApp = useNuxtApp()

const { getLocalMedia } = useLocalMedia()
// onServerPrefetch should be registered before any other global awaits to run
onServerPrefetch(async () => {
  await load()
  nuxtApp.runWithContext(() => {
    // preload first image in the first events block, suppose it is an LCP image
    useHead({
      // @ts-ignore-next-line strange types issue
      link: [
        ...(useDevice().isMobile &&
        firstEventLogo.value &&
        pageEditorStore.page?.blocks?.find(
          (block: any) => block.type === props.blockType
        )?.id === props.blockId
          ? [
              {
                rel: 'preload',
                as: 'image',
                href: useProcessImage({
                  url: firstEventLogo.value,
                  width: 1200,
                }),
              },
            ]
          : []),
      ],
    })
  })
})
const Swiper = (await import('swiper/vue')).Swiper
const SwiperSlide = (await import('swiper/vue')).SwiperSlide
const Grid = (await import('swiper/modules')).Grid
const Pagination = (await import('swiper/modules')).Pagination

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
  blockId?: number
  blockType?: number
}

const props = defineProps<PropsInterface>()

const pageEditorStore = usePageEditor()
const eventsStore = useEvents()
const authStore = useAuth()
const isLoading = ref(false)
const { getLinkTarget, getLinkUrl, isRelativeLink } = useEditorLinks()
const SLIDES_GAP = 24

const swiperModules = [Grid, Pagination]
const linkUrl = computed(() => getLinkUrl(props.formData.viewAllLink))
const filteredEvents = computed(() =>
  props.formData.events.filter(
    (event: any) => eventsStore.eventsMap[event.eventId]
  )
)

async function load() {
  isLoading.value = true
  await eventsStore.fetchEventsByIds(
    props.formData.events.map((event: any) => event.eventId),
    !!authStore.auth,
    undefined,
    true
  )
  isLoading.value = false
}

onMounted(async () => {
  !useNuxtApp().isHydrating && (await load())
})

const firstEvent = computed(() => {
  return eventsStore.eventsMap[props.formData.events[0]?.eventId]
})

const firstEventLogo = computed(() =>
  firstEvent.value ? getLocalMedia(firstEvent.value.logo)?.url : undefined
)

watch(
  () => !!authStore.auth,
  async () => {
    eventsStore.resetEventsMap()
    await load()
  }
)

watch(
  () => props.formData,
  () => {
    load()
  },
  { deep: true }
)
</script>
<style>
@import 'swiper/css';
@import 'swiper/css/grid';
@import 'swiper/css/pagination';
</style>
