<template>
  <BaseButton
    size="none"
    look="none"
    theme="info"
    :title="
      isFavorite
        ? $t('favorites.remove_from_favorites')
        : $t('favorites.add_to_favorites')
    "
    :class="[
      (isProcessing || isLoading) && 'pointer-events-none opacity-50',
      buttonClass,
      isFavorite && activeButtonClass,
    ]"
    class="flex items-center transition"
    @click="() => onClick()"
  >
    <BaseIcon
      :size="iconSize"
      :name="
        isFavorite
          ? 'outline_bookmark_fill'
          : iconWidth === 'normal'
          ? 'outline_bookmark'
          : 'outline_bookmark_thin'
      "
    />
  </BaseButton>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useFavorites } from '~/stores/favorites'
import { EntityType } from '~/models/common'
import { useNotifications } from '~/stores/notifications'

const { pushNotification } = useNotifications()
const { t } = useI18n()
const favoritesStore = useFavorites()
const isFavorite = ref(false)
const isProcessing = ref(true)
const props = defineProps({
  autoFavorite: {
    type: Boolean,
    default: false,
  },
  entityTitle: {
    type: String,
    required: true,
  },
  entityId: {
    type: Number,
    required: true,
  },
  entityType: {
    type: String as PropType<EntityType>,
    required: true,
  },
  initialValue: {
    type: Boolean,
    default: undefined,
  },
  eventId: {
    type: Number,
    default: undefined,
  },
  iconWidth: {
    type: String as PropType<'normal' | 'thin'>,
    default: 'normal',
  },
  buttonClass: {
    type: String,
    default: 'text-gray-600 hover:text-primary-900 h-8 p-2',
  },
  activeButtonClass: {
    type: String,
    default: 'text-primary-900 hover:opacity-80',
  },
  iconSize: {
    type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
    default: 'sm',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update'])

const onClick = async (toggleToFavorite = false) => {
  if (isFavorite.value && toggleToFavorite) {
    return
  }
  try {
    isProcessing.value = true
    // optimistically change status
    isFavorite.value = !isFavorite.value
    if (isFavorite.value) {
      await favoritesStore.addToFavorites(
        props.entityId,
        props.entityType,
        props.eventId
      )
    } else {
      await favoritesStore.removeFromFavorites(props.entityId, props.entityType)
    }
    pushNotification({
      title: t(
        `notifications.favorites.${
          isFavorite.value ? 'add' : 'remove'
        }_success`,
        { name: props.entityTitle }
      ),
    })
    emit('update', isFavorite.value ? 'add' : 'remove', true)
  } catch (error: any) {
    isFavorite.value = !isFavorite.value // revert
    pushNotification({
      theme: 'destructive',
      title: t(
        `notifications.favorites.${isFavorite.value ? 'remove' : 'add'}_fail`,
        { name: props.entityTitle }
      ),
    })
    emit('update', isFavorite.value ? 'remove' : 'add', false)
  } finally {
    isProcessing.value = false
  }
}

const load = async () => {
  if (props.initialValue !== undefined) {
    isFavorite.value = props.initialValue
    isProcessing.value = false
    return
  }
  try {
    await favoritesStore.isFavorite(props.entityId, props.entityType)
    isFavorite.value = true
  } catch (error: any) {
    if (error.statusCode === 404) {
      isFavorite.value = false
    } else {
      throw error
    }
  } finally {
    isProcessing.value = false
  }
}

onMounted(async () => {
  await load()
  props.autoFavorite && (await onClick(true))
})

// for the cases when we get initialValue asynchronously after page loading (e.g., with observer)
if (props.initialValue !== undefined) {
  watchOnce(
    () => props.initialValue,
    (value) => {
      if (value !== undefined) {
        isFavorite.value = value
      }
    }
  )
}

defineExpose({ onClick })
</script>
