function sendResize(stage = 'other') {
  sendData({
    message_type: 'resize',
    frameHeight: document.body.scrollHeight,
    url: `${useHost()}${useRoute().fullPath}`,
    stage,
  })
}

function sendData(data: any) {
  setTimeout(() => {
    window.parent?.postMessage(data, '*')
  }, 0)
}

function initBridge() {
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(() => {
      sendResize('observer')
    })
  })
  resizeObserver.observe(document.body)
  window.addEventListener('resize', () => sendResize('event'), true)
  sendResize('init')
}

export function useFrameBridge() {
  return {
    initBridge,
    sendResize,
    sendData,
  }
}
