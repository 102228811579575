import { useI18n } from 'vue-i18n'
import type { Ref } from 'vue'
import { useAuth } from '~/stores/auth'
import { useCounters } from '~/stores/counters'
import type {
  CounterReactionsResponseType,
  CounterRequestType,
  ReactionsCounters,
} from '~/models/counters'
import { ReactionType } from '~/models/counters'
import { useNotifications } from '~/stores/notifications'
import { useFavorites } from '~/stores/favorites'

export interface CountersCallback {
  onDeleteReaction?: () => Promise<void>
  onSendReaction?: () => Promise<void>
  onObserve?: () => Promise<void>
}

export const useCountersHelper = () => {
  const {
    fetchCommentsCounters,
    fetchReactionsCounters,
    sendReaction: sendReactionAction,
    deleteReaction: deleteReactionAction,
  } = useCounters()
  const { pushNotification } = useNotifications()
  const authStore = useAuth()
  const { t } = useI18n()
  const favoritesStore = useFavorites()

  const commentsCounter = ref(0)
  const reactionsCounters: Ref<ReactionsCounters> = ref({})
  const isObserverDone = ref(false)
  const isCountersProcessing = ref(false)
  const entity = ref<CounterRequestType | null>(null)
  const observedTarget = ref<Ref<HTMLElement | null>>()
  const callbacks = ref<CountersCallback | null>(null)
  const isFavorite = ref(false)
  const isFavoriteLoading = ref(false)

  const getCommentsCounters = async () => {
    if (!entity.value) {
      return
    }

    try {
      const counter = await fetchCommentsCounters(entity.value)

      if (counter) {
        updateCommentsCounter(counter.comments_count)
      }
    } catch (error: any) {
      useLogError(error)
    }
  }

  const updateCommentsCounter = (value: number) => {
    commentsCounter.value = value
  }

  const resetReactions = () => {
    reactionsCounters.value = {}
  }

  const updateReactiosCounters = (
    reactionList: CounterReactionsResponseType[]
  ) => {
    if (reactionList && reactionList.length) {
      reactionList.forEach((reactionItem) => {
        reactionsCounters.value[reactionItem.reaction] = reactionItem
      })
    }
  }

  const getReactionsCounters = async () => {
    if (!entity.value) {
      return
    }

    try {
      const counters = await fetchReactionsCounters(
        entity.value,
        !!authStore.auth
      )

      resetReactions()
      updateReactiosCounters(counters)
    } catch (error: any) {
      useLogError(error)
    }
  }

  const sendReaction = async (reactionType: ReactionType) => {
    if (!entity.value) {
      return
    }

    if (!authStore.auth) {
      authStore.openLoginModal()
      return
    }

    try {
      await sendReactionAction(
        entity.value.entity_id,
        entity.value.entity_type,
        reactionType
      )
      await getReactionsCounters()

      if (callbacks.value && callbacks.value.onSendReaction) {
        await callbacks.value.onSendReaction()
      }
    } catch (error) {
      pushNotification({
        title: t('notifications.reactions.sent_error'),
        theme: 'destructive',
      })
      useLogError(error)
    }
  }

  const deleteReaction = async () => {
    if (!entity.value) {
      return
    }

    try {
      await deleteReactionAction(
        entity.value.entity_id,
        entity.value.entity_type
      )
      await getReactionsCounters()

      if (callbacks.value && callbacks.value.onDeleteReaction) {
        await callbacks.value.onDeleteReaction()
      }
    } catch (error) {
      pushNotification({
        title: t('notifications.reactions.delete_error'),
        theme: 'destructive',
      })
      useLogError(error)
    }
  }

  const onChangeReaction = async (reactionType: ReactionType) => {
    isCountersProcessing.value = true

    if (reactionsCounters.value[reactionType]?.is_set_by_user) {
      await deleteReaction()
    } else {
      await sendReaction(reactionType)
    }

    isCountersProcessing.value = false
  }

  const checkFavorites = async () => {
    if (!entity.value) {
      return
    }

    isFavoriteLoading.value = true

    try {
      await favoritesStore.isFavorite(
        entity.value.entity_id,
        entity.value.entity_type
      )
      isFavorite.value = true
    } catch (error) {
      isFavorite.value = false
    } finally {
      isFavoriteLoading.value = false
    }
  }

  const createObserver = (promises: (() => Promise<void>)[]) => {
    const targetIsVisible = ref(false)

    // let timeout: number

    const { stop } = useIntersectionObserver(
      observedTarget.value,
      ([{ isIntersecting }], _observerElement) => {
        targetIsVisible.value = isIntersecting

        if (targetIsVisible.value) {
          // timeout =
          window.setTimeout(async () => {
            if (targetIsVisible.value) {
              try {
                await Promise.all(promises.map((item) => item()))
              } catch (error: any) {
                useLogError(error)
              }
              stop()
              isObserverDone.value = true
            }
          }, 200)
        }
      },
      {
        rootMargin: '200px',
      }
    )

    // onUnmounted is temporaly commented as we getting vue warnings about no active instance could be bounded to unmounted hook
    // moreover if component will be unmounted so it should be not visible and callback should be stopped

    // onUnmounted(() => {
    //   clearTimeout(timeout)
    // })
  }

  interface CounterHelperParams {
    entity: CounterRequestType
    target?: Ref<HTMLElement | null>
    callbacks?: CountersCallback
    isLoadComments?: boolean
    isLoadReactions?: boolean
    isCheckFavorite?: boolean
  }

  const initCountersHelper = async (params: CounterHelperParams) => {
    entity.value = params.entity

    if (params.target) {
      observedTarget.value = params.target
    }

    if (params.callbacks) {
      callbacks.value = params.callbacks
    }

    const promises = []
    if (params.isLoadComments) {
      promises.push(getCommentsCounters)
    }
    if (params.isLoadReactions) {
      promises.push(getReactionsCounters)
    }
    if (params.isCheckFavorite) {
      promises.push(checkFavorites)
    }

    if (observedTarget.value) {
      if (params.callbacks?.onObserve) {
        promises.push(params.callbacks.onObserve)
      }

      createObserver(promises)
    } else {
      await Promise.all(promises.map((item) => item()))
    }

    if (params.isLoadReactions) {
      watch(
        () => authStore.auth,
        () => {
          if (!observedTarget.value) {
            getReactionsCounters()
          } else if (isObserverDone.value) {
            createObserver([getReactionsCounters])
          }
        }
      )
    }
  }

  return {
    initCountersHelper,
    reactionsCounters,
    commentsCounter,
    updateCommentsCounter,
    onChangeReaction,
    isCountersProcessing,
    isFavorite,
    isFavoriteLoading,
  }
}
