export const useMarkedText = () => {
  const { $marked } = useNuxtApp()

  const getMarkedTextWithBlankLinks = (text: string, linkColor?: string) => {
    if (!text) {
      return ''
    }

    const markedText = $marked(text || '')

    if (linkColor) {
      return markedText.replace(
        /<a /g,
        `<a target="_blank" style="color: ${linkColor}"`
      )
    } else {
      return markedText.replace(/<a /g, '<a target="_blank" ')
    }
  }

  return { getMarkedTextWithBlankLinks }
}
