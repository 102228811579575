export default {
  userInfoColor: {
    'ui:widget': 'BaseColorWidget',
  },
  textColor: {
    'ui:widget': 'BaseColorWidget',
  },
  iframeTextColor: {
    'ui:widget': 'BaseColorWidget',
  },
  iframeUserInfoColor: {
    'ui:widget': 'BaseColorWidget',
  },
  iframeLinkColor: {
    'ui:widget': 'BaseColorWidget',
  },
  bgColor: {
    'ui:widget': 'BaseColorWidget',
  },
  cardBgColor: {
    'ui:widget': 'BaseColorWidget',
  },
  iframeCardBgColor: {
    'ui:widget': 'BaseColorWidget',
  },
  reviews: {
    items: {
      image: {
        'ui:widget': 'EditorUploadWidget',
      },
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
    },
  },
}
