import {
  DEFAULT_COLOR_PRIMARY,
  DEFAULT_MARKDOWN_PLACEHOLDER,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Tickets',
  description: 'Tickets',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['bgColor', 'textColor'],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    subtitle: {
      title: 'Subtitle',
      type: 'string',
    },
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...bgColorSchema,
    categories: {
      type: 'array',
      title: 'A list of category cards',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Category card',
        type: 'object',
        required: ['title'],
        properties: {
          title: {
            title: 'Title',
            type: 'string',
            default: 'Category title',
          },
          color: {
            title: 'Card color',
            type: 'string',
            default: DEFAULT_COLOR_PRIMARY,
          },
          isShowVat: {
            title: 'Is show VAT',
            type: 'boolean',
            default: true,
          },
          text: {
            title: 'Text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          additionalInfo: {
            title: 'Additional info for popup',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          tickets: {
            type: 'array',
            title: 'Linked tickets',
            'ui:options': {
              showIndexNumber: true,
            },
            items: {
              title: 'Ticket',
              type: 'object',
              required: ['label'],
              properties: {
                label: {
                  title: 'Name',
                  type: 'string',
                },
                value: {
                  title: 'Ticket id',
                  type: 'number',
                },
                maxTicketsCount: {
                  title: 'Max tickets count',
                  type: 'number',
                  default: 99,
                  minimum: 1,
                  maximum: 99,
                },
                freeTicketText: {
                  title: 'Free ticket price text',
                  type: 'string',
                  default: 'Free',
                },
              },
            },
          },
        },
      },
    },
  },
}
