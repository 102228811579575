<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="formData.blockShadow && 'shadow-lg'"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
      color: formData.textColor,
      '--text-color': formData.textColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="font-bold mb-2 md:mb-4"
      :class="titleSizeMap[formData.titleSize] || titleSizeMap.XL3"
      :style="{ textAlign: formData.titleAlign }"
    >
      {{ formData.title }}
    </h2>
    <div
      class="grid gap-x-4 gap-y-6"
      :class="`grid-cols-1 md:grid-cols-${formData.colsNumberOnDesktop || 2}`"
    >
      <template v-for="(column, index) in formData.columns" :key="index">
        <ColumnsItem :column="column" :link-color="formData.linkColor" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import ColumnsItem from './ColumnsItem.vue'
import { usePageEditor } from '~/stores/page-editor'
import { titleSizeMap } from '~/components/page-editor/common'

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

defineProps<PropsInterface>()

const pageEditorStore = usePageEditor()
</script>
