import type { TimezoneInterface } from '~/models/timezones'
import type { OptionInterface } from '~/components/base/controls/use-input'

export const useTimezones = () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  async function getTimezonesAutocomplete(
    query: string
  ): Promise<OptionInterface[]> {
    const { data } = await useAuthFetch<{ data: TimezoneInterface[] }>(
      '/timezones',
      {
        baseURL,
        params: { query },
      }
    )

    return data.map((item: TimezoneInterface) => toAutocompleteOption(item))
  }

  async function getTimezoneById(id: string): Promise<OptionInterface> {
    const { data } = await useAuthFetch<{ data: TimezoneInterface }>(
      `/timezones/${id}`,
      {
        baseURL,
      }
    )

    return toAutocompleteOption(data)
  }

  async function getTimezoneByIdRaw(id: string): Promise<TimezoneInterface> {
    const { data } = await useAuthFetch<{ data: TimezoneInterface }>(
      `/timezones/${id}`,
      {
        baseURL,
        headers: {
          'Accept-Language': 'en',
        },
      }
    )

    return data
  }

  function toAutocompleteOption(timezone: TimezoneInterface): OptionInterface {
    return {
      label: `${timezone.id} - ${timezone.name} (${timezone.offsetLabel})`,
      value: timezone.id,
    }
  }

  function getBrowserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  async function isTimezoneExists(timezone: string) {
    try {
      await getTimezoneById(timezone)
      return true
    } catch (error: any) {
      if (error.statusCode === 404) {
        useLogError(`Error! Unknown timezone: "${timezone}"`)
      }
      return false
    }
  }

  return {
    getTimezonesAutocomplete,
    getTimezoneById,
    getTimezoneByIdRaw,
    toAutocompleteOption,
    getBrowserTimezone,
    isTimezoneExists,
  }
}
