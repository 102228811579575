import { markRaw } from 'vue'
import View from './IconsTextsView.vue'
import schema from './schema'

const DEFAULT_CARD = {
  text: '## Subtitle \n\n Morbi et orci sapien. Morbi vehicula elit vel diam tristique sagittis',
  icon: 'https://cdn.wnhub.io/46347/66a2c572411cc1.13238226.svg',
}

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorData: {
    colsNumberOnDesktop: 2,
    colsNumberOnMobile: 1,
    title: 'Почему WN Academy?',
    cards: [DEFAULT_CARD, DEFAULT_CARD, DEFAULT_CARD, DEFAULT_CARD],
  },
}
